import { EnvironmentVariableService } from './services/environment-variable.service';
import { Component, HostListener, OnDestroy ,NgZone, OnInit} from "@angular/core";
import { NavigationCancel, Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "../../environments/environment";
import { ConfirmationDialogService } from "./services/confirmation-dialog.service";
import { DeviceService } from "./services/device.service";
import { MessagePanelService } from "./services/messagePanel.service";
import { PanelManagerService } from './services/panel-manager.service';
import { ReportService } from "./services/report.service";
import { HttpClient } from "@angular/common/http";
import { Meta, Title } from '@angular/platform-browser';
import { EnvironmentVariable } from './models/environmentVariables';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit,OnDestroy {
  webSiteUrl: string =""
  isSuccess: boolean = false;
  currentEnvironmentVariable: EnvironmentVariable;
  wakeLock: WakeLockSentinel | null = null;
  constructor(
    private router: Router,
    private readonly translateService: TranslateService,
    public panelManagerService: PanelManagerService,
    public deviceService: DeviceService,
    public reportService: ReportService,
    public confirmationDialogService: ConfirmationDialogService,
    public messagePanelService: MessagePanelService,
    public http: HttpClient,
    private environmentVariableService:EnvironmentVariableService,
    private meta: Meta,
    private titleService: Title
  ) {
    this.translateService.setDefaultLang('tr')

    this.router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });
  }

  ngOnInit(): void {
    this.callEnvVariableAndSetMetaTags();
    this.requestWakeLock();
  }

  ngOnDestroy(): void {
    this.releaseWakeLock();
  }

  callEnvVariableAndSetMetaTags() {
    this.environmentVariableService.callEnvironmentVariable().then(res=>{
      this.currentEnvironmentVariable = res;
    }).then(()=>{
      this.updateOgMetaTags()
      this.updateMetaTags()
      this.updateLinkRel()
      this.updateTitle()
    });
    let domainName = window.location.hostname;
    domainName = "https://" +domainName+"/"
    this.webSiteUrl = domainName
  }


  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {

    }
    if (event instanceof NavigationEnd) {

    }
    if (event instanceof NavigationCancel) {

    }
    if (event instanceof NavigationError) {

    }
  }

  onMediaAccessAllow(e) {
    this.panelManagerService.onMediaAccessAllowSource.next(null);
  }
  updateMetaTags() {
    this.meta.updateTag({ name: 'keywords', content: this.currentEnvironmentVariable.Keyword });
    this.meta.updateTag({ name: 'title', content: this.currentEnvironmentVariable.Title });
    this.meta.updateTag({ name: 'description', content: this.currentEnvironmentVariable.Description });
  }
  updateTitle(){
    this.titleService.setTitle(this.currentEnvironmentVariable.UygulamaAdi);
  }

  updateLinkRel() {
    const link: HTMLLinkElement = document.querySelector('link[rel="shortcut icon"]');
    link.href = this.currentEnvironmentVariable.LogoS;
  }

  updateOgMetaTags() {
    this.meta.updateTag({ property: 'og:title', content: this.currentEnvironmentVariable.Title });
    this.meta.updateTag({ property: 'og:description', content: this.currentEnvironmentVariable.Description });
    this.meta.updateTag({ property: 'og:image', content: this.currentEnvironmentVariable.LogoXL });
    this.meta.updateTag({ property: 'og:url', content: "https://" + this.currentEnvironmentVariable.AlanAdi });    
  }

  requestWakeLock() {
    if ('wakeLock' in navigator) {
      (navigator.wakeLock as WakeLock).request('screen').then((lock: WakeLockSentinel) => {
        this.wakeLock = lock;
      }).catch((err) => {
        console.error("Wake Lock Error: ", err);
      });
    }
  }

  releaseWakeLock() {
    if (this.wakeLock) {
      this.wakeLock.release().then(() => {
      }).catch((err) => {
        console.error("Error releasing wake lock: ", err);
      });
    }
  }
}
