import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomService } from 'src/app/core/services/room.service';

@Component({
  selector: 'app-room-message-process',
  templateUrl: './room-message-process.component.html',
  styleUrls: ['./room-message-process.component.scss']
})
export class RoomMessageProcessComponent implements OnInit {

  faCopy = faCopy;
  constructor(
    public panelManagerService: PanelManagerService,
    public roomService: RoomService,
    public indexedDBService: IndexedDBService,
    public messagePanelService: MessagePanelService
  ) {
  }

  ngOnInit(): void {
  }

  closeRoomMessageProcessPanel() {
    this.panelManagerService.roomMessageProcessPopupDisplay = false;
  }

  copyMessageContent(event){
    event.stopPropagation();
    navigator.clipboard.writeText(this.roomService.roomMessageWillProcess.messageContent)
      .then(() => {
        this.messagePanelService.ShowPopupMessageWithLocalization('copiedSuccessfully', 2000);
      });
  }
}