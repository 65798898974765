import { Injectable, Type } from '@angular/core';
import { MainPanels, RoomPagePanels, ContactType } from 'src/app/core/models/enums';
import { Subject } from 'rxjs';
import { Friend, UserFullInfo } from 'src/app/core/models/user';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { ActiveChatPanel, ChatPanelProps } from '../models/chat-panel-props';
import { Constants } from 'src/app/core/models/constants';
import { ConferenceRequestResponse } from '../models/chat-message';
import { UtilService } from 'src/app/core/services/util.service';
import { RoomCamera } from 'src/app/core/models/room';
import { DeviceService } from 'src/app/core/services/device.service';

@Injectable({
    providedIn: 'root'
})
export class PanelManagerService {

    adminComponent?: Type<any>
    aiUserNameEntryLoading: boolean = false;
    whoWeArePageDisplay :boolean= false;
    legalWarningPageDisplay :boolean= false;
    usageAggrementPageDisplay :boolean= false;
    imageViewerPanelDisplay :boolean= false;
    activeMainPanel: MainPanels = MainPanels.None;
    currentMobileActivePanelId: string = Constants.mainSwiperTag + '' + MainPanels.RoomList;
    mobileActivePanelIdHistory: Array<string> = [this.currentMobileActivePanelId];


    activeselectedFriendFileUploadPanel: ChatPanelProps = new ChatPanelProps();  

    roomSwiperIndex = RoomPagePanels.Chat;

    roomPagePanels: RoomPagePanels = RoomPagePanels.Chat;

    focusedPanel: string;
    focusedZIndex = 3;
    unFocusedZIndex = 2;
    roomPageDisplay = false;
    isHiddenRoom = false;
    roomOpened = false;

    adminPageDisplay = false;
    adminOpened = false;
    adminModuleLoaded = false;
    roomRecordingPanelDisplay: boolean = false;
    visibleComplainNotification: boolean = false;
    visibleLiveSupportNotification: boolean = false;
    roomPasswordPanelIsOpened: boolean = false;
    roomSecondNickPanelIsOpened: boolean = false;
    userNameEntryPanelIsOpened: boolean = false;

    isLiveSupport: boolean = false;
    showMicSettings: boolean = false;
    p2pChatPanels: Array<ActiveChatPanel> = [];
    p2pIncomingMessageFromAntoherUserPanels: Array<Friend> = [];
    p2pIncomingMessageFromAntoherUserNotification:boolean = false;
    roomVideoPanels: Array<RoomCamera> = [];

    viewRoomLoading: boolean = false;
    findFriendLoading: boolean = false;
    mainLoading: boolean = false;
    connectionLoading: boolean = false;
    //REQUEST:1 : no need this property
    roomUserListLoading: boolean = false;
    cameraLoading: boolean = false;
    addFriendPopupDisplay: boolean = false;
    showBlockedFriendsPopupDisplay: boolean = false;
    changeFontPopupDisplay: boolean = false;
    viewProfileInfoPopupDisplay: boolean = false;
    updateStateMessagePopupDisplay: boolean = false;
    updateUserNamePopupDisplay: boolean = false;
    controlOfRecordingPopupDisplay: boolean = false;
    sendFolderToRoomPopupDisplay: boolean = false;
    sendFolderToFriendPopupDisplay: boolean = false;

    phoneCallingPopupDisplay:boolean = false
    redirectToRoomWarningPopupDisplay: boolean = false;
    guestWarningPopupDisplay: boolean = false;
    banPanelPopupDisplay: boolean = false;
    accountDeletionPopupDisplay: boolean = false;
    roomMicrophoneAcceptPopupDisplay: boolean = false;
    updateWallpaperPopupDisplay: boolean = false;
    profilePhotoPopupDisplay: boolean = false;
    roomSharedImagePopupDisplay: boolean = false;
    roomAIImagePopupDisplay: boolean = false;
    roomAIChatRemainingTimePopupDisplay: boolean = false;
    imageCropperPopupDisplay: boolean = false;
    roomLobiImageCropperPopupDisplay : boolean = false
    roomAITemplateLobiImageCropperPopupDisplay : boolean = false
    roomAITemplateOwnerImageCropperPopupDisplay : boolean = false

    generalMessageConfirmPopupDisplay: boolean = false;

    roomAIImageCropperPopupDisplay : boolean = false
    imageCapturePopupDisplay: boolean = false;
    permissionsDisplay: boolean = false;
    mediaDeviceModalVisible: boolean = false;
    selectAvatarPopupDisplay: boolean = false;
    mainMenuVisible: boolean = false;
    roomUserListVisible: boolean = false;
    youtubePlayerPopupDisplay: boolean = false;
    youtubePlayerPopupMinus: boolean = false;
    viewTheRoomPopupDisplay: boolean = false;
    inviteToRoomPopupDisplay: boolean = false;
    openMicRequestPopupDisplay: boolean = false;
    micOnRoomPopupDisplay: boolean = false;
    roomUserCardPopupDisplay: boolean = false;
    roomInfoCardPopupDisplay:boolean = false
    p2pContextMenuPopupDisplay: boolean = false;
    newUserRegisterPopupDisplay: boolean = false;
    newUserForDynamicRoomRegisterPopupDisplay: boolean = false;
    roomMessageProcessPopupDisplay: boolean = false;
    p2pMessageProcessPopupDisplay: boolean = false;


    showedRoomUserList: boolean = false;
    showedFriendList: boolean = false;
    showedIncommingAnotherUserMessagePanel:boolean = false;
    showedP2p: boolean = false;
    isMyFriend: boolean = true;

    loadedFriends: Promise<boolean> | undefined;

    p2pChatPanelsAddedSource: Subject<ActiveChatPanel> = new Subject();
    p2pChatPanelsAdded = this.p2pChatPanelsAddedSource.asObservable();

    p2pChatPanelsRemovedSource: Subject<number> = new Subject();
    p2pChatPanelsRemoved = this.p2pChatPanelsRemovedSource.asObservable();

    roomVideoPanelsAddedSource: Subject<RoomCamera> = new Subject();
    roomVideoPanelsAdded = this.roomVideoPanelsAddedSource.asObservable();

    roomVideoPanelsRemovedSource: Subject<number> = new Subject();
    roomVideoPanelsRemoved = this.roomVideoPanelsRemovedSource.asObservable();

    roomVideoElementsClearSource: Subject<any> = new Subject();
    roomVideoElementsClear = this.roomVideoElementsClearSource.asObservable();

    mainSwiperUpdatedSource: Subject<any> = new Subject();
    mainSwiperUpdated = this.mainSwiperUpdatedSource.asObservable();

    roomPageOpenedSource: Subject<boolean> = new Subject();
    roomPageOpened = this.roomPageOpenedSource.asObservable();

    adminPageOpenedSource: Subject<boolean> = new Subject();
    adminPageOpened = this.adminPageOpenedSource.asObservable();

    notificationsOpenedSource: Subject<boolean> = new Subject();
    notificationsOpened = this.notificationsOpenedSource.asObservable();


    roomPasswordPanelResponseSource: Subject<any> = new Subject();
    roomPasswordPanelResponse = this.roomPasswordPanelResponseSource.asObservable();

    userNameEntryPanelResponseSource: Subject<any> = new Subject();
    userNameEntryPanelResponse = this.userNameEntryPanelResponseSource.asObservable();

    roomSecondNickPanelResponseSource: Subject<any> = new Subject();
    roomSecondNickPanelResponse = this.roomSecondNickPanelResponseSource.asObservable();

    onMediaAccessAllowSource: Subject<any> = new Subject();
    onMediaAccessAllow = this.onMediaAccessAllowSource.asObservable();

    conferenceRejectedSource: Subject<any> = new Subject(); //Kullanılmıyor
    conferenceRejected = this.conferenceRejectedSource.asObservable();

    youtubePanelDisplayedSource: Subject<number> = new Subject();
    youtubePanelDisplayed = this.youtubePanelDisplayedSource.asObservable();

    recordListPanelDisplayedSource: Subject<boolean> = new Subject();
    recordListPanelDisplayed = this.recordListPanelDisplayedSource.asObservable();

    contentEditable: boolean = true;

    viewRecordVideoPopupDisplay:boolean = false

    constructor(
        private indexedDBService: IndexedDBService,
        private utilService: UtilService,
        private deviceService: DeviceService
    ) {

    }

    loadAdminModule(): Promise<any> {

        if (this.adminModuleLoaded) {
            this.adminOpened = true;
            return Promise.resolve();
        }
        else {
            return import('../../admin/admin.module')
                .then(mod => mod.AdminModule)
                .then(adminModule => {
                    this.adminComponent = adminModule.components.adminComponent;
                    this.adminModuleLoaded = true;
                    this.adminOpened = true;
                });
        }
    }

    swipeToPanel(id: string) {
        this.currentMobileActivePanelId = id;
        let lastId = this.mobileActivePanelIdHistory[this.mobileActivePanelIdHistory.length - 1]
        if (lastId !== id) {
            this.mobileActivePanelIdHistory = this.mobileActivePanelIdHistory.filter(f => f !== id)
            this.mobileActivePanelIdHistory.push(id);
        }
    }


    moveUserIconToTop(userId: number) {
        let currentUser = this.p2pChatPanels.find(f => f.user.KullaniciId === userId);
        let index = this.p2pChatPanels.indexOf(currentUser)
        this.p2pChatPanels.splice(index, 1)
        this.p2pChatPanels.splice(0, 0, currentUser);
    }


    openP2PChatPanel(friendId: number, friend: Friend, conferenceRequestResponse?: ConferenceRequestResponse, display: boolean = true, isMyFriend: boolean = true) {
        this.isMyFriend = isMyFriend;
        if (display) {
            this.indexedDBService.messageCounts[friendId] = 0;
        }
        this.mainLoading = true;
        let activeChatPanel = this.p2pChatPanels.find(f => f.user.KullaniciId === friendId);
        if (activeChatPanel) {
            activeChatPanel.display = display;
            //setTimeout(() => {
            if (!display) {
                this.showP2PChatPanel(friendId, false);
            } else {
                this.showP2PChatPanel(friendId, true,true);
            }

            //}, 200);

            activeChatPanel.conferenceRequestResponse = conferenceRequestResponse ? conferenceRequestResponse : new ConferenceRequestResponse()
        }
        else {
            let activeChatPanel = <ActiveChatPanel>{
                display: display,
                user: friend,
                conferenceRequestResponse: conferenceRequestResponse ? conferenceRequestResponse : new ConferenceRequestResponse()
            };
            this.p2pChatPanels.push(activeChatPanel);
            this.moveUserIconToTop(friendId);

            this.p2pChatPanelsAddedSource.next(activeChatPanel);
        }
        this.mainLoading = false;
    }

    removeP2PChatPanel(userId: number) {
        // setTimeout(() => {
        let activeChatPanel = this.p2pChatPanels.find(f => f.user.KullaniciId === userId);
        if (activeChatPanel) {
            activeChatPanel.display = false;
            // setTimeout(() => {
            this.p2pChatPanelsRemovedSource.next(userId);
            //}, 300);
            this.p2pChatPanels.forEach((element, index) => {
                if (element.user.KullaniciId === userId)
                    this.p2pChatPanels.splice(index, 1);
            });
        }
    }

    openRoomVideoPanel(romCamera: RoomCamera) {
        let roomVideoPanel = this.roomVideoPanels.find(f => f.userId === romCamera.userId);
        if (!roomVideoPanel) {
            this.roomVideoPanels.push(romCamera);
            //setTimeout(() => { 
            this.roomVideoPanelsAddedSource.next(romCamera);
            //}, 200);
        }
    }

    removeRoomVideoPanel(userId: number) {
        let roomVideoPanel = this.roomVideoPanels.find(f => f.userId === userId);

        if (roomVideoPanel) {
            this.roomVideoPanels = this.roomVideoPanels.filter(f => f.userId !== userId);
            // setTimeout(() => { 
            this.roomVideoPanelsRemovedSource.next(userId);
            //}, 200);
            //setTimeout(() => { 
            this.mainSwiperUpdatedSource.next(null);
            //}, 300);
        }
    }

    focusPanel(focusedPanelTag: string) {
        if (focusedPanelTag === '4') {
            this.p2pChatPanels.forEach(element => {
                element.display = false;
            });

            this.roomPageDisplay = true;
            this.activeMainPanel = MainPanels[focusedPanelTag]
        }
        this.focusedPanel = focusedPanelTag;
        // this.activeMainPanel = MainPanels[focusedPanelTag]
    }

    hideP2PChatPanel(userId: number) {
        setTimeout(() => {
            let activeP2PChatPanel = this.p2pChatPanels.find((f => f.user.KullaniciId === userId));
            let activeP2PChatPanelIndex = this.p2pChatPanels.indexOf(activeP2PChatPanel);
            this.p2pChatPanels[activeP2PChatPanelIndex].display = false;

            let deletedPanel = this.mobileActivePanelIdHistory.pop();
            let id = this.mobileActivePanelIdHistory.pop();
            if (id)
                this.swipeToPanel(id);

            this.focusedPanel === Constants.chatPanelTag + userId.toString() ?
                this.focusedPanel = "" :
                null;
            //this.mobileActivePanelIdHistory = this.mobileActivePanelIdHistory.filter(f => f !== deletedPanel);
        });
    }

    showP2PChatPanel(userId: number, display: boolean = true,fromList:boolean = false) {
        try {
            // if (this.roomPageDisplay){
            //     this.activeMainPanel = MainPanels.RoomPage;
            // }    
            let activeP2PChatPanel = this.p2pChatPanels.find(f => f.user.KullaniciId === userId);

            if (activeP2PChatPanel)
                activeP2PChatPanel.display = display;

            if (display) {
                this.focusedPanel = (Constants.chatPanelTag + userId.toString());
                //this.swipeToPanel(Constants.mainSwiperTag + '' + MainPanels.P2PChat + '_' + userId);

                this.indexedDBService.messageCounts[userId] = 0;
                this.indexedDBService.updateNewMessageCount(
                    this.indexedDBService.newMessageCountsUserKey,
                    userId,
                    0
                );
             this.indexedDBService.p2pChatInitSource.next(userId);
            }
            if (fromList != true) {
                if (this.utilService.isNullOrUndefined(activeP2PChatPanel) === false) {  //Sürekli yeni element oluşturduğu için kaldırıldı.
                    if (this.utilService.isNullOrUndefined(activeP2PChatPanel.videoContainer)) {
                        this.p2pChatPanelsAddedSource.next(activeP2PChatPanel);
                    }
                }
            }
           

            if (!this.showedP2p && display) {
                this.showedP2p = true;
            }

            //this.panelManagerService.openP2PChatPanel(friend.KullaniciId, this.indexedDBService.getFriendById(friend.KullaniciId));
        } catch (error) {
            console.log(error);
        }
    }

    showRoomVideoPanel(userId: number) {
        // this.swipeToPanel(Constants.mainSwiperTag + '' + MainPanels.RoomVideo + '_' + userId);
    }

    closeMainPanel() {
        if (this.activeMainPanel === MainPanels.FriendList) {
            this.currentMobileActivePanelId = Constants.mainSwiperTag + '' + MainPanels.RoomList;
            this.swipeToPanel(this.currentMobileActivePanelId);
        }
        this.activeMainPanel = MainPanels.None;
        this.showedFriendList = false;
    }

    openMainPanel(mainPanel: MainPanels) {
        if (this.indexedDBService.userFullInfo.RutbeFullInfo.ADI == "AIUser") {
            if (mainPanel == MainPanels.RoomList) {
                return
            }
        }

        if (mainPanel === MainPanels.MainMenu && this.deviceService.isMobile)
            this.mainMenuVisible = !this.mainMenuVisible;
        else {
            this.mainMenuVisible = false;

            this.activeMainPanel = mainPanel;

            this.currentMobileActivePanelId = Constants.mainSwiperTag + '' + mainPanel;
            this.swipeToPanel(this.currentMobileActivePanelId);

            if (this.deviceService.isMobile && mainPanel !== MainPanels.RoomPage) {
                this.roomPageDisplay = false;
            }
            // if (this.deviceService.isMobile)
            //     this.hideRoomPage();
        }

    }

    closeRoomPage() {
        this.roomOpened = false;
        this.activeMainPanel = MainPanels.RoomList;
        this.swipeToPanel(Constants.mainSwiperTag + MainPanels.RoomList);
        this.isHiddenRoom = true;
        this.removeRoomVideoPanel(this.indexedDBService.userFullInfo.KullaniciId);
        this.hideRoomPage();
       // this.roomPageOpenedSource.next(false);
    }

    openRoomPage() {
        if (this.roomOpened) {
            this.focusPanel((MainPanels.RoomPage).toString());
            this.roomSwiperIndex = RoomPagePanels.Chat;
            this.swipeToPanel(Constants.mainSwiperTag + MainPanels.RoomPage);
        } else {
            this.roomOpened = true;
            this.focusPanel((MainPanels.RoomPage).toString());
            this.roomPageOpenedSource.next(true);
            this.roomSwiperIndex = RoomPagePanels.Chat;
            this.swipeToPanel(Constants.mainSwiperTag + MainPanels.RoomPage);
        }
        this.showRoomPage();
    }
    showRoomPage() {
        this.roomPageDisplay = true;
        this.activeMainPanel = MainPanels.RoomPage;
        this.isHiddenRoom = false;
    }

    hideRoomPage() {
        this.roomPageDisplay = false;
        this.activeMainPanel = MainPanels.RoomList;
        this.focusedPanel = MainPanels.RoomList;
        this.isHiddenRoom = true;
        /*let deletedPanel = this.mobileActivePanelIdHistory.pop();
        let id = this.mobileActivePanelIdHistory.pop();
        if (id) {
            this.swipeToPanel(id);
        }

        this.mobileActivePanelIdHistory = this.mobileActivePanelIdHistory.filter(f => f !== deletedPanel);*/
    }


    openP2pConferencePanel(conferenceRequestResponse: ConferenceRequestResponse, userId: number, p2pConferenceId: string, contactType: ContactType) {
        let activeChatPanel = this.p2pChatPanels.find(f => f.user.KullaniciId === userId);
        if (activeChatPanel) {
            if (p2pConferenceId === activeChatPanel.conferenceRequestResponse.conferenceId) {
                activeChatPanel.chatPanelProps.ContactType = contactType;
            }

        }
    }



    closeP2pConferenceOutgoingCallingPanel(userId: number, p2pConferenceId: string) {
        let activeChatPanel = this.p2pChatPanels.find(f => f.user.KullaniciId === userId);
        if (activeChatPanel) {
            if (p2pConferenceId === activeChatPanel.conferenceRequestResponse.conferenceId)
                activeChatPanel.conferenceRequestResponse.show = false;
        }
    }


    showP2pConferenceIncomingCallPanel(p2pContactUser: Friend, conferenceRequestResponse: ConferenceRequestResponse) {


        this.openP2PChatPanel(p2pContactUser.KullaniciId, p2pContactUser);

        let activeChatPanel = this.p2pChatPanels.find(f => f.user.KullaniciId === p2pContactUser.KullaniciId);

        activeChatPanel.conferenceRequestResponse = <ConferenceRequestResponse>{
            conferenceId: conferenceRequestResponse.conferenceId,
            contactType: conferenceRequestResponse.contactType,
            userId: p2pContactUser.KullaniciId,
            show: true,
            receiving: true,
            candidates: conferenceRequestResponse.candidates,
            sdp: conferenceRequestResponse.sdp
        };
    }

    closeP2pConferenceIncomingCallingPanel(userId: number, p2pConferenceId: string) {
        let activeChatPanel = this.p2pChatPanels.find(f => f.user.KullaniciId === userId);
        if (activeChatPanel) {
            if (p2pConferenceId === activeChatPanel.conferenceRequestResponse.conferenceId)
                activeChatPanel.conferenceRequestResponse.show = false;
        }

        this.conferenceRejectedSource.next(null);
        // this.p2pConferenceIncomingCallPanelDisplay = false;
    }


    // #region Open Admin Page
    closeAdminPage() {
        this.adminOpened = false;
        this.hideAdminPage();
        this.adminPageOpenedSource.next(false);
    }

    closeRoomRecordingPage() {
        this.roomRecordingPanelDisplay = false;
    }

    openAdminPage(from?: string) {
        if (this.adminOpened) {
            if (!this.deviceService.isMobile) {
                this.focusPanel((MainPanels.Admin).toString());
                this.swipeToPanel(Constants.mainSwiperTag + MainPanels.Admin);
            }
        } else {
            this.loadAdminModule()
                .then(() => {
                    this.activeMainPanel = MainPanels.None;
                    this.adminPageOpenedSource.next(true);
                    if (!this.deviceService.isMobile) {
                        this.focusPanel((MainPanels.Admin).toString());
                        this.swipeToPanel(Constants.mainSwiperTag + MainPanels.Admin);
                    }
                });
        }
        this.showAdminPage();

        if (from === 'adminTab_report') {
            this.visibleComplainNotification = false;
        }

        if (from === 'adminTab_liveSupport') {
            this.visibleLiveSupportNotification = false;
        }
    }

    showAdminPage() {
        this.adminPageDisplay = true;
        if (!this.deviceService.isMobile) {
            this.activeMainPanel = MainPanels.Admin;
        }
    }

    hideAdminPage() {
        this.adminPageDisplay = false;

        if (this.deviceService.isMobile) {
            this.adminOpened = false;
        }
        else {
            let deletedPanel = this.mobileActivePanelIdHistory.pop();
            let id = this.mobileActivePanelIdHistory.pop();
            if (id)
                this.swipeToPanel(id);

            this.mobileActivePanelIdHistory = this.mobileActivePanelIdHistory.filter(f => f !== deletedPanel);
        }

    }
    // #endregion

}
