export const environment = {
  production: true,
  appVersion: '2.49.10-38f937cc',
  checkVersion: true,
  builtAppHash: '38f937cc7483651aebbfa9f189215693dedd9ea7',
  centrifugoWebsocketUrl: 'wss://centri.seslidunya.com/connection/websocket',
  jitsiurl: 'meetx.turkpages.com',
  webSiteUrl: 'https://web.seslidunya.com/',
  apiUrl: 'https://v2-be.seslidunya.com/',
  natsUrl: 'wss://centri.seslidunya.com',
  googleCientId: '590432653135-m4etmnl73b4h37pf4peqv3ik951fesm6.apps.googleusercontent.com',
  livekitUrl:   'wss://live-test.zumti.com',
  customerName: 'seslidunyacom',
  s3AccountUrl: 'https://d1sy3qwuhfvqo8.cloudfront.net/v2/',
};