import { MessageType } from './../../../models/enums';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ChatMessage } from 'src/app/core/models/chat-message';
import { Constants } from 'src/app/core/models/constants';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomService } from 'src/app/core/services/room.service';

@Component({
  selector: 'app-general-message-confirm',
  templateUrl: './general-message-confirm.component.html',
  styleUrls: ['./general-message-confirm.component.scss']
})
export class GeneralMessageConfirmComponent implements OnInit {

  timeLeftDefault: number = 20;
  timeLeft: number = this.timeLeftDefault;
  interval;

  accepted: boolean; 
  isGifOrEmoji: boolean = false;
  gifAddress: SafeHtml ;
  constructor(
    public panelManagerService: PanelManagerService,
    public roomService: RoomService,
    private sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    this.messageIsGif()
  }

  messageIsGif() {
    let message: ChatMessage = this.roomService.generalMessageToConfirm;
    if (message.MessageType == MessageType.Animation) {
      this.isGifOrEmoji = true;
      this.gifAddress = this.sanitizer.bypassSecurityTrustHtml(
        `<img alt="" src="${Constants.S3AccountCentralUrl}${this.roomService.generalMessageToConfirm.RtfText}.gif" />`
      );
    } else {
      const emojiRegex = /<img[^>]*class=["'][^"']*emoji[^"']*["'][^>]*>/g;
      if (emojiRegex.test(message.RtfText)) {
        this.isGifOrEmoji = true;
        this.gifAddress = this.sanitizer.bypassSecurityTrustHtml(message.RtfText);
      }
    }
  }
  
  ngOnDestroy(): void {
    this.roomService.generalMessageToConfirm = null;
    this.isGifOrEmoji = false;
  }

  onShow() {   
    this.accepted = null;
    this.timeLeft = this.timeLeftDefault;
    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {    
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } 
      else {   
        this.hide();
      }
    }, 1000)
  }

  hide(){
    clearInterval(this.interval);
    this.timeLeft = this.timeLeftDefault;
    this.panelManagerService.generalMessageConfirmPopupDisplay = false;
    this.isGifOrEmoji = false;
  }


  acceptOrRejectConfirm(acceptInvitation: boolean){
      this.accepted = true;
      this.roomService.callAcceptOrRejectGeneralMessage(this.roomService.generalMessageToConfirm, acceptInvitation)
      .then(() => {})
      .finally(() => {
        this.hide();
      });
     this.hide();
  }
}