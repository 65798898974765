import { Component, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';
import { RoomService } from '../../../../services/room.service';
import { ChatMessageService } from '../../../../services/chatMessage.service';
import { UtilService } from '../../../../services/util.service';
import { DynamicMethodsSendService } from '../../../../services/dynamic-methods-send.service';
import { AdminService } from '../../../../../admin/services/admin.service';
import { MessageSendingTypeImgUrl } from '../../../../models/constants';
import { ChatType, MessageSendingType, SikayetTipleri } from '../../../../models/enums';
import { IndexedDBService } from '../../../../services/indexed-db.service';
import { PanelManagerService } from '../../../../services/panel-manager.service';
import { RoomUser } from '../../../../models/room-user';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogService } from '../../../../services/confirmation-dialog.service';
import { ConfirmationDialogParams } from '../../../../models/dialog';
import { faPalette, faBroom, faSignOutAlt, faPencilAlt, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { RoomUserListService } from '../../../../services/room-user-list-service';
import { faFlag } from '@fortawesome/free-regular-svg-icons';
import { ReportService } from '../../../../services/report.service';
import { DynamicMethodsService } from 'src/app/core/services/dynamic-methods.service';

@Component({
  selector: 'app-room-editor-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditorContextMenuComponent implements OnInit {

  faPalette = faPalette;
  faBroom = faBroom;
  faSignOutAlt = faSignOutAlt;
  faPencilAlt = faPencilAlt;
  faFlag = faFlag;
  faUserTie = faUserTie;

  @ViewChild('roomEditorContextMenuClick') contextMenu: ContextMenuComponent;
  isThereRankForOpenAdmin: boolean = false;
  @Input() roomUser: RoomUser;
  params: ConfirmationDialogParams;

  roomUserUpdatedSubscription: Subscription;

  constructor(
    public indexedDBService: IndexedDBService,
    public roomService: RoomService,
    private chatMessageService: ChatMessageService,
    private utilService: UtilService,
    private dynamicMethodsSendService: DynamicMethodsSendService,
    private adminService: AdminService,
    private panelManagerService: PanelManagerService,
    private translateService: TranslateService,
    private confirmationDialogService: ConfirmationDialogService,
    private roomUserListService: RoomUserListService,
    private reportService: ReportService,
    private dynamicMethodsService:DynamicMethodsService

  ) {
    this.params = new ConfirmationDialogParams();

    this.roomUserUpdatedSubscription = this.roomUserListService.roomUserUpdated.subscribe((roomUser: RoomUser) => {
      if (roomUser.KullaniciId === this.indexedDBService.userFullInfo.KullaniciId) {
        this.roomUser = roomUser;
        this.setVisibility();
      };
    });
  }


  ngOnInit() {
    this.setVisibility();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.roomUserUpdatedSubscription)
      this.roomUserUpdatedSubscription.unsubscribe();
  }

  setVisibility() {
    this.setVisibilityForOpenAdmin();
  }

  async setVisibilityForOpenAdmin() {
    try {
      this.roomService.adminPanelTabs = await this.roomService.getOpenableAdminPanelTabList();

  
      if (this.utilService.isNullOrEmtpyObject(this.roomService.adminPanelTabs)) {
        this.isThereRankForOpenAdmin = false;
      } else {
        this.isThereRankForOpenAdmin = true;
      }
    } catch (error) {
      console.error("Error loading tabs: ", error);
      this.isThereRankForOpenAdmin = false;
    }
  }
  

  clearRoomChatPanel() {
    this.roomService.roomAllMessagesRemovedSource.next(null);
  }

  clearChatPanelMessageAreaForAllUsers() {
    // this.chatMessageService.sendRoomSettingMessage(this.roomService.currentRoom.Info.ID,
    //   [this.dynamicMethodsSendService.ClearTextbox()]
    // );

    let operationMessageForClearTextbox = this.dynamicMethodsService.prepareRoomSettingMessage(
      this.roomService.currentRoom.Info.ID,
      [this.dynamicMethodsSendService.ClearTextbox()]
     )
      this.dynamicMethodsService.publishDynClearTextboxAsRoomSettingMessage(this.roomService.currentRoom.Info.ID,operationMessageForClearTextbox)

    var adminActionMessage = this.utilService.getKeyWithTag("AllRoomChatsDeleted") +
      " (" + this.roomService.currentRoom.Info.NAME + ")";

    this.adminService.callSaveAdminActions(adminActionMessage, this.utilService.getKeyWithTag("AllRoomChatsDeleted"));

    this.chatMessageService.sendAdminMessageToOneRoom(this.roomService.currentRoom.Info.ID, adminActionMessage, ChatType.AdminChangesMessage)
    //this.chatMessageService.sendAdminBulkMessageToPublicChannel(adminActionMessage, Constants.BulkMessageUnnecessaryId, BulkMessageType.AllRooms, ChatType.AdminChangesMessage);

  }

  changeFont() {
    this.panelManagerService.changeFontPopupDisplay = true;
  }

 async openAdminPanel() {
    this.roomService.adminPanelTabs = await this.roomService.getOpenableAdminPanelTabList();
    console.log("this.roomService.adminPanelTabs :" ,this.roomService.adminPanelTabs)

    this.panelManagerService.openAdminPage();
  }

  logout() {
    this.params.title = this.translateService.instant('menu_signout');
    this.params.message = this.translateService.instant('are-you-sure-to-logout');
    this.params.display = true;
    this.params.acceptLabel = this.translateService.instant('Yes');
    this.params.rejectLabel = this.translateService.instant('No');

    this.params.ok = () => {
      this.roomService.signOut()
    };
    this.params.cancel = () => {
      return
    };

    this.confirmationDialogService.showPopup(this.params);
  }

  sendMessageToAllUsers() {
    this.roomService.currentMessageSendingType = MessageSendingType.GenelHerkes;
    this.roomService.currentMessageChatType = ChatType.RoomActionMessage;
    this.roomService.messageSendingTypeImgUrl = MessageSendingTypeImgUrl.GenelHerkes;
    this.roomService.selectedSendingMessageTypeSource.next(null);
  }

  sendMessageToAllAuthorizedUsers() {
    this.roomService.currentMessageSendingType = MessageSendingType.GenelAdmin;
    this.roomService.currentMessageChatType = ChatType.RoomActionMessage;
    this.roomService.messageSendingTypeImgUrl = MessageSendingTypeImgUrl.GenelAdmin;
    this.roomService.selectedSendingMessageTypeSource.next(null);
  }

  complainRoom() {
    this.reportService.header = this.roomService.currentRoom.Info.NAME;
    this.reportService.insertComplainRequest.ComplainType = SikayetTipleri.Oda;
    this.reportService.insertComplainRequest.ComplainingId = this.indexedDBService.userFullInfo.KullaniciId;
    this.reportService.insertComplainRequest.ComplainedId = this.roomService.currentRoom.Info.OWNER; //this.roomService.currentRoom.Info.ID;
    this.reportService.insertComplainRequest.Html = ""; //TODO this.message.messageHtml[0];
    this.reportService.reportPopupDisplay = true;
  }
}
