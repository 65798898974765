import { LegalWarningPageComponent } from './about-us-pages/legal-warning-page/legal-warning-page.component';
import { BrowserModule, Title,Meta  } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core.module';
import { SharedModule } from '../shared/shared.module';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { HttpModule } from '@angular/http';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

//#region internal components
import { UserPhotoComponent } from './component/user/user-photo/user-photo.component';
import { MainMenuBarPanelComponent } from './component/home-page/main-menu-bar-panel/main-menu-bar-panel.component';
import { MessagePanelComponent } from './component/general-panels/message-panel/message-panel.component';
// import { ConfirmationDialogComponent } from './component/general-panels/confirmation-dialog/confirmation-dialog.component';
import { RoomListComponent } from './component/room-list/room-list.component';
import { AddNewFriendComponent } from './component/main-menu/add-new-friend/add-new-friend.component';
import { ChangeFontComponent } from './component/main-menu/change-font/change-font.component';
import { ShowBlockedFiendsComponent } from './component/main-menu/show-blocked-friends/show-blocked-friends.component';
import { UpdateStateMessageComponent } from './component/main-menu/update-state-message/update-state-message.component';
import { HomePageComponent } from './component/home-page/home-page.component';
import { FriendtListComponent } from './component/friend-list/friend-list.component';
import { MainMenuComponent } from './component/main-menu/main-menu.component';
import { UpdateProfilePhotoComponent } from './component/main-menu/update-profile-photo/update-profile-photo.component';
import { PublicComponent } from './component/public/public.component';
import { PublicDirective } from './component/public/public.directive';
import { EditorComponent } from './component/editor/editor.component';
import { RankComponent } from './component/room/camera/rank/rank.component';
import { BubbleComponent } from './component/bubble/bubble.component';
import { BubbleDirective } from './component/bubble/bubble.directive';
import { MediaSettingsComponent } from './component/media-settings/media-settings.component';
import { MediaDeviceAccessModalComponent } from './component/media-device-access-modal/media-device-access-modal.component';


import { P2PChatBubbleComponent } from './component/p2p-chat/bubble/bubble.component';
import { P2PChatEditorComponent } from './component/p2p-chat/editor/editor.component';
import { P2PChatHeaderUpdateWallpaperComponent } from './component/p2p-chat/update-wallpaper/update-wallpaper.component';
import { P2PChatComponent } from './component/p2p-chat/p2p-chat.component';
import { P2PChatDirective } from './component/p2p-chat/p2p-chat.directive';

import { RoomMicrophoneQueueAcceptingPanelComponent } from './component/room/microphone-queue-accepting/microphone-queue-accepting.component';
import { RoomChatButtonsComponent } from './component/room/chat/buttons/buttons.component';
import { RoomChatButtonsAnimationsComponent } from './component/room/chat/buttons/animations/animations.component';
import { RoomChatButtonsMicrophoneSettingsComponent } from './component/room/chat/buttons/microphone-settings/microphone-settings.component';
import { RoomPasswordEntryComponent } from './component/room/password-entry/password-entry.component';
import { RoomSecondNickEntryComponent } from './component/room/second-nick-entry/second-nick-entry.component';
import { RoomChatEditorComponent } from './component/room/chat/editor/editor.component';
import { RoomChatBubbleComponent } from './component/room/chat/bubble/bubble.component';
import { RoomComponent } from './component/room/room.component';
import { RoomChatComponent } from './component/room/chat/chat.component';
import { RoomUserListBanComponent } from './component/room/user-list/ban/ban.component';
import { RoomUserListComponent } from './component/room/user-list/user-list.component';
import { RoomUserListUserComponent } from './component/room/user-list/user/user.component';
import { RoomUserListUserDirective } from './component/room/user-list/user/user.directive';
import { RoomCameraComponent } from './component/room/camera/camera.component';
import { RoomCameraDirective } from './component/room/camera/camera.directive';
import { EditorContextMenuComponent } from './component/room/chat/context-menu/context-menu.component';
import { FriendlistContextMenuComponent } from './component/friend-list/context-menu/context-menu.component';
import { ImageCropperCustomComponent } from './component/main-menu/update-profile-photo/image-cropper/image-cropper.component';
import { ImageCaptureComponent } from './component/main-menu/update-profile-photo/image-capture/image-capture.component';
import { SelectAvatarComponent } from './component/main-menu/update-profile-photo/select-avatar/select-avatar.component';
import { ConfirmationDialogComponent } from './component/general-panels/confirmation-dialog/confirmation-dialog.component';
import { YoutubeComponent } from './component/room/chat/buttons/youtube/youtube.component';
import { ViewRoomComponent } from './component/room-list/view-room/view-room.component';
import { InviteToRoomComponent } from './component/room/invite-to-room/invite-to-room.component';
import { VersionControlDialogComponent } from './version-control/dialog/version-dialog.component';
// import { SubAuthorListComponent } from '../admin/tabs/sub-authors/components/sub-author-list/sub-author-list.component';
// import { UsernameStyleChangeComponent } from '../admin/tabs/moderators/components/username-style-change/username-style-change.component';
import { PermissionsComponent } from './component/public/permissions/permissions.component';
import { OnMicComponent } from './component/room-list/on-mic/on-mic.component';
import { ReportComponent } from './component/report/report.component';
import { UnderConstructionComponent } from './component/public/under-construction/under-construction.component';
import { UserCardComponent } from './component/room/user-list/user-card/user-card.component';
import { RoomUserSliderListComponent } from './component/room/user-slider-list/user-slider-list.component';
import { UserOnMicComponent } from './component/room/chat/user-on-mic/user-on-mic.component';
import { UserOnMicDirective } from './component/room/chat/user-on-mic/user-on-mic.directive';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { UserIdComponent } from './component/user-id/user-id.component';
import { UsageAgreementPageComponent } from './about-us-pages/usage-agreement-page/usage-agreement-page.component';
import { WhoAreWePageComponent } from './about-us-pages/who-are-we-page/who-are-we-page.component';
import { UpdateUserNameComponent } from './component/main-menu/update-user-name/update-user-name.component';
import { AccountDeletionRequestComponent } from './component/main-menu/account-deletion-request/account-deletion-request.component';
import { ScreenShareComponent } from './component/room/screen-share/screen-share.component';
import { RoomScreenShareDirective } from './component/room/screen-share/screen-share.directive';
import { RoomRecordsComponent } from './component/room-records/room-records.component';

import { HlsVideoPlayerDirective } from './component/room-records/hls-video-player.directive';
import { PlayingVideoComponent } from './component/room-records/playing-video/playing-video.component';
import { GuestUserWarningComponent } from './component/guest-user-warning/guest-user-warning.component';
import { PhoneCallingComponent } from './component/room/phone-calling/phone-calling.component';
import { ControlOfRecordingComponent } from './component/room/control-of-recording/control-of-recording.component';
import { RoomLinkComponent } from './component/public/room-link/room-link.component';
import { RoomInfoCardComponent } from './component/room/room-info-card/room-info-card.component';
import { RoomIdComponent } from './component/room/room-id/room-id.component';
import { RoomListBackImageCropperComponent } from '../admin/tabs/rooms/components/room-list-back-image-cropper/room-list-back-image-cropper.component';
import { SendFolderToRoomComponent } from './component/room/chat/send-folder-to-room/send-folder-to-room.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { SendDocsToFriendComponent } from './component/p2p-chat/send-docs-to-friend/send-docs-to-friend.component';
import { ImageViewerComponent } from './component/room/chat/image-viewer/image-viewer.component';
import { P2PChatAnimationComponent } from './component/p2p-chat/friend-animation/friend-animation.component';
import { RoomAiImageCropperComponent } from '../admin/tabs/room-ai-template/components/room-ai-image-cropper/room-ai-image-cropper.component';
import { TemplateRoomListBackImageCropperComponent } from '../admin/tabs/room-ai-template/components/template-room-list-back-image-cropper/template-room-list-back-image-cropper.component';
import { TemplateOwnerImageCropperComponent } from '../admin/tabs/room-ai-template/components/template-owner-image-cropper/template-owner-image-cropper.component';
import { IncomingMessagesListComponent } from './component/incoming-messages-list/incoming-messages-list.component';
import { UsernameEntryComponent } from './component/public/dynamic-room/unregister-type/username-entry/username-entry.component';
import { RedirectRegisterTypeComponent } from './component/public/dynamic-room/register-type/redirect-register-type/redirect-register-type.component';
import { RegisterTypeLoginComponent } from './component/public/dynamic-room/register-type/register-type-login/register-type-login.component';
import { RedirectUnRegisterTypeComponent } from './component/public/dynamic-room/unregister-type/redirect-unregister-type/redirect-unregister-type.component';
import { UnRegisterTypeLogin } from './component/public/dynamic-room/unregister-type/unregister-type-login/unregister-type-login.component';
import { KFormatterPipe } from './component/room/pipes/k-formatter.pipe';
import { GlobalService } from './services/global.service';
import { OpenMicRequestComponent } from './component/room/open-mic-request/open-mic-request.component';
import { GeneralMessageConfirmComponent } from './component/room/general-message-confirm/general-message-confirm.component';
import { RoomMessageProcessComponent } from './component/bubble/room-message-process/room-message-process.component';
import { P2pMessageProcessComponent } from './component/p2p-chat/bubble/p2p-message-process/p2p-message-process.component';
//#endregion

@NgModule({
  declarations: [
    VersionControlDialogComponent,
    AppComponent,
    ConfirmationDialogComponent,
    //#region P2P Chat Panel
    P2PChatBubbleComponent,
    P2PChatEditorComponent,
    P2PChatComponent,
    P2PChatDirective,
    KFormatterPipe,

    P2PChatHeaderUpdateWallpaperComponent,

    //#endregion


    //#region Room Panel 

    RoomCameraComponent,
    ScreenShareComponent,
    RoomCameraDirective,
    RoomScreenShareDirective,
    RoomMicrophoneQueueAcceptingPanelComponent,
    RoomChatButtonsComponent,
    RoomChatButtonsAnimationsComponent,
    P2PChatAnimationComponent,
    RoomChatButtonsMicrophoneSettingsComponent,
    RoomPasswordEntryComponent,
    UsernameEntryComponent,
    RoomSecondNickEntryComponent,
    RoomChatEditorComponent,
    RoomChatBubbleComponent,
    RoomComponent,
    RoomChatComponent,
    RoomUserListBanComponent,
    RoomUserListComponent,
    RoomUserListUserComponent,
    RoomUserListUserDirective,
    HlsVideoPlayerDirective,
    UserOnMicDirective,
    BubbleComponent,
    BubbleDirective,
    //#endregion

    MediaSettingsComponent,
    MediaDeviceAccessModalComponent,
    EditorComponent,
    PublicComponent,
    RoomLinkComponent,
    UnRegisterTypeLogin,
    RedirectUnRegisterTypeComponent,
    RedirectRegisterTypeComponent,
    RegisterTypeLoginComponent,
    HomePageComponent,
    MainMenuBarPanelComponent,
    PublicDirective,
    MessagePanelComponent,
    RoomListComponent,
    FriendtListComponent,
    IncomingMessagesListComponent,
    AddNewFriendComponent,
    ChangeFontComponent,
    ShowBlockedFiendsComponent,
    UpdateStateMessageComponent,
    UpdateUserNameComponent,
    ControlOfRecordingComponent,
    SendFolderToRoomComponent,
    SendDocsToFriendComponent,
    ImageViewerComponent,
    GuestUserWarningComponent,
    PhoneCallingComponent,
    AccountDeletionRequestComponent,
    ImageCropperCustomComponent,
    RoomListBackImageCropperComponent,
    RoomAiImageCropperComponent,
    TemplateRoomListBackImageCropperComponent,
    TemplateOwnerImageCropperComponent,
    MainMenuComponent,
    UpdateProfilePhotoComponent,
    EditorContextMenuComponent,
    //AdminComponent,
    RoomRecordsComponent,
    PlayingVideoComponent,
    FriendlistContextMenuComponent,
    ImageCaptureComponent,
    SelectAvatarComponent,
    UserPhotoComponent,
    RankComponent,
    YoutubeComponent,
    ViewRoomComponent,
    InviteToRoomComponent,
    OpenMicRequestComponent,
    // UsernameStyleChangeComponent,
    // SubAuthorListComponent,
    PermissionsComponent,
    OnMicComponent,
    ReportComponent,
    UnderConstructionComponent,
    UserCardComponent,
    RoomInfoCardComponent,
    RoomUserSliderListComponent,
    UserOnMicComponent,
    UserIdComponent,
    RoomIdComponent,
    UsageAgreementPageComponent,
    WhoAreWePageComponent,
    LegalWarningPageComponent,
    GeneralMessageConfirmComponent,
    RoomMessageProcessComponent,
    P2pMessageProcessComponent
  ],
  imports: [
    NgxDocViewerModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    NoopAnimationsModule,
    HttpModule,
    HttpClientModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }),
  ],
  exports:[
    SharedModule,
    UserPhotoComponent,
    RankComponent,
    VersionControlDialogComponent,
    // SubAuthorListComponent,
    // UsernameStyleChangeComponent,
    UserIdComponent,
    RoomIdComponent
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    Title,
    Meta,
    GlobalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
