import { P2PService } from './../../../../services/p2p.service';
import { Component, OnInit } from '@angular/core';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';

@Component({
  selector: 'app-p2p-message-process',
  templateUrl: './p2p-message-process.component.html',
  styleUrls: ['./p2p-message-process.component.scss']
})
export class P2pMessageProcessComponent implements OnInit {

  faCopy = faCopy;
  constructor(
    public panelManagerService: PanelManagerService,
    public indexedDBService: IndexedDBService,
    public messagePanelService: MessagePanelService,
    public p2PService:P2PService
  ) {
  }

  ngOnInit(): void {
  }

  closeRoomMessageProcessPanel() {
    this.panelManagerService.p2pMessageProcessPopupDisplay = false;
  }

  copyMessageContent(event){
    event.stopPropagation();
    navigator.clipboard.writeText(this.p2PService.p2pMessageWillProcess.messageContent)
      .then(() => {
        this.messagePanelService.ShowPopupMessageWithLocalization('copiedSuccessfully', 2000);
      });
  }
}