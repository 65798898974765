import { SqlUser } from 'src/app/admin/models/users';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ServiceInformation } from 'src/app/core/services/serviceinformation.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { UtilService } from 'src/app/core/services/util.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AuthorizedUsers, AuthorizedUserDetails, AuthorizedUser, UserAllLoginInfo } from '../models/moderators';
import { KeepAdminLog } from 'src/app/admin/models/keep-admin-log';
import { AdminService } from 'src/app/admin/services/admin.service';
import { ChatMessageService } from 'src/app/core/services/chatMessage.service';
import { DynamicMethodsSendService } from 'src/app/core/services/dynamic-methods-send.service';
import { Constants } from 'src/app/core/models/constants';
import { BulkMessageType, ChatType } from 'src/app/core/models/enums';
import { GetRutbeInfo } from '../../ranks/models/rank';
import { formatDate } from '@angular/common';
import { error } from 'console';
import { DynamicMethodsService } from 'src/app/core/services/dynamic-methods.service';
import { AltYetkililer } from '../../sub-authors/models/sub-authors';
import { environment } from 'src/environments/environment';
import { RoomService } from 'src/app/core/services/room.service';
import { CategoryModel } from '../../categories/models/category';

@Injectable({
  providedIn: 'root'
})

export class AdminModeratorService extends ServiceInformation {

  userDeletedSource: Subject<any> = new Subject();
  userDeleted = this.userDeletedSource.asObservable();

  userNameStyleChangePopupDisplay: boolean = false;
  reasonRankChangePopupDisplay: boolean = false;

  currentPage;//= CurrentModeratorPage.moderatorList;
  hasTopOfficial: boolean = false;
  authorizedUsers: AuthorizedUsers;
  notAuthorizedUser: AuthorizedUser = new AuthorizedUser();
  authorizedUserDetail: AuthorizedUserDetails = new AuthorizedUserDetails();
  isThereAtLeastOneRankChange: boolean = false;
  isAuthorityAlreadyNull: boolean = false;
  userAllLoginInfo: UserAllLoginInfo[] = [];
  totalRecordOfUserAllLoginInfo: number = 0
  endDate: Date;
  startDate: Date;
  aciklama: string;

  dayLeft: number;

  subAuthorList: Array<AltYetkililer> = [];

  selectedTimeLimitType : any
  timeLimitTypeOptions: any[] = [];


  selectCategoryPopupDisplay: boolean = false;
  categoryDetailPopupDisplay: boolean = false;
  selectedCategoryDetail:CategoryModel
  constructor(
    private http: HttpClient,
    protected messagePanelService: MessagePanelService,
    protected utilService: UtilService,
    protected indexedDBService: IndexedDBService,
    private adminService: AdminService,
    private chatMessageService: ChatMessageService,
    private dynamicMethodsSendService: DynamicMethodsSendService,
    private dynamicMethodsService:DynamicMethodsService,
    public roomService:RoomService
  ) {
    super(messagePanelService, utilService, indexedDBService);
  }

  populateTimeLimitTypeOptions() {
    this.timeLimitTypeOptions = [
      { label: 'Günlük', value:'1' },
      { label: 'Haftalık', value: '2' },
      { label: 'Aylık', value: '3'}
    ];
  }

  setSelectedTimeLimitType() {
    const type = this.timeLimitTypeOptions.find(option => option.value === this.authorizedUserDetail.User.TimeLimitType);
    if (type) {
        this.selectedTimeLimitType = type;
    } else {
        this.selectedTimeLimitType = null
    }
  }
  //#region get moderator list and not authorized user
  callGetAuthorizedUsers(isAuthorizedUsers: boolean, pageIndex: number, pageSize: number, rutbeDerece: number, searchText: string, isIdSearch: boolean): Promise<AuthorizedUsers> {
    let body = {
      "ClientInfo": this.Get_ClientInfo(),
      "SearchText": searchText,
      "IsAuthorizedUsers": isAuthorizedUsers,
      "RutbeDerece": rutbeDerece,
      "PageSize": pageSize,
      "PageIndex": pageIndex,
      "IsIDSearch": isIdSearch
    }

    return new Promise((resolve, reject) => {
      this.getAuthorizedUsers(body)
        .subscribe((authorizedUsers: AuthorizedUsers) => {
          if (this.utilService.isNullOrEmtpyString(authorizedUsers) === false) {
            return resolve(authorizedUsers);
          }
          else
            return reject(null);
        }),
        error => {
          console.log(error);
          return reject(null);
        };
    })
  }

  getAuthorizedUsers(body): Observable<any> {
    const endpoint = this.ServiceUrl + "api/admin/authuser/list";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  callGetNotAuthorizedUser(searchText: string): Promise<void> {
    let body = {
      "ClientInfo": this.Get_ClientInfo(),
      "SearchText": searchText,
      "IsAuthorizedUsers": false,
      "RutbeDerece": 0,
      "PageSize": 25,
      "PageIndex": 1,
      "IsIDSearch": false
    }

    return new Promise((resolve, reject) => {
      this.getAuthorizedUsers(body)
        .subscribe((notAuthorizedUser: AuthorizedUsers) => {
          if (notAuthorizedUser.AuthorizedUsers.length > 0) {
            this.notAuthorizedUser = notAuthorizedUser.AuthorizedUsers[0];
            resolve();
          }
          else
            reject();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  getNotAuthorizedUser(body): Observable<any> {
    const endpoint = this.ServiceUrl + "api/admin/authuser/list";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  //#endregion

  //#region get moderator/user detail
callGetAuthorizedUserDetailById(userId: number): Promise<void> {
  let body = {
    "UserId": userId
  };

  return new Promise((resolve, reject) => {
    this.getAuthorizedUserDetailById(body).subscribe(
      (authorizedUserDetail: AuthorizedUserDetails) => {
        if (this.utilService.isNullOrEmtpyString(authorizedUserDetail) === false) {
          this.authorizedUserDetail = authorizedUserDetail;
          if (authorizedUserDetail.User.IP) {
            this.totalRecordOfUserAllLoginInfo = this.authorizedUserDetail.User.IP.length;
          }
          if (this.utilService.isNullOrUndefined(this.authorizedUserDetail.Yetki)) {
            this.isAuthorityAlreadyNull = true;
          }
          const endDate = new Date(authorizedUserDetail.User.BITIS_TARIHI);
          const currentDate = new Date();
          this.dayLeft = this.calculateDayLeftIfValid(endDate, currentDate);
          resolve();
        } else {
          reject(new Error('Empty response received'));
        }
      },
      error => {
        console.log(error);
        reject(error); // Reject with the actual error object
      }
    );
  });
}

calculateDayLeftIfValid(endDate: Date, currentDate: Date): number | undefined {
  if (endDate > currentDate) {
    return this.calculateRemainingDays(endDate);
  } else {
    return undefined;
  }
}

calculateRemainingDays(endDate: Date): number {
  const currentDate = new Date();
  const currentDateMilliseconds = currentDate.getTime();
  const endDateMilliseconds = endDate.getTime();
  const remainingTimeMilliseconds = endDateMilliseconds - currentDateMilliseconds;
  const remainingDays = remainingTimeMilliseconds / (1000 * 60 * 60 * 24);
  return Math.ceil(remainingDays);
}

  callGetSubUsers(userId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getSubUsers(userId)
        .toPromise()
        .then((subAuthorList: Array<AltYetkililer>) => {
          resolve();
          if (this.utilService.isNullOrUndefined(subAuthorList))
            reject();
          this.subAuthorList = subAuthorList;
          this.subAuthorList.forEach((subAuthor) => {
            subAuthor.SonGirisTarihi = formatDate(subAuthor.SonGirisTarihi, 'yyyy-MM-dd HH:mm:ss', 'en-US')
          })
          resolve();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  getSubUsers(userId:number): Observable<AltYetkililer[]> {
    const endpoint = `${environment.apiUrl}api/admin/subusers/list/${userId}`;
    return this.http.get<AltYetkililer[]>(endpoint)
      .pipe(catchError(error => this.handleError(error)));
  }

  callGetUserLastLoginDetailById(userId: number, pageIndex: number, pageSize: number): Promise<void> {
    let body = {
      "UserId": userId,
      "PageIndex": pageIndex,
      "PageSize": pageSize
    };

    return new Promise((resolve, reject) => {
      this.getAuthorizedUserDetailById(body)
        .subscribe((authorizedUserDetail: AuthorizedUserDetails) => {
          if (this.utilService.isNullOrEmtpyString(authorizedUserDetail) === false) {
            this.userAllLoginInfo = [];
            if (authorizedUserDetail.User.IP) {
              authorizedUserDetail.User.IP.forEach((element, index) => {
                let userLoginInfo = new UserAllLoginInfo();
                userLoginInfo.IP = element;
                this.userAllLoginInfo.push(userLoginInfo);
              });
  
              authorizedUserDetail.User.FINGERPRINT.forEach((element, index) => {
                this.userAllLoginInfo[index].FINGERPRINT = element;
              });
  
              authorizedUserDetail.User.TABID.forEach((element, index) => {
                this.userAllLoginInfo[index].TABID = element;
              });
  
              authorizedUserDetail.User.LOGINDATE.forEach((element, index) => {
                this.userAllLoginInfo[index].LOGINDATE = element;
                this.userAllLoginInfo[index].LOGINDATE = formatDate(this.userAllLoginInfo[index].LOGINDATE, 'yyyy-MM-dd HH:mm:ss', 'en-US')
              });
              resolve();
            }
          } else {
            this.userAllLoginInfo = [];
            reject();
          }
        }),
        error => {
          console.log(error);
          this.userAllLoginInfo = [];
          reject();
        };
    });
  }


  getAuthorizedUserDetailById(body): Observable<any> {
    const endpoint = this.ServiceUrl + "api/admin/authuser/detail";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  //#endregion

  //#region delete user operations
  callDeleteUserById(userId: number, userName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.deleteUserById(userId)
        .subscribe(response => {
          if (this.utilService.isNullOrEmtpyString(response) || response.Result === false) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject();
            return;
          }
          if (response.Result === true) {
            var keepAdminLog = new KeepAdminLog();
            keepAdminLog.AdminLog = this.utilService.getKeyWithTag("UserDeleted") + "(" + userName + ").\n ";
            keepAdminLog.TargetName = this.utilService.getKeyWithTag("DeleteUserTitle");
            keepAdminLog.UserMessage = "DeletedPerson";
            this.adminService.callSaveAdminActions(keepAdminLog.AdminLog, keepAdminLog.TargetName)
              .then(() => {
                this.sendMessageToUserForDeleted(userId, userName);
              })
              .then(() => {
                this.messagePanelService.ShowPopupMessageWithLocalization('DeletedPerson', 2000);
              })
              .then(() => {
                this.userDeletedSource.next(null);
              })
              .catch(error => {
                console.log(error);
              });
          }
          resolve(response);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  deleteUserById(userId): Observable<any> {
    const endpoint = `${this.ServiceUrl}api/admin/account/delete/${userId}`;
    return this.http.delete<any>(endpoint, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  sendMessageToUserForDeleted(userId: number, username: string) {
    let operationMessageForKullaniciLogOut = this.dynamicMethodsService.prepareUserSettingMessage(
      userId,
      [this.dynamicMethodsSendService.KullaniciLogOut(true)],
      username)
    this.dynamicMethodsService.publishDynKullaniciLogOut(this.indexedDBService.userFullInfo.KullaniciId,userId,operationMessageForKullaniciLogOut);

    let operationMessageForKullaniciAdminHareketiMesajGoster = this.dynamicMethodsService.prepareUserSettingMessage(
      userId,
      [this.dynamicMethodsSendService.KullaniciAdminHareketiMesajGoster(userId, this.utilService.getKeyWithTag('YourAccountDeleted'))],
      username)
    this.dynamicMethodsService.publishDynKullaniciAdminHareketiMesajGoster(this.indexedDBService.userFullInfo.KullaniciId,userId,operationMessageForKullaniciAdminHareketiMesajGoster);
  }
  //#endregion

  //#region  delete user image 
  callDeleteUserImage(userId: number, userName: string): Promise<any> {
    let body = {
      "ClientInfo": this.Get_ClientInfo(),
      "UserId": userId
    }
    return new Promise((resolve, reject) => {
      this.deleteUserImage(body)
        .subscribe(response => {
          if (this.utilService.isNullOrEmtpyString(response) || response.Result === false) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            return;
          }
          if (response.Result === true) {
            var keepAdminLog = new KeepAdminLog();
            keepAdminLog.AdminLog = this.utilService.getKeyWithTag("UserProfilePhotoDeleted") + "(" + userName + ").\n ";
            keepAdminLog.TargetName = this.utilService.getKeyWithTag("DeleteUserProfilePhotoTitle");
            keepAdminLog.UserMessage = "SuccessfullyDeleted";
            this.adminService.callSaveAdminActions(keepAdminLog.AdminLog, keepAdminLog.TargetName)
              .then(() => {
                this.sendMessageToUserForProfilePhotoDeleted(userId, userName);
              })
              .then(() => {
                this.messagePanelService.ShowPopupMessageWithLocalization('SuccessfullyDeleted', 2000);
              })
              .catch(error => {
                this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                console.log(error);
              });
          }
          resolve(response);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  sendMessageToUserForProfilePhotoDeleted(userId: number, userName: string) {
    // this.chatMessageService.sendUserSettingMessage(userId,
    //   [
    //     // this.dynamicMethodsSendService.RefreshUser(userId),
    //     //this.dynamicMethodsSendService.KullaniciBilgiEkle(false),
    //     // this.dynamicMethodsSendService.SetPictureboxImage(),
    //     // this.dynamicMethodsSendService.DeleteUserImage_SendMessageToRoom(),
    //     //this.dynamicMethodsSendService.KullaniciAdminHareketiMesajGoster(userId, this.utilService.getKeyWithTag('UserProfilePhotoDeleted'))
    //   ],
    //   userName
    // );
    let operationMessageForRefreshUser = this.dynamicMethodsService.prepareUserSettingMessage(
      userId,
      [this.dynamicMethodsSendService.RefreshUser(userId)],userName)
    this.dynamicMethodsService.publishDynRefreshUser(this.indexedDBService.userFullInfo.KullaniciId,userId,operationMessageForRefreshUser);

    let operationMessageForKullaniciBilgiEkle = this.dynamicMethodsService.prepareUserSettingMessage(
      userId,
      [this.dynamicMethodsSendService.KullaniciBilgiEkle(false)],userName)
     this.dynamicMethodsService.publishDynKullaniciBilgiEkle(this.indexedDBService.userFullInfo.KullaniciId,userId,operationMessageForKullaniciBilgiEkle);
  
     let operationMessageForKullaniciAdminHareketiMesajGoster = this.dynamicMethodsService.prepareUserSettingMessage(
      userId,
      [this.dynamicMethodsSendService.KullaniciAdminHareketiMesajGoster(userId, this.utilService.getKeyWithTag('UserProfilePhotoDeleted'))],
      userName)
    this.dynamicMethodsService.publishDynKullaniciAdminHareketiMesajGoster(this.indexedDBService.userFullInfo.KullaniciId,userId,operationMessageForKullaniciAdminHareketiMesajGoster);

    let operationMessageForSetPictureboxImage = this.dynamicMethodsService.prepareUserSettingMessage(
      userId,
      [this.dynamicMethodsSendService.SetPictureboxImage()],
      userName)
    this.dynamicMethodsService.publishDynSetPictureboxImage(this.indexedDBService.userFullInfo.KullaniciId,userId,operationMessageForSetPictureboxImage);

    let operationMessageDeleteUserImage_SendMessageToRoom = this.dynamicMethodsService.prepareUserSettingMessage(
      userId,
      [this.dynamicMethodsSendService.DeleteUserImage_SendMessageToRoom()],
      userName)
    this.dynamicMethodsService.publishDynDeleteUserImage_SendMessageToRoom(this.indexedDBService.userFullInfo.KullaniciId,userId,operationMessageDeleteUserImage_SendMessageToRoom);
  }

  deleteUserImage(body): Observable<any> {
    // const endpoint = this.ServiceUrl + "DeleteUserImage";
    const endpoint = this.ServiceUrl + "api/admin/user/deleteimage";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  checkMicrophoneRoomByUserId(userId: number): Observable<any> {
    let body = {
      "userID": userId
    }
    const endpoint = this.ServiceUrl + "api/user/room";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  callCheckMicrophoneRoomByUserId(userId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.checkMicrophoneRoomByUserId(userId).subscribe(response => {
        resolve(response.roomID);
      }, error => {
        console.log(error);
        reject();
      });
    })
  }
  //#endregion

  //#region save username style
  callSaveUserNickSettings(userId: number, nickjson: string, flashnickop: number, flashNickBlob: string, username: string): Promise<void> {
    let body = {
      "ClientInfo": this.Get_ClientInfo(),
      "UserId": userId,
      "NickJson": nickjson,
      "FlashNickOperation": flashnickop,
      "FlashNickBlob": flashNickBlob
    };

    return new Promise((resolve, reject) => {
      this.saveUserNickSettings(body)
        .subscribe(response => {
          if (this.utilService.isNullOrEmtpyString(response) || response.Result === false) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            return;
          }
          if (response.Result === true) {
            this.messagePanelService.ShowPopupMessageWithLocalization("SavedSuccess", 2000);

            var logObject = this.getAdminLog(response, username);
            var keepAdminLog = new KeepAdminLog();
            keepAdminLog.TargetName = this.utilService.getKeyWithTag("UpdateUser");
            keepAdminLog.UserMessage = "UserNameStyleUpdated";
            keepAdminLog.AdminLog = logObject.adminHareketleriMesaj;

            this.adminService.callSaveAdminActions(keepAdminLog.AdminLog, keepAdminLog.TargetName)
              .then(() => {
                this.chatMessageService.sendAdminBulkMessageToPublicChannel(
                  logObject.adminHareketleriMesaj,
                  Constants.BulkMessageUnnecessaryId,
                  BulkMessageType.AllRooms,
                  ChatType.AdminChangesMessage
                );
              })
              .then(() => {
                // this.chatMessageService.sendUserSettingMessage(userId,
                //   [
                //     // this.dynamicMethodsSendService.RefreshUser(userId),
                //     //this.dynamicMethodsSendService.KullaniciAdminHareketiMesajGoster(userId, logObject.kullaniciyaMesaj)
                //   ],
                //   username
                // );
                let operationMessageForRefreshUser = this.dynamicMethodsService.prepareUserSettingMessage(
                  userId,
                  [this.dynamicMethodsSendService.RefreshUser(userId)],username)
                this.dynamicMethodsService.publishDynRefreshUser(this.indexedDBService.userFullInfo.KullaniciId,userId,operationMessageForRefreshUser);
              
                let operationMessageForKullaniciAdminHareketiMesajGoster = this.dynamicMethodsService.prepareUserSettingMessage(
                  userId,
                  [this.dynamicMethodsSendService.KullaniciAdminHareketiMesajGoster(userId, logObject.kullaniciyaMesaj)],
                  username)
                this.dynamicMethodsService.publishDynKullaniciAdminHareketiMesajGoster(this.indexedDBService.userFullInfo.KullaniciId,userId,operationMessageForKullaniciAdminHareketiMesajGoster);
              
              })
              .then(() => {
                let body = {
                  "ClientInfo": null,
                  "UserId": userId,
                };
                return this.getAuthorizedUserDetailById(body).toPromise()
              })
              .then((authorizedUserDetail: AuthorizedUserDetails) => {
                if (this.utilService.isNullOrEmtpyString(authorizedUserDetail) === false) {
                  // this.authorizedUserDetail = authorizedUserDetail;
                  // resolve(authorizedUserDetail);
                  if (this.utilService.isNullOrUndefined(this.authorizedUserDetail.Yetki))
                    this.isAuthorityAlreadyNull = true;
                  resolve()
                }
                else
                  reject();
              })
              .catch(error => {
                console.log(error);
                reject();
              });
          }
          resolve(response);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  getAdminLog(response, username: string) {
    var adminHareketleriMesaj = "";
    var kullaniciyaMesaj = "";

    if (response.AzureUploadGifSASUrl != null)
      adminHareketleriMesaj += this.utilService.getKeyWithTag("FlashNickUpdated") + "\n";
    else if (response.AzureDeleteGifSASUrl != null)
      adminHareketleriMesaj += this.utilService.getKeyWithTag("FlashNickDeleted") + "\n";

    adminHareketleriMesaj += this.utilService.getKeyWithTag("NickSettingsChanged");

    kullaniciyaMesaj += adminHareketleriMesaj;

    adminHareketleriMesaj = adminHareketleriMesaj + " (" + username + ")";

    return { adminHareketleriMesaj: adminHareketleriMesaj, kullaniciyaMesaj: kullaniciyaMesaj };
  }

  saveUserNickSettings(body): Observable<any> {
    // const endpoint = this.ServiceUrl + "SaveUserNickSettings";
    const endpoint = this.ServiceUrl + "api/admin/nicksettings/save";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  //#endregion

  //#region  save authorized user
  callSaveAuthorizedUser(newAuthority: GetRutbeInfo[], newUser: any, isSubAuthorityChanged: boolean = false): Promise<void> {
    let body = {
      "User": newUser,
      "SubUserAuthority": newAuthority,
      "IsSubAuthorityChanged": isSubAuthorityChanged
    };

    return new Promise((resolve, reject) => {
      this.saveAuthorizedUser(body)
        .subscribe(response => {
          if (this.utilService.isNullOrUndefined(response)) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject();
          }

          if (response.Result === false) {
            if (this.utilService.isNullOrUndefined(response.ErrorMessage))
              this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            else
              this.messagePanelService.ShowPopupMessageWithLocalization(response.ErrorMessage, 2000);
            reject();
          }

          if (response.Result === true) {
            resolve();
          }
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }


  saveAuthorizedUser(body): Observable<any> {
    const endpoint = this.ServiceUrl + "SaveAuthorizedUser";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  //#endregion
}