<div class="panel">
  <div class="static-buttons-div" #staticButtonsDiv>
    <div class="main-menu-button-div profile-button" data-testid="profile" (click)="panelManagerService.openMainPanel(mainPanels.MainMenu)"
      pTooltip="{{ 'Profile' | translate }}" tooltipPosition="right">
      <app-user-photo [userPhotoId]="indexedDBService.userFullInfo?.ProfilResmiId"
        [stateId]="indexedDBService.userFullInfo?.StateId" [width]="deviceService.isMobile?30:40"
        [height]="deviceService.isMobile?30:40" [showOnlineStateIcon]="true">
      </app-user-photo>
    </div>

    <div class="main-menu-button-div" (click)="panelManagerService.openMainPanel( mainPanels.FriendList)" 
        [style.disabled]="(panelManagerService.loadedFriends | async)">
      <div class="main-menu-button-img-div" pTooltip="{{ 'chats' | translate }}" tooltipPosition="right">
        <div class="mainWebLeftButtonIcon" *ngIf="indexedDBService.totalMessageCount > 0 || indexedDBService.Friends?.IncomingFriends?.length > 0">
          <span class="notification">1</span>
        </div>
        <fa-icon [icon]="faUsers" class="friends-button-img"></fa-icon>
      </div>
      <div *ngIf="false" class="menu-title">{{ 'chats' | translate }}</div>
    </div>

    <div class="main-menu-button-div" (click)="loadStaticRoomList()">
      <div class="main-menu-button-img-div" pTooltip="{{ 'Rooms' | translate }}" tooltipPosition="right">
        <div class="mainWebLeftButtonIcon" *ngIf="roomService.onlineUserCount >= 0">{{ roomService.onlineUserCount }}</div>
        <fa-icon [icon]="faComments" class="rooms-button-img"></fa-icon>
      </div>

      <div *ngIf="false" class="menu-title">{{ 'Rooms' | translate }}</div>
    </div>

    <div class="main-menu-button-div" *ngIf="panelManagerService.roomOpened"
      (click)="panelManagerService.openRoomPage()" pTooltip="{{  roomService.currentRoom.Info?.NAME }}"
      tooltipPosition="right">
      <div class="main-menu-button-img-div">
        <fa-icon [icon]="faCommentDots" class="room-button-img"></fa-icon>
      </div>
      <div class="menu-title">{{ roomService.currentRoom.Info?.NAME }}</div>
    </div>

    <div class="main-menu-button-div" *ngIf="panelManagerService.visibleComplainNotification">
      <div class="main-menu-button-img-div" pTooltip="{{  'NewComplainNotificationTitle' | translate }}"
        tooltipPosition="right" (click)="openAdminPage('adminTab_report')">
        <!-- <img src="https://sesliturkiye.azureedge.net/themes/SesliTurkiye/admin.png" class="admin-button-img"> -->
        <fa-icon [icon]="faFlag" class="complain-button-img"></fa-icon>
      </div>
    </div>

    <div class="main-menu-button-div" *ngIf="panelManagerService.visibleLiveSupportNotification">
      <div class="main-menu-button-img-div" pTooltip="{{  'NewLiveSupportNotificationTitle' | translate }}"
        tooltipPosition="right" (click)="openAdminPage('adminTab_liveSupport')">
        <!-- <img src="https://sesliturkiye.azureedge.net/themes/SesliTurkiye/admin.png" class="admin-button-img"> -->
        <fa-icon [icon]="faHeadset" class="live-support-button-img"></fa-icon>
      </div>
    </div>

    <div class="main-menu-button-div" *ngIf="panelManagerService.adminOpened">
      <div class="main-menu-button-img-div" pTooltip="{{  'admin' | translate }}" tooltipPosition="right"
        (click)="panelManagerService.openAdminPage()">
        <!-- <img src="https://sesliturkiye.azureedge.net/themes/SesliTurkiye/admin.png" class="admin-button-img"> -->
        <fa-icon [icon]="faUserTie" class="admin-button-img"></fa-icon>
      </div>
    </div>

  </div>

  <!-- [ngStyle]="deviceService.isMobile ? friendsDivStyle : ''" -->
  <div class="main-menu-friends-div" *ngIf="panelManagerService.p2pChatPanels.length > 0 && !deviceService.isMobile">
    <p-scrollPanel [style]="{width: '100%', height: deviceService.isMobile ? '100%' : friendsDivStyle.height }"
      class="friendsIconScrollPanel">
      <div *ngFor="let activeChatPanel of panelManagerService.p2pChatPanels">
        <div class="mainWebLeftButtonChatMessageCount"
          *ngIf="indexedDBService.messageCounts[activeChatPanel?.user?.KullaniciId]>0">
          {{ indexedDBService.messageCounts[activeChatPanel?.user?.KullaniciId] }}
        </div>
        <div class="p2pIcon" pTooltip="{{ activeChatPanel?.user.KullaniciAdi }}" tooltipPosition="right">
          <app-user-photo [userPhotoId]="activeChatPanel?.user.ProfilResmiId" [width]="deviceService.isMobile?30:40"
            [height]="deviceService.isMobile?30:40" [showStateIcon]="false"
            (click)="panelManagerService.showP2PChatPanel(activeChatPanel?.user.KullaniciId,true,true)" class="friendsIcon">
          </app-user-photo>
          <div class="menu-title" (click)="panelManagerService.showP2PChatPanel(activeChatPanel?.user.KullaniciId,true,true)">
            {{activeChatPanel?.user.KullaniciAdi}}</div>
        </div>
      </div>
    </p-scrollPanel>
  </div>
</div>