<p-dialog [(visible)]="reportService.reportPopupDisplay" [baseZIndex]="10000" [modal]="true" [closable]="true"
    header="{{  (panelManagerService.isLiveSupport ? 'Canlı Destek' : ((header | translate) + ' (' + reportService.header + ')'))}}">
    <div class="report">
        <div class="message" *ngIf="reportService.insertComplainRequest.Html !== ''"
            [innerHTML]="reportService.insertComplainRequest.Html | safeHtml"></div>
        <label class="description" for="description">{{ ('description' | translate) + ' (' + ( 'compulsory' | translate
            )
            + ')'}}</label>
        <textarea id="description" class="dialog-input" rows="5" cols="30" (keyup)="keyup()" maxlength="150" [(ngModel)]="description"
            pInputTextarea></textarea>
        <small class="countdown">{{ countDown }}</small>
    </div>

    <p-footer>
        <button pButton type="button"
            label="{{ (panelManagerService.isLiveSupport ? 'Kaydet' : ('report' | translate)) }}"
            [disabled]="description.trim() === ''" class="p-button-raised p-button-success"
            (click)="saveReport()"></button>
    </p-footer>
</p-dialog>