import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { Injectable, Injector } from '@angular/core';
import {
  AudioPreset,
  AudioPresets,
  DisconnectReason,
  LocalParticipant,
  LocalTrack,
  LocalTrackPublication,
  LogLevel,
  MediaDeviceFailure,
  Participant,
  ParticipantEvent,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  RoomConnectOptions,
  RoomEvent,
  RoomOptions,
  Track,
  TrackPublication,
  TranscriptionSegment,
  VideoCodec,
  VideoPresets,
  setLogLevel,
} from 'livekit-client';

import { State } from '../models/state';
import { ConnectionOptions } from '../models/connectionOption';
import { RoomService } from '../../services/room.service';
import { PanelManagerService } from '../../services/panel-manager.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../models/constants';
import { MainPanels, MessageBubbleType } from '../../models/enums';
import { RoomUser } from '../../models/room-user';
import { UtilService } from '../../services/util.service';
import { NatsService } from '../../nats/services/nats.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { RoomMicToken } from '../../models/room';
@Injectable({
  providedIn: 'root'
})
export class LivekitService {
  room: Room;

  state = State

  devices: MediaDeviceInfo[]
  //connect options

  preferredCodec: VideoCodec;

  //PARTICIPANTS
  participants: Participant[] = []

  //element-mapping
  elementMapping: { [k: string]: MediaDeviceKind } = {
    'video-input': 'videoinput',
    'audio-input': 'audioinput',
    'audio-output': 'audiooutput',
  };

  isOnMic: boolean = false;
  isOnScreenSharing: boolean = false;
  isCamOpen: boolean = false;

  isOnSharingTabMusic: boolean = false;
  allSenderAudioTrackAreMuted: boolean = false;
  isShareMusic: boolean = false;
  closeScreenSharefromComponent: boolean = false;
  //DEVICE ELEMENTS
  videoDevices: MediaDeviceInfo[] = [];
  audioInputDevices: MediaDeviceInfo[] = [];
  selectedVideoDeviceId: string | undefined;
  selectedAudioInputDeviceId: string | undefined;
  isMusicSharedWithoutMic: boolean = false;
  isMicEnabled = true

  //TOKEN_ROOM_MIC
  roomMicToken: RoomMicToken

  //reconnect options 
  maxRetryDelay = 3000;

  roomAIAssistant: RoomUser
  isRoomHasAIAgent: boolean = false

  aiUserName: string = "Veribot"
  lastReceivedMessage: string

  livekitUrl = `${environment.livekitUrl}`

  constructor(public panelManagerService: PanelManagerService,
    public http: HttpClient,
    private injector: Injector,
    public utilService: UtilService,
    public natsService: NatsService,
    public indexedDBService: IndexedDBService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService) {
  }

  async initConnection() {
    const url = this.livekitUrl;
    const simulcast = ConnectionOptions.simulcast;
    const dynacast = ConnectionOptions.dynacast;
    const forceTURN = ConnectionOptions.forceTurn;
    const adaptiveStream = ConnectionOptions.adaptiveStream;
    const shouldPublish = ConnectionOptions.publishOption;
    const preferredCodec = this.preferredCodec;
    const autoSubscribe = ConnectionOptions.autoSubscribe;
    const e2eeEnabled = ConnectionOptions.e2eeEnabled;

    setLogLevel(LogLevel.debug);
    let roomService = this.injector.get(RoomService)
    let maxRetryCount = 999999
    const roomOpts: RoomOptions = {
      reconnectPolicy: {
        nextRetryDelayInMs: (context) => {
          if (context.retryCount >= maxRetryCount) {
            return null;
          }
          roomService.connectionProblemDetected();
          return this.maxRetryDelay;
        }
      },
      adaptiveStream,
      dynacast,
      publishDefaults: {
        simulcast,
        videoSimulcastLayers: [VideoPresets.h90, VideoPresets.h216],
        videoCodec: preferredCodec || 'vp8',
        dtx: true,
        red: true,
        forceStereo: false,
      },
      videoCaptureDefaults: {
        resolution: VideoPresets.h720.resolution,
      },
      e2ee: e2eeEnabled
        ? {
          keyProvider: this.state.e2eeKeyProvider,
          worker: new Worker(
            new URL('livekit-client/e2ee-worker', import.meta.url)
          ),
        }
        : undefined,
    };
    const audioQualityToUse = roomService.audioQualityFromRoomInfo !== undefined
      && roomService.audioQualityFromRoomInfo !== 0 ?
      roomService.audioQualityFromRoomInfo :
      roomService.environmentVariable.AudioQuality;

    if (audioQualityToUse) {
      const foundPreset =
        this.findPresetByQuality(audioQualityToUse, AudioPresets)
      if (foundPreset && roomOpts.publishDefaults) {
        roomOpts.publishDefaults.audioPreset = foundPreset;
      }
    }
    if (
      roomOpts.publishDefaults?.videoCodec === 'av1' ||
      roomOpts.publishDefaults?.videoCodec === 'vp9'
    ) {
      roomOpts.publishDefaults.backupCodec = true;
    }

    const connectOpts: RoomConnectOptions = {
      autoSubscribe: autoSubscribe,
    };
    if (forceTURN) {
      connectOpts.rtcConfig = {
        iceTransportPolicy: 'relay',
      };
    }
    this.participants = [];
    this.getMicToken().then(res => {
      this.roomMicToken = res
    }).then(() => {
      this.connectToRoom(url, this.roomMicToken.token, roomOpts, connectOpts, shouldPublish);
    }).then(() => {
      this.handleDevicesChanged()
    });
  }

  async initConnectionForDynamicRoom() {
    const url = this.livekitUrl;
    const simulcast = ConnectionOptions.simulcast;
    const dynacast = ConnectionOptions.dynacast;
    const forceTURN = ConnectionOptions.forceTurn;
    const adaptiveStream = ConnectionOptions.adaptiveStream;
    const shouldPublish = ConnectionOptions.publishOption;
    const preferredCodec = this.preferredCodec;
    const autoSubscribe = ConnectionOptions.autoSubscribe;
    const e2eeEnabled = ConnectionOptions.e2eeEnabled;

    setLogLevel(LogLevel.debug);
    let roomService = this.injector.get(RoomService)
    let maxRetryCount = 999999
    const roomOpts: RoomOptions = {
      reconnectPolicy: {
        nextRetryDelayInMs: (context) => {
          if (context.retryCount >= maxRetryCount) {
            return null;
          }
          roomService.connectionProblemDetected();
          return this.maxRetryDelay;
        }
      },
      adaptiveStream,
      dynacast,
      publishDefaults: {
        simulcast,
        videoSimulcastLayers: [VideoPresets.h90, VideoPresets.h216],
        videoCodec: preferredCodec || 'vp8',
        dtx: true,
        red: true,
        forceStereo: false,
      },
      videoCaptureDefaults: {
        resolution: VideoPresets.h720.resolution,
      },
      e2ee: e2eeEnabled
        ? {
          keyProvider: this.state.e2eeKeyProvider,
          worker: new Worker(
            new URL('livekit-client/e2ee-worker', import.meta.url)
          ),
        }
        : undefined,
    };
    const audioQualityToUse = roomService.audioQualityFromRoomInfo !== undefined
      && roomService.audioQualityFromRoomInfo !== 0 ?
      roomService.audioQualityFromRoomInfo :
      roomService.environmentVariable.AudioQuality;

    if (audioQualityToUse) {
      const foundPreset =
        this.findPresetByQuality(audioQualityToUse, AudioPresets)
      if (foundPreset && roomOpts.publishDefaults) {
        roomOpts.publishDefaults.audioPreset = foundPreset;
      }
    }
    if (
      roomOpts.publishDefaults?.videoCodec === 'av1' ||
      roomOpts.publishDefaults?.videoCodec === 'vp9'
    ) {
      roomOpts.publishDefaults.backupCodec = true;
    }

    const connectOpts: RoomConnectOptions = {
      autoSubscribe: autoSubscribe,
    };
    if (forceTURN) {
      connectOpts.rtcConfig = {
        iceTransportPolicy: 'relay',
      };
    }
    this.participants = [];
    this.getDynamicRoomMicToken().then(res => {
      this.roomMicToken = res
    }).then(() => {
      this.connectToRoom(url, this.roomMicToken.token, roomOpts, connectOpts, shouldPublish);
    }).then(() => {
      this.handleDevicesChanged()
    });
  }

  async connectToRoom(
    url: string,
    token: string,
    roomOptions?: RoomOptions,
    connectOptions?: RoomConnectOptions,
    shouldPublish?: boolean
  ): Promise<Room | undefined> {
    this.room = new Room(roomOptions);

    await this.room.prepareConnection(url, token);
    this.room
      .on(RoomEvent.Connected, this.handleRoomConnect)
      .on(RoomEvent.Reconnected, this.handleRoomReconnect)
      .on(RoomEvent.Disconnected, this.handleRoomDisconnect)
      .on(RoomEvent.ParticipantConnected, this.participantConnected)
      .on(RoomEvent.ParticipantDisconnected, this.participantDisconnected)
      .on(RoomEvent.TrackPublished, this.handleTrackPublished)
      .on(RoomEvent.TrackUnpublished, this.handleTrackUnPublished)
      .on(RoomEvent.LocalTrackPublished, this.handleLocalTrackPublished)
      .on(RoomEvent.LocalTrackUnpublished, this.handleLocalTrackUnPublished)
      .on(RoomEvent.MediaDevicesChanged, this.handleDevicesChanged)
      .on(RoomEvent.TrackSubscribed, this.handleTrackSubscribed)
      .on(RoomEvent.TrackUnsubscribed, this.handleTrackUnSubscribed)
      .on(RoomEvent.SignalConnected, this.handleSignalConnected)
      .on(RoomEvent.MediaDevicesError, this.handleMediaDevicesError)
      .on(RoomEvent.TranscriptionReceived, this.handleDataReceived)
      .on(RoomEvent.AudioPlaybackStatusChanged, this.handleAudioPlaybackStatusChanged)

    try {
      await this.room.connect(url, token, connectOptions);
    } catch (error: any) {
      let message: any = error.message || error;
      if (error.message) {
        message = error.message;
      }
      return;
    }
    this.room.remoteParticipants.forEach((participant) => {
      this.participantConnected(participant);
    });

    this.participantConnected(this.room.localParticipant);

    return this.room;
  }

  //TOGGLE AND PUBLISH AREA START
  async toggleAudio(isOpen: boolean) {
    const maxRetries = 3;
    const retryDelay = 2500;
  
    const attemptToggle = async (attempt: number): Promise<void> => {
      try {
        if (!this.room) return;
        this.selectedAudioInputDeviceId = this.audioInputDevices[0].deviceId;
        await this.room.localParticipant.setMicrophoneEnabled(isOpen);
        this.isOnMic = isOpen;
        this.allSenderAudioTrackAreMuted = !isOpen;
        this.isMicEnabled = isOpen;
        this.renderAudioElement(this.room.localParticipant);
      } catch (error) {
        if (attempt < maxRetries) {
          await new Promise(resolve => setTimeout(resolve, retryDelay));
          return attemptToggle(attempt + 1);
        } else {
        }
      }
    };
  
    await attemptToggle(0);
  }
  
  async toggleVideo(open: boolean): Promise<boolean> {
    try {
      if (!this.room) return;
      await this.setDefaultVideoInputDevice();
      await this.room.localParticipant.setCameraEnabled(open);
      this.isCamOpen = open;
      this.renderVideoElement(this.room.localParticipant);
      return true;
    } catch (error) {
      console.error('toggleVideo an error occurred:', error);
      return false;
    }
  }

  async toggleScreenShare(open: boolean): Promise<boolean> {
    try {
      if (!this.room) return false;
      await this.room.localParticipant.setScreenShareEnabled(open, { audio: true });
      return true;
    } catch (error) {
      console.error('toggleVideo an error occurred:', error);
      return false;
    }
  }

  async toggleShareScreenAudio(isOpen: boolean) {
    if (isOpen) {
      this.publishShareScreenAudio()
    }
    else {
      this.unpublishShareAudio()
    }
  }

  async publishShareScreenAudio() {
    await this.room.localParticipant.createScreenTracks({
      audio: true,
    }).then((tracks: LocalTrack[]) => {
      tracks.forEach((track) => {
        if (track.kind === 'audio' && track.source == Track.Source.ScreenShareAudio) {
          this.room.localParticipant.publishTrack(track);
          this.isShareMusic = true
          this.renderShareMusicTab(this.room.localParticipant)
        }
      });
    }).catch(() => {
      this.isShareMusic = false
    });
  }

  unpublishShareAudio() {
    const track = this.room.localParticipant.getTrackPublication(Track.Source.ScreenShareAudio)
    if (track?.track?.kind === 'audio' && track.track.source == Track.Source.ScreenShareAudio) {
      this.room.localParticipant.unpublishTrack(track.track)
      this.renderShareMusicTab(this.room.localParticipant)
    }
  }

  //TOGGLE AREA END 

  //EVENT HANDLING AREA START
  participantConnected = (participant: Participant) => {
    participant
      .on(ParticipantEvent.TrackMuted, (pub: TrackPublication) => {
        if (pub.kind == 'audio' && pub.source == Track.Source.Microphone) {
          this.renderAudioElement(participant);
        }
        if (pub.kind == 'video' && pub.source == Track.Source.Camera) {
          this.renderVideoElement(participant);
        }
        if (pub.source == Track.Source.ScreenShareAudio) {
          if (!this.isOnScreenSharing) {
            this.renderShareMusicTab(participant)
          }
        }
        if (pub.source == Track.Source.ScreenShare) {
          this.renderScreenShareVideoElement(participant)
        }
      })
      .on(ParticipantEvent.TrackUnmuted, (pub: TrackPublication) => {
        if (pub.kind == 'audio' && pub.source == Track.Source.Microphone) {
          this.renderAudioElement(participant);
        }
        if (pub.kind == 'video' && pub.source == Track.Source.Camera) {
          this.renderVideoElement(participant);
        }
        if (pub.source == Track.Source.ScreenShareAudio) {
          if (!this.isOnScreenSharing) {
            this.renderShareMusicTab(participant)
          }
        }
        if (pub.source == Track.Source.ScreenShare) {
          this.renderScreenShareVideoElement(participant)
        }
      })
      .on(ParticipantEvent.IsSpeakingChanged, () => {
        this.updateMicIndicator(participant);
      })
  }

  participantDisconnected = (participant: RemoteParticipant) => {
    this.renderAudioElement(participant);
    this.removeAudioElement(participant)
    this.renderVideoElement(participant, true);
    this.renderScreenShareVideoElement(participant, true)
  }

  handleRoomDisconnect = (reason?: DisconnectReason) => {
    if (!this.room) return;
    this.renderAudioElement(this.room.localParticipant);
    this.renderVideoElement(this.room.localParticipant);
    this.removeAudioElement(this.room.localParticipant);
    this.participants.forEach((p) => {
      this.removeAudioElement(p);
    });
    this.panelManagerService.roomVideoElementsClearSource.next(null);
  }


  handleRoomConnect = () => {
  }

  handleRoomReconnect = () => {
    if (!this.panelManagerService.roomOpened) {
      this.disconnectRoom();
    }
    else {
      let roomService = this.injector.get(RoomService)
      roomService.reconnectedSuccessfully()
    }
  }

  handleTrackPublished = (pub: RemoteTrackPublication, participant: RemoteParticipant) => {
    if (pub.kind == 'audio' && pub.source == Track.Source.Microphone) {
      this.renderAudioElement(participant);
    }
    if (pub.kind == 'video' && pub.source == Track.Source.Camera) {
      this.renderVideoElement(participant);
    }
    if (pub.source == Track.Source.ScreenShareAudio) {
      if (!this.isOnScreenSharing) {
        this.renderShareMusicTab(participant)
      }
    }
    if (pub.source == Track.Source.ScreenShare) {
      this.renderScreenShareVideoElement(participant)
    }
  }
  handleTrackUnPublished = (pub: RemoteTrackPublication, participant: RemoteParticipant) => {
    if (participant.identity.includes('agent-')) {
      this.isRoomHasAIAgent = false
      let roomService = this.injector.get(RoomService)
      roomService.micUserListLoadedSource.next(null)
    }
  }

  handleLocalTrackPublished = (pub: LocalTrackPublication) => {
    if (pub.kind == 'audio' && pub.source == Track.Source.Microphone) {
      this.renderAudioElement(this.room.localParticipant);
    }
    if (pub.kind == 'video' && pub.source == Track.Source.Camera) {
      this.renderVideoElement(this.room.localParticipant);
    }
    if (pub.source == Track.Source.ScreenShareAudio) {
      if (!this.isOnScreenSharing) {
        this.renderShareMusicTab(this.room.localParticipant)
        this.isOnSharingTabMusic = true
      }
    }
    if (pub.source == Track.Source.ScreenShare) {
      this.renderScreenShareVideoElement(this.room.localParticipant)
    }
  }

  handleLocalTrackUnPublished = (pub: LocalTrackPublication) => {
    if (pub.kind == 'audio' && pub.source == Track.Source.Microphone) {
      this.renderAudioElement(this.room.localParticipant);
      this.unpublishShareAudio()
    }
    if (pub.kind == 'video' && pub.source == Track.Source.Camera) {
      this.renderVideoElement(this.room.localParticipant);
    }
    if (pub.source == Track.Source.ScreenShareAudio) {
      if (!this.isOnScreenSharing) {
        this.renderShareMusicTab(this.room.localParticipant)
        this.isOnSharingTabMusic = false
      }
    }
    if (pub.source == Track.Source.ScreenShare) {
      if (!this.closeScreenSharefromComponent) {
        const roomService = this.injector.get(RoomService);
        roomService.stopScreenShareFromLivekit(parseInt(this.room.localParticipant.identity), roomService.currentRoom.Info?.ID, false, false);
      }
      this.renderScreenShareVideoElement(this.room.localParticipant)
    }
  }

  handleTrackSubscribed = (track: RemoteTrack, pub: RemoteTrackPublication, participant: RemoteParticipant) => {
    if (participant.identity.includes('agent-')) {
      this.isRoomHasAIAgent = true
      this.appendAIAssistantToList(participant)
    }
    if (pub.kind == 'audio' && pub.source == Track.Source.Microphone) {
      this.renderAudioElement(participant);
    }
    if (pub.kind == 'video' && pub.source == Track.Source.Camera) {
      this.renderVideoElement(participant);
    }
    if (pub.source == Track.Source.ScreenShareAudio) {
      if (!this.isOnScreenSharing) {
        this.renderShareMusicTab(participant)
      }
    }
    if (pub.source == Track.Source.ScreenShare) {
      this.renderScreenShareVideoElement(participant)
    }
  }

  handleTrackUnSubscribed = (track: RemoteTrack, pub: RemoteTrackPublication, participant: RemoteParticipant) => {
    if (track.kind == 'audio' && pub.source == Track.Source.Microphone) {
      this.renderAudioElement(participant);
    }
    if (track.kind == 'video') {
      this.renderVideoElement(participant);
    }
    if (pub.source == Track.Source.ScreenShareAudio) {
      if (!this.isOnScreenSharing) {
        this.renderShareMusicTab(participant)
      }
    }
    if (pub.source == Track.Source.ScreenShare) {
      this.renderScreenShareVideoElement(participant)
    }
  }


  handleDataReceived = (transcription: TranscriptionSegment[], participant?: Participant) => {
    let text = ""
    const roomService = this.injector.get(RoomService);
    transcription.forEach((segment) => {
      if (segment.final) {
        text = segment.text

        if (!text) {
          return;
        }

        if (participant?.identity.includes('agent-')) {
          let chatMessageForSend = this.prepareAIChatMessage(segment)
          this.natsService.publishRoomAIChatMessage(Number(this.roomMicToken.roomId), chatMessageForSend);
          roomService.aiMessageSetToBubbleSource.next(chatMessageForSend)
        }
        else {
            //The message sent by the user is triggered twice
            if (participant?.identity === this.indexedDBService.userFullInfo.KullaniciId.toString()) {
              if (this.lastReceivedMessage == text) {
                return
              }
              let chatMessageForSend = this.prepareLocalParticipantChatMessage(text)
              this.natsService.publishRoomAIChatMessage(Number(this.roomMicToken.roomId), chatMessageForSend);
              roomService.aiMessageSetToBubbleSource.next(chatMessageForSend)
              this.lastReceivedMessage = text
            }
            else {
              if (this.lastReceivedMessage == text) {
                return
              }
              this.getParticipantInfo(participant)
                  .then(roomUser => {
                      let chatMessageForSend = this.prepareRemoteParticipantChatMessage(text, roomUser);
                      this.natsService.publishRoomAIChatMessage(Number(this.roomMicToken.roomId), chatMessageForSend);
                      roomService.aiMessageSetToBubbleSource.next(chatMessageForSend);
                  })
                  .catch(error => {
                      console.error('Failed to get participant info:', error);
                  });
          }
          
        }
      }
    })
  }

  async handleSignalConnected() {
    if (ConnectionOptions.publishOption) {
      await this.room.localParticipant.enableCameraAndMicrophone();
    }
  }
  handleMediaDevicesError = (e: Error) => {
    const failure = MediaDeviceFailure.getFailure(e)
    console.log("MediaDeviceFailure - > ", failure);
  }
  
  handleAudioPlaybackStatusChanged = (playing: boolean) => {
    if (!playing) {
      this.loadStartAudio();
    }
  }
  async handleDeviceSelected(deviceId: string, kind: MediaDeviceKind) {
    if (!kind) {
      return;
    }
    this.state.defaultDevices.set(kind, deviceId);
    if (this.room) {
      if (kind === 'videoinput') {
        await this.room.switchActiveDevice(kind, deviceId);
        this.selectedVideoDeviceId = deviceId;
      }
      else if (kind === 'audioinput') {
        await this.room.switchActiveDevice(kind, deviceId);
        this.selectedAudioInputDeviceId = deviceId;
      }
    }
  }

  async handleDevicesChanged() {
    if (this.elementMapping) {
      await Promise.all(
        Object.keys(this.elementMapping).map(async (id) => {
          const kind = this.elementMapping[id];
          if (!kind) {
            return;
          }
          if (kind == 'videoinput') {
            this.videoDevices = await Room.getLocalDevices(kind);
          }
          if (kind == 'audioinput') {
            this.audioInputDevices = await Room.getLocalDevices(kind);
          }
        })
      );
    }
  }
  //EVENT HANDLING END AREA

  //RENDER ELEMENT AREA START
  renderAudioElement(participant: Participant) {
    try {
      this.addNewParticipants(participant);
      const { identity } = participant;
      const roomService = this.injector.get(RoomService);
      const container = <HTMLElement>document.getElementById("participants-area");
      if (!container) return;
      let div = <HTMLElement>document.getElementById("participant-" + identity);
      if (!div) {
        div = document.createElement('div');
        div.id = `participant-${identity}`;
        div.className = 'participant';
        div.innerHTML = `<audio id="audio-${identity}"></audio>`;
        div.innerHTML += `<audio id="screen-music-${identity}"></audio>`;
        container.appendChild(div);
      }
      let audioELm = <HTMLAudioElement>document.getElementById("audio-" + identity);
      const micPub = participant.getTrackPublication(Track.Source.Microphone);
      const micEnabled = micPub && micPub.isSubscribed && !micPub.isMuted;
      if (micEnabled) {
        if (!(participant instanceof LocalParticipant)) {
          micPub?.audioTrack?.attach(audioELm);
        }
        if (roomService.allParticipantAreMuted) {
          let remoteParticipant = participant as RemoteParticipant
          this.muteParticipant(remoteParticipant)
        }
      } else {
        if (!(participant instanceof LocalParticipant)) {
          micPub?.audioTrack?.detach(audioELm);
        }
      }
    } catch (error) {
      console.error('renderAudioElement an error occurred:', error);
    }
  }


  renderVideoElement(participant: Participant, remove: boolean = false) {
    try {
      this.addNewParticipants(participant);
      const roomService = this.injector.get(RoomService);
      const { identity } = participant;
      let videoContainerId = "room_video_" + this.roomMicToken.roomId + "_" + identity;
      let videoElm = <HTMLVideoElement>document.getElementById(videoContainerId);
      const cameraPub = participant.getTrackPublication(Track.Source.Camera);

      if (remove) {
        if (videoElm) {
          let appCameraId = Constants.mainSwiperTag + '' + MainPanels.RoomVideo + '_' + identity
          let appCameraComponent = <HTMLElement>document.getElementById(appCameraId);
          if (appCameraComponent) {
            appCameraComponent.remove();
          }
        }
        return;
      }
      const cameraEnabled = cameraPub && cameraPub.isSubscribed && !cameraPub.isMuted;
      if (cameraEnabled && videoElm) {
        if (participant instanceof LocalParticipant) {
          videoElm.style.transform = 'scale(-1, 1)';
        }
        cameraPub?.videoTrack?.attach(videoElm);
        roomService.callGetMicUserList();

      } else {
        if (cameraPub?.videoTrack) {
          cameraPub.videoTrack?.detach(videoElm);
          roomService.callGetMicUserList();
        }
      }
    } catch (error) {
      console.error('renderVideoElement an error occurred:', error);
    }
  }

  renderScreenShareVideoElement(participant: Participant, remove: boolean = false) {
    try {
      this.addNewParticipants(participant);
      const roomService = this.injector.get(RoomService);
      const { identity } = participant;
      let videoContainerId = "screenShare_video_" + this.roomMicToken.roomId + "_" + identity;
      let videoElm = <HTMLVideoElement>document.getElementById(videoContainerId);
      const screenSharePub = participant.getTrackPublication(Track.Source.ScreenShare);

      if (remove) {
        if (videoElm) {
          let appScreenShareId = Constants.mainSwiperTag + '' + MainPanels.ScreenShare + '_' + identity
          let appScreenShareComponent = <HTMLElement>document.getElementById(appScreenShareId);
          if (appScreenShareComponent) {
            appScreenShareComponent.remove();
          }
        }
        return;
      }
      const screenShareEnabled = screenSharePub && screenSharePub.isSubscribed && !screenSharePub.isMuted;
      const screenShareAudioPub = participant.getTrackPublicationByName(Track.Source.ScreenShareAudio)
      const screenShareAudioEnabled = screenShareAudioPub && screenShareAudioPub.isSubscribed && !screenShareAudioPub.isMuted;
      if (screenShareEnabled && videoElm) {
        screenSharePub?.videoTrack?.attach(videoElm);
        if (screenShareAudioEnabled) {
          screenShareAudioPub.audioTrack?.attach(videoElm)
        }
      }
      setTimeout(() => {
        roomService.callGetMicUserList();
      }, 500)
    } catch (error) {
      console.error('renderScreenShareVideoElement an error occurred:', error);
    }
  }

  renderShareMusicTab(participant: Participant) {
    let {identity}=participant
    const roomService = this.injector.get(RoomService);
    let audioELm = <HTMLAudioElement>document.getElementById("screen-music-" + identity);
    const screenMusicPub = participant.getTrackPublication(Track.Source.ScreenShareAudio);
    const screenMusicEnabled = screenMusicPub && screenMusicPub.isSubscribed && !screenMusicPub.isMuted;
    if (screenMusicEnabled) {
      if (!(participant instanceof LocalParticipant)) {
        screenMusicPub?.audioTrack?.attach(audioELm);
      }
      if (roomService.allParticipantAreMuted) {
      let remoteParticipant = participant as RemoteParticipant
      this.muteParticipant(remoteParticipant)
       }
    } else {
      if (!(participant instanceof LocalParticipant)) {
        screenMusicPub?.audioTrack?.detach(audioELm);
      }
    }
  }
  //RENDER ELEMENT AREA END

  //ROOM OPERATIONS AREA START
  async disconnectRoom() {
    if (this.room) {
      await this.room.disconnect();
    }
  }
  updateMicIndicator(participant: Participant) {
    this.updateUserMicIndicator(participant);
    this.updateAIMouthAnimation(participant);
  }
  
  private updateUserMicIndicator(participant: Participant) {
    const userMicElementId = `user-on-mic-${participant.identity}`;
    const userMicElement = document.getElementById(userMicElementId);
  
    if (userMicElement) {
      userMicElement.style.opacity = participant.isSpeaking ? "1" : "0";
    }
  }
  
  private updateAIMouthAnimation(participant: Participant) {
    if (!participant.identity.includes('agent-')) {
      return;
    }
    const mouthElementId = 'mouth-panel'
    const mouthElement = document.getElementById(mouthElementId);

    if (mouthElement && this.panelManagerService.roomAIImagePopupDisplay) {
      mouthElement.classList.toggle('robot-speaking', participant.isSpeaking);
    }
  }
  

  async setDefaultVideoInputDevice() {
    let deviceId = this.videoDevices[0].deviceId
    this.selectedVideoDeviceId = this.videoDevices[0].deviceId
    setTimeout(() => {
      this.state.defaultDevices.set('videoinput', deviceId);
    }, 100)
    await this.room.switchActiveDevice('videoinput', deviceId)
  }

  addNewParticipants(participant: Participant) {
    const index = this.participants.findIndex(p => p.identity === participant.identity);
    if (index === -1) {
      this.participants.push(participant);
    }
  }

  removeAudioElement(participant: Participant) {
    let identity = participant.identity
    let element = <HTMLElement>document.getElementById("participant-" + identity);
    if (element) {
      element.parentElement?.removeChild(element);
    }
  }


  muteShareAudio() {
    const track = this.room.localParticipant.getTrackPublication(Track.Source.ScreenShareAudio)
    track?.mute()
  }

  unmuteShareAudio() {
    const track = this.room.localParticipant.getTrackPublication(Track.Source.ScreenShareAudio)
    track?.unmute()
  }

  muteParticipant(participant: RemoteParticipant) {
    const micPublication = participant.getTrackPublication(Track.Source.Microphone);
    const screenShareAudioPublication = participant.getTrackPublication(Track.Source.ScreenShareAudio);
    if (micPublication) {
      micPublication.setEnabled(false);
    }
    if (screenShareAudioPublication) {
      screenShareAudioPublication.setEnabled(false);
    }
  }
  findPresetByQuality(audioQuality: number, presets: Record<string, AudioPreset>): AudioPreset | undefined {
    for (const presetName in presets) {
      if (presets.hasOwnProperty(presetName)) {
        const preset = presets[presetName];
        if (preset.maxBitrate === audioQuality) {
          return preset;
        }
      }
    }
    return undefined;
  }
  loadStartAudio() {
    this.confirmationService.confirm({
      header: this.translateService.instant('start-audio'),
      message: this.translateService.instant('confirm-start-audio'),
      icon: null,
      accept: () => {
        this.room.startAudio()
      },
      reject: () => {
      }
    });
  }
  //ROOM OPERATIONS AREA END

  //MIC TOKEN AREA START
  getMicToken() {
    let apiurl = environment.apiUrl + "api/mic/token";
    return this.http.get<RoomMicToken>(apiurl).toPromise();
  }

  getDynamicRoomMicToken() {
    let apiurl = environment.apiUrl + "api/mic/token/dynroom";
    return this.http.get<RoomMicToken>(apiurl).toPromise();
  }
  //MIC TOKEN AREA END

  //ROOM AI OPERATIONS AREA START
  prepareAIChatMessage(segment: TranscriptionSegment): any {
    let roomService = this.injector.get(RoomService)
    let channelName = "room:" + roomService.currentRoom.Info?.ID
    return {
      MessageId: segment.id,
      IndexName: roomService.currentRoom.Info?.ID.toString(),
      Channelname: channelName,
      SenderRutbeDerece: 0,
      SenderId: 0,
      SenderName: this.aiUserName,
      SenderNameSecond: "",
      ReceiverId: 0,
      ReceiverName: this.aiUserName,
      RtfText: segment.text,
      Text: segment.text,
      Time: new Date(),
      UserPhotoId: this.roomAIAssistant.ProfilResmiId,
      ContactType: 0,
      Contactstate: 7,
      MessageSendingType: 0,
      ChatType: 0,
      Chatstate: 1,
      NickType: null,
      SenderStateId: 1,
      IsTopluMesaj: false,
      BulkMessageType: 0,
      MessageType: 0,
      IsDocs: false,
      IsImage: false,
      URL: "",
      RoomNameWhereTheMessageSent: roomService.currentRoom.Info?.NAME,
      RoomIdWhereTheMessageSent: roomService.currentRoom.Info?.ID,
      MessageBubbleType: MessageBubbleType.RoomMe
    }
  }

  prepareRemoteParticipantChatMessage(text: string,roomUser:RoomUser): any {
    let roomService = this.injector.get(RoomService)
    let channelName = "room:" + roomService.currentRoom.Info?.ID
    return {
      MessageId: this.utilService.guid(),
      IndexName: roomService.currentRoom.Info?.ID.toString(),
      Channelname: channelName,
      SenderRutbeDerece: roomUser.RutbeDerece,
      SenderId: roomUser.KullaniciId,
      SenderName: roomUser.KullaniciAdi,
      SenderNameSecond: "",
      ReceiverId: roomUser.KullaniciId,
      ReceiverName: roomUser.KullaniciAdi,
      RtfText: text,
      Text: text,
      Time: new Date(),
      UserPhotoId: roomUser.ProfilResmiId,
      ContactType: 0,
      Contactstate: 7,
      MessageSendingType: 0,
      ChatType: 0,
      Chatstate: 1,
      NickType: roomUser.Nicktype,
      SenderStateId: 1,
      IsTopluMesaj: false,
      BulkMessageType: 0,
      MessageType: 0,
      IsDocs: false,
      IsImage: false,
      URL: "",
      RoomNameWhereTheMessageSent: roomService.currentRoom.Info?.NAME,
      RoomIdWhereTheMessageSent: roomService.currentRoom.Info?.ID,
      MessageBubbleType: MessageBubbleType.RoomMe
    }
  }

  prepareLocalParticipantChatMessage(text: string): any {
    let roomService = this.injector.get(RoomService)
    let channelName = "room:" + roomService.currentRoom.Info?.ID
    return {
      MessageId: this.utilService.guid(),
      IndexName: roomService.currentRoom.Info?.ID.toString(),
      Channelname: channelName,
      SenderRutbeDerece: this.indexedDBService.userFullInfo.RutbeDerece,
      SenderId: this.indexedDBService.userFullInfo.KullaniciId,
      SenderName: this.indexedDBService.userFullInfo.KullaniciAdi,
      SenderNameSecond: "",
      ReceiverId: this.indexedDBService.userFullInfo.KullaniciId,
      ReceiverName: this.indexedDBService.userFullInfo.KullaniciAdi,
      RtfText: text,
      Text: text,
      Time: new Date(),
      UserPhotoId: this.indexedDBService.userFullInfo.ProfilResmiId,
      ContactType: 0,
      Contactstate: 7,
      MessageSendingType: 0,
      ChatType: 0,
      Chatstate: 1,
      NickType: this.indexedDBService.userFullInfo.Nicktype,
      SenderStateId: 1,
      IsTopluMesaj: false,
      BulkMessageType: 0,
      MessageType: 0,
      IsDocs: false,
      IsImage: false,
      URL: "",
      RoomNameWhereTheMessageSent: roomService.currentRoom.Info?.NAME,
      RoomIdWhereTheMessageSent: roomService.currentRoom.Info?.ID,
      MessageBubbleType: MessageBubbleType.RoomMe
    }
  }

  getParticipantInfo(participant?: Participant): Promise<RoomUser> {
    return new Promise((resolve, reject) => {
        const roomService = this.injector.get(RoomService);
        let userId = participant?.identity;
        if (userId?.includes('sip_')) {
          const id = participant?.identity;
          let user = roomService.micUserList.find(f => f.IkinciKullaniciAdi === id);
          if (user) {
            resolve(user)
          }
          else {
            reject();
          }
        }
        else{
          const id = participant?.identity;
          let user = roomService.micUserList.find(f => f.KullaniciId === Number(id));
          if (user) {
            resolve(user)
          }
          else {
            reject();
          }
        }
    });
}


  appendAIAssistantToList(participant: Participant) {
    let roomService = this.injector.get(RoomService)
    this.roomAIAssistant = {
      KullaniciId: Number(participant.identity),
      KullaniciAdi: this.aiUserName,
      IkinciKullaniciAdi: "",
      Nicktype: null,
      BanOda: false,
      BanSite: false,
      MuteOda: false,
      MuteSite: false,
      Karaliste: false,
      RutbeAdi: "AI Assistant",
      RutbeDerece: 0,
      RutbeId: 0,
      ProfilResmiId: "10000000-0000-0000-0000-000000000012",
      FlashnickId: "",
      StateId: 0,
      BanOdaList: null,
      MuteOdaList: null,
      IsCamOpen: false,
      IsScreenShareOpen: false,
      OnMainMic: false,
      EnterRoomIndex: 0,
      HandUp: false,
      OnSecondMic: false,
      RoomCamSession: null,
    }
    if (roomService.micUserList.length < 0) {
      roomService.micUserAddedSource.next(this.roomAIAssistant)
    }
    else {
      roomService.micUserListLoadedSource.next(null)
    }
  }
  //ROOM AI OPERATIONS AREA END

}