import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { RoomUser } from 'src/app/core/models/room-user';
import { DeviceService } from 'src/app/core/services/device.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { RoomUserListService } from 'src/app/core/services/room-user-list-service';
import { RoomService } from 'src/app/core/services/room.service';
import { UtilService } from 'src/app/core/services/util.service';
import { RoomUserListUserComponent } from '../../room/user-list/user/user.component';

@Component({
  selector: 'app-view-room',
  templateUrl: './view-room.component.html',
  styleUrls: ['./view-room.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class ViewRoomComponent implements OnInit {

  faTimes = faTimes;
  viewRoomUserListDoneSubscription: Subscription;
  isCurrentRoom: boolean = false;
  @ViewChild('dynamicComponent', { read: ViewContainerRef }) roomUserHost;
  @ViewChild('scrollPanel') scrollPanel: any;
  panelHeight: string = '';
  constructor(
    public roomService: RoomService,
    public panelManagerService: PanelManagerService,
    private utilService: UtilService,
    private componentFactoryResolver: ComponentFactoryResolver,
    public deviceService: DeviceService,
    private roomUserListService: RoomUserListService
  ) {
    this.viewRoomUserListDoneSubscription = this.roomService.viewRoomUserListDone.subscribe((isCurrentRoom: boolean) => {
      this.isCurrentRoom = isCurrentRoom;
      this.claerUserViewList();
      setTimeout(() => {
        this.changeAllRoomUserViewsIndex();
      }, 150);
    })
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.viewRoomUserListDoneSubscription)
      this.viewRoomUserListDoneSubscription.unsubscribe(); 
    this.claerUserViewList();

    for (var i = 0; i < this.roomService.roomUserListForViewTheRoom.length; i++) {
      // if(!this.roomService.roomUserList.find(f => f.KullaniciId === this.roomService.roomUserListForViewTheRoom[i].KullaniciId))
      if(!this.roomService.getRoomUserFromActiveListByUserId(this.roomService.roomUserListForViewTheRoom[i].KullaniciId))
        delete this.roomUserListService.userViewInRoom[this.roomService.roomUserListForViewTheRoom[i].KullaniciId];
    }

    this.roomService.roomUserListForViewTheRoom = [];
  }

  closeViewRoomPanel() {
    this.panelManagerService.viewTheRoomPopupDisplay = false;
  }

  claerUserViewList() {
    if(this.roomUserHost)
      this.roomUserHost.clear();    
      
  }

  async changeAllRoomUserViewsIndex() {
    for (let i = 0; i < this.roomService.roomUserListForViewTheRoom.length; i++) {
      this.appendUserToList(this.roomService.roomUserListForViewTheRoom[i], i);
    }
      this.updateScrollPanelHeight();
  }
  
  updateScrollPanelHeight() {
    if (this.scrollPanel) {
      const contentElement = this.scrollPanel.el.nativeElement.querySelector('.p-scrollpanel-content');
      if (contentElement) {
        const contentHeight = contentElement.scrollHeight
        this.panelHeight = contentHeight + 'px'
        this.scrollPanel.refresh(); 
      }
    }
  }
  

  appendUserToList(roomUser: RoomUser, index?: number) {
    try {
      const factory = this.componentFactoryResolver.resolveComponentFactory(RoomUserListUserComponent);
      const componentRef = this.roomUserHost.createComponent(factory, index);
      (<RoomUserListUserComponent>componentRef.instance).set(roomUser, {
        'background': this.utilService.hexToRgb(this.roomService.currentRoom.Info.RENK_USER_1) + 
          'linear-gradient(to right, ' + 
          this.utilService.hexToRgb(this.roomService.currentRoom.Info.RENK_USER_1) + ',' + 
          this.utilService.hexToRgb(this.roomService.currentRoom.Info.RENK_USER_2) + ')'
      }, true);
    } catch (error) {
      console.log(error);
    }
  }
}
