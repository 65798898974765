import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { DeviceService } from '../../../services/device.service';
import { UserService } from '../../../services/user.service';
import { Friend, UserFullInfo } from 'src/app/core/models/user';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { MainPanels, ContactType } from 'src/app/core/models/enums';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { RoomService } from 'src/app/core/services/room.service';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/core/models/constants';
import { UtilService } from 'src/app/core/services/util.service';
import { StateService } from 'src/app/core/services/state.service';
import { StateImagesByName, StateIdList } from 'src/app/core/models/state';
import { ChatService } from 'src/app/core/services/chat.service';
import { ChatMessage, ConferenceRequestResponse } from 'src/app/core/models/chat-message';
import { ActiveChatPanel } from 'src/app/core/models/chat-panel-props';
import { RoomUserListService } from 'src/app/core/services/room-user-list-service';
import { FriendListService } from 'src/app/core/services/friendlist.service';
import { faUsers, faComments, faUserTie, faCommentDots, faFlag, faHeadset, faBoxTissue } from '@fortawesome/free-solid-svg-icons';
import { RoomUser } from 'src/app/core/models/room-user';
import { AdminService } from 'src/app/admin/services/admin.service';
import { RoomListType } from 'src/app/core/models/room';
@Component({
  selector: 'main-menu-bar-panel',
  templateUrl: './main-menu-bar-panel.component.html',
  styleUrls: ['./main-menu-bar-panel.component.scss',
    './main-menu-bar-panel-mobile.component.scss',
    './main-menu-bar-panel-web.component.scss']
})
export class MainMenuBarPanelComponent implements OnInit, OnDestroy {
  faUsers = faUsers;
  faComments = faComments;
  faUserTie = faUserTie;
  faCommentDots = faCommentDots;
  faFlag = faFlag;
  faHeadset = faHeadset;
  faBox = faBoxTissue

  clicked: boolean = true;
  public userFullInfo: UserFullInfo;

  secondNickEntryPanelDisplay: boolean;
  _contactType = ContactType;
  onlineCount: number = 0;
  constants = Constants;
  mainPanels = MainPanels;
  roomPageOpenedSubscription: Subscription;
  adminPageOpenedSubscription: Subscription;
  p2pChatPanelsRemovedSubscription: Subscription;
  friendsChangedSubscription: Subscription;
  newMessageNotificationTakenSubscription: Subscription;
  adminNotificationSubscription: Subscription;

  friendsDivStyle;

  @ViewChild("staticButtonsDiv", { static: true, read: ElementRef }) staticButtonsDiv: ElementRef<HTMLElement>;

  constructor(
    public deviceService: DeviceService,
    public userService: UserService,
    public panelManagerService: PanelManagerService,
    public indexedDBService: IndexedDBService,
    public roomService: RoomService,
    public utilService: UtilService,
    public stateService: StateService,
    public chatService: ChatService,
    private roomUserListService: RoomUserListService,
    private friendListService: FriendListService,
    private adminService: AdminService
  ) {

    this.roomPageOpenedSubscription = this.panelManagerService.roomPageOpened.subscribe(() => {
      setTimeout(() => { this.calculateFriendsDivSize(); });
    });

    this.adminPageOpenedSubscription = this.panelManagerService.adminPageOpened.subscribe(() => {
      setTimeout(() => { this.calculateFriendsDivSize(); });
    });

    this.adminNotificationSubscription = this.panelManagerService.notificationsOpened.subscribe(() => {
      setTimeout(() => { this.calculateFriendsDivSize(); });
    });

    this.p2pChatPanelsRemovedSubscription = this.panelManagerService.p2pChatPanelsRemoved.subscribe((userId: number) => {
      this.removeP2PChatPanel(userId);
    });

    this.friendsChangedSubscription = this.indexedDBService.friendsChanged.subscribe((friendID: number) => {
      let userIndex: number = this.panelManagerService.p2pChatPanels.findIndex(f => f.user.KullaniciId === friendID);
      if (userIndex !== -1) {
        if (this.indexedDBService.isMyFriend(friendID)) {
          this.panelManagerService.p2pChatPanels[userIndex].user = this.indexedDBService.Friends.AllFriends.find(f => f.KullaniciId === friendID);
        }
        else {
          // if (this.utilService.isNullOrUndefined(this.roomService.roomUserList.find(f => f.KullaniciId === friendID))) {
            if (this.utilService.isNullOrUndefined(this.roomService.getRoomUserFromActiveListByUserId(friendID))) {
            this.removeP2PChatPanel(friendID);
          }
        }
      }
    });

    this.newMessageNotificationTakenSubscription = this.chatService.newMessageNotificationTaken.subscribe((chatMessage: ChatMessage) => {
      this.showNewMessages(chatMessage);
    });

  }

  ngOnInit() {
    this.calculateFriendsDivSize();
    //  this.setUserStateIcon();
    this.calculateTotalRoomsOnlineCount();
  }

  loadStaticRoomList(){
    if (this.roomService.selectedRoomListType !== RoomListType.STATIC) {
      this.roomService.pageIndexForRoomList = 1
      this.roomService.roomListDataClearedSource.next(null);
      this.roomService.loadStaticRoomList();
    }
    this.panelManagerService.openMainPanel(MainPanels.RoomList) 
  }

  loadDynamicRoomList(){
    if (this.roomService.selectedRoomListType !== RoomListType.HISTORYDYNAMIC) {
      this.roomService.pageIndexForRoomList = 1
      this.roomService.callHistoryDynamicRoomList()
      this.roomService.roomListDataClearedSource.next(null);
    }
    this.panelManagerService.openMainPanel(MainPanels.RoomList) 
  }
  
  showNewMessages(chatMessage: ChatMessage) {
    let senderId: number = chatMessage.SenderId;

    if (this.panelManagerService.focusedPanel !== Constants.chatPanelTag + senderId.toString()) {
      if (this.indexedDBService.isMyFriend(senderId)) {
        if (!this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === senderId)) {
          let user: Friend = this.indexedDBService.getFriendById(senderId);
          this.panelManagerService.p2pChatPanels.push(<ActiveChatPanel>{
            display: false,
            user: user,
            conferenceRequestResponse: new ConferenceRequestResponse()
          });

          this.panelManagerService.openP2PChatPanel(senderId, user, null, false, true);
        }
      } else {
        if (!this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === senderId)) {
          this.roomService.getRoomUserByUserId(senderId).then((roomUser: RoomUser) => {
            if (roomUser) {
              let user: Friend = new Friend();
              user = this.roomUserListService.convertRoomUserToPtoPChatUser(roomUser)
              var userNameAndRank: any[] = this.roomUserListService.getUserNameForShow(user?.KullaniciId, "", user?.KullaniciAdi, user?.RutbeAdi, user?.RutbeDerece);
              user.KullaniciAdi = userNameAndRank[0];
              user.RutbeDerece = userNameAndRank[1];
              
              this.panelManagerService.p2pChatPanels.push(<ActiveChatPanel>{
                display: false,
                user: user,
                conferenceRequestResponse: new ConferenceRequestResponse()
              });

              if (!this.panelManagerService.p2pIncomingMessageFromAntoherUserPanels.find(f => f.KullaniciId === senderId)) {
                this.panelManagerService.p2pIncomingMessageFromAntoherUserPanels.push(user);
              }
              this.panelManagerService.openP2PChatPanel(senderId, user, null, false, false);
              if (this.indexedDBService.messageCounts[chatMessage.SenderId]) {
                if (!this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === senderId).display) {
                  this.indexedDBService.messageCounts[chatMessage.SenderId] = this.indexedDBService.messageCounts[chatMessage.SenderId] + 1;

                  if (this.panelManagerService.showedIncommingAnotherUserMessagePanel == false) {
                    this.panelManagerService.p2pIncomingMessageFromAntoherUserNotification = true;
                  }
                }
              }
              else {
                if (!this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === senderId).display) {
                  this.indexedDBService.messageCounts[chatMessage.SenderId] = 1;
                  if (this.panelManagerService.showedIncommingAnotherUserMessagePanel == false) {
                    this.panelManagerService.p2pIncomingMessageFromAntoherUserNotification = true;
                  }
                }
              }
            }
          }).catch(error => {
            console.error('Error retrieving RoomUser:', error);
          });
        }
        else{
          if (this.panelManagerService.showedIncommingAnotherUserMessagePanel == false) {
            this.panelManagerService.p2pIncomingMessageFromAntoherUserNotification = true;
          }
        }
        
      }

      setTimeout(() => {
        if (this.panelManagerService.p2pChatPanels[0].user.KullaniciId !== senderId)
          this.moveUserIconToTop(senderId);
      }, 200);

      // this.chatService.newMessageNotificationTakenDoneSource.next(chatMessage.LocalDBId);
    }
  }

  moveUserIconToTop(userId: number) {
    let currentUser = this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === userId);
    let index = this.panelManagerService.p2pChatPanels.indexOf(currentUser)

    /* let activechatpanel = this.panelManagerService.p2pChatPanels.find(f => f.chatPanelProps.ContactType === this._contactType.Audio ||
       f.chatPanelProps.ContactType === this._contactType.VideoAndAudio);
     if (this.utilService.isNullOrEmtpyObject(activechatpanel) === false) {
       this.panelManagerService.p2pChatPanels.splice(this.panelManagerService.p2pChatPanels.indexOf(activechatpanel), 1)
       this.panelManagerService.p2pChatPanels.splice(0, 0, activechatpanel);
 
 
       this.panelManagerService.p2pChatPanels.splice(index, 2)
       this.panelManagerService.p2pChatPanels.splice(1, 1, currentUser);
     }
     else {
       this.panelManagerService.p2pChatPanels.splice(index, 1)
       this.panelManagerService.p2pChatPanels.splice(0, 0, currentUser);
     }*/

    this.panelManagerService.p2pChatPanels.splice(index, 1)
    this.panelManagerService.p2pChatPanels.splice(0, 0, currentUser);
  }

  removeP2PChatPanel(userId: number) {
    this.panelManagerService.focusedPanel === Constants.chatPanelTag + userId.toString() ?
      this.panelManagerService.focusedPanel = "" : null;
    this.panelManagerService.p2pChatPanels.filter(f => f.user.KullaniciId !== userId);

  }

  setUserStateIcon() {
    if (this.stateService.currentStateId !== 1) {
      this.getStateIdFromCurrentState();
    } else {
      this.getStateIdFromIndexedDb();
    }
  }

  getStateIdFromCurrentState() {
    this.indexedDBService.userFullInfo.StateId = this.stateService.currentStateId;
  }

  getStateIdFromIndexedDb() {
    this.indexedDBService.getPrivateSetting(this.indexedDBService.settingsCurrentStatePrivateKey)
      .then((response) => {
        this.indexedDBService.userFullInfo.StateId = parseInt(StateImagesByName[response.value]);
      })
      .catch(error => {
        console.log(error);
      });
  }

  ngOnDestroy() {

    if (this.roomPageOpenedSubscription)
      this.roomPageOpenedSubscription.unsubscribe();

    if (this.p2pChatPanelsRemovedSubscription)
      this.p2pChatPanelsRemovedSubscription.unsubscribe();

    if (this.friendsChangedSubscription)
      this.friendsChangedSubscription.unsubscribe();

    if (this.newMessageNotificationTakenSubscription)
      this.newMessageNotificationTakenSubscription.unsubscribe();

    if (this.adminPageOpenedSubscription) 
      this.adminPageOpenedSubscription.unsubscribe();
        
     if(this.adminNotificationSubscription)
      this.adminNotificationSubscription.unsubscribe(); 
  }

  calculateTotalRoomsOnlineCount() {
    // this.onlineCount = this.indexedDBService.Rooms.reduce((a, b) => a + b.O, 0);
  }

  calculateFriendsDivSize() {
    if (this.deviceService.isMobile) {
      var staticButtonsDivWidth = this.staticButtonsDiv.nativeElement.offsetWidth;
      this.friendsDivStyle = {
        'width': 'calc(100% - ' + (staticButtonsDivWidth + 50) + 'px)',
        'height': '50px', //100%
        'left': (staticButtonsDivWidth + 45) + 'px',
        'top': '0px'
      }
    } else {
      var staticButtonsDivHeight = this.staticButtonsDiv.nativeElement.offsetHeight;
      this.friendsDivStyle = {
        //'width': '100%',
        'height': 'calc(100vh - ' + (staticButtonsDivHeight + 10) + 'px)'
        /*'left': '0px',
        'top': (staticButtonsDivHeight + 5) + 'px',*/
      }
    }
  }

  async openAdminPage(from: string) {
    try {
      this.roomService.adminPanelTabs = await this.roomService.getOpenableAdminPanelTabList()
      if (!this.utilService.isNullOrEmtpyObject(this.roomService.adminPanelTabs)) {
        this.adminService.selectedTabFromPublic = from;
        this.panelManagerService.openAdminPage(from);
      }
    } catch (error) {
      console.error("Error opening admin page: ", error);
    }
  }
  
}