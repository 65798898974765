<p-dialog [(visible)]="panelManagerService.imageCropperPopupDisplay" [modal]="true" [baseZIndex]="10000"
  [responsive]="true" (onHide)="onHide($event)" class="imageCropper" [showHeader]="false" [dismissableMask]="true">

  <p-scrollPanel [style]="{'width': '100%', 'height': '100%' }">

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8 mb-1">

          <div [style.display]="deviceService.isMobile === true ? 'block' : 'none'">
            <button pButton class="p-button-secondary" type="button" (click)="visibleSidebar = true"
              icon="pi pi-bars" [ngStyle]="{'left': '0px', 'position': 'relative'}"></button>
            <button pButton type="button" (click)="saveImage()" class="p-button-success mb-1"
              label="{{ 'save' | translate }}" [ngStyle]="{'right': '0px', 'position': 'absolute'}"></button>
          </div>

          <p-sidebar 
          [style]="{ 'width': '135px', 'position': 'absolute', 'overflow': 'auto' }" 
          [(visible)]="visibleSidebar" 
          [blockScroll]="true" 
          [baseZIndex]="10000" 
          [modal]="false"
          [showCloseIcon]="false">
          
          <button pButton 
                  type="button" 
                  class="p-button-secondary w-100 mb-1 mt-1" 
                  (click)="takePicture()" 
                  label="{{ 'takephoto' | translate }}">
          </button>
          
          <input type="file" 
                 id="imageFileUpload" 
                 (change)="fileChangeEvent($event)" 
                 accept="image/*" 
                 name="profilePhoto" 
                 style="display: none;">
                 
          <button pButton 
                  type="button" 
                  class="p-button-secondary w-100 mb-1" 
                  label="{{ 'selectimagefromfile' | translate }}" 
                  (click)="openFileUpload($event)">
          </button>
        
          <button pButton 
                  type="button" 
                  class="w-100 mb-1" 
                  (click)="rotateLeft()" 
                  label="{{ 'RotateLeft' | translate }}">
          </button>
          
          <button pButton 
                  type="button" 
                  class="w-100 mb-1" 
                  (click)="rotateRight()" 
                  label="{{ 'RotateRight' | translate }}">
          </button>
          
          <button pButton 
                  type="button" 
                  class="w-100 mb-1" 
                  (click)="flipHorizontal()" 
                  label="{{ 'FlipHorizontal' | translate }}">
          </button>
          
          <button pButton 
                  type="button" 
                  class="w-100 mb-1" 
                  (click)="flipVertical()" 
                  label="{{ 'FlipVertical' | translate }}">
          </button>
        
          <button pButton 
                  type="button" 
                  class="p-button-danger w-100 mb-1" 
                  (click)="resetImage()" 
                  label="{{ 'ResetImage' | translate }}">
          </button>
        
          <input [(ngModel)]="rotation" 
                 class="w-100 mb-1" 
                 pInputText 
                 placeholder="{{ 'Rotation' | translate }}" 
                 type="number" 
                 (keyup)="updateRotation()" />
        
          <button pButton 
                  icon="pi pi-minus" 
                  class="w-100 mb-1" 
                  (click)="zoomOut()" 
                  label="Zoom">
          </button>
          
          <button pButton 
                  icon="pi pi-plus" 
                  class="w-100 mb-1" 
                  (click)="zoomIn()" 
                  label="Zoom">
          </button>
          
        </p-sidebar>
        

          <div>
            <image-cropper *ngIf="isObject" [imageChangedEvent]="userService.imageChangedEvent"
              [maintainAspectRatio]="true" [aspectRatio]="1 / 1" [resizeToWidth]="400" [resizeToHeight]="400"
              [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
              [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
              (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
              (loadImageFailed)="loadImageFailed()">
            </image-cropper>
            <image-cropper *ngIf="!isObject" [imageBase64]="userService.imageChangedEvent" [maintainAspectRatio]="true"
              [aspectRatio]="1 / 1" [resizeToWidth]="400" [resizeToHeight]="400" [cropperMinWidth]="128"
              [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform"
              [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
              (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
              (loadImageFailed)="loadImageFailed()">
            </image-cropper>
          </div>

          <div [style.text-align]="'center'">
            <img [src]="croppedImage" [ngStyle]="{'width': '200px', 'height': '200px'}"
              [style.border]="croppedImage ? '1px solid black' : 'none'" />
          </div>
        </div>

        <div class="col-md-4 m-auto" [style.display]="deviceService.isMobile === false ? 'block' : 'none'">
          <button pButton type="button" class="p-button-secondary w-100 mb-1 mt-1" (click)="takePicture()"
            label="{{'takephoto'| translate }}"></button>
          <input type="file" id="imageFileUpload" (change)="fileChangeEvent($event)" accept="image/*"
            name="profilePhoto" style="display: none;">
          <button pButton type="button" class="p-button-secondary w-100 mb-1"
            label="{{ 'selectimagefromfile' | translate }}" (click)="openFileUpload($event)"></button>

          <button pButton type="button" class="w-100 mb-1" (click)="rotateLeft()"
            label="{{ 'RotateLeft' | translate }}"></button>
          <button pButton type="button" class="w-100 mb-1" (click)="rotateRight()"
            label="{{ 'RotateRight' | translate }}"></button>
          <button pButton type="button" class="w-100 mb-1" (click)="flipHorizontal()"
            label="{{ 'FlipHorizontal' | translate }}"></button>
          <button pButton type="button" class="w-100 mb-1" (click)="flipVertical()"
            label="{{ 'FlipVertical' | translate }}"></button>

          <button pButton type="button" class="p-button-danger w-100 mb-1" (click)="resetImage()"
            label="{{ 'ResetImage' | translate }}"></button>

          <input [(ngModel)]="rotation" class="w-100 mb-1" pInputText placeholder="{{ 'Rotation' | translate }}"
            type="number" (keyup)="updateRotation()" />
          <button pButton icon="pi pi-minus" class="w-100 mb-1" (click)="zoomOut()" label="Zoom"></button>
          <button pButton icon="pi pi-plus" class="w-100 mb-1" (click)="zoomIn()" label="Zoom"></button>
          <button pButton type="button" (click)="saveImage()" class="p-button-success w-100 mb-1"
            label="{{ 'save' | translate }}"></button>
        </div>
      </div>
    </div>
  </p-scrollPanel>
</p-dialog>