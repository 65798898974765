import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, HostListener, ViewEncapsulation, OnDestroy } from '@angular/core';
import { EditorComponent } from 'src/app/core/component/editor/editor.component';
import { MessengerService } from 'src/app/core/services/messenger.service';
import { UtilService } from 'src/app/core/services/util.service';
import { P2PService } from 'src/app/core/services/p2p.service';
import { ChatPanelProps } from 'src/app/core/models/chat-panel-props';
import { MessageBubbleType, EditorOn } from 'src/app/core/models/enums';
import { faSmile, faPaperPlane, faFolder, faFilm } from '@fortawesome/free-solid-svg-icons';
import { DeviceService } from 'src/app/core/services/device.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/core/services/chat.service';
import { FileMessageData, FileMessageUploadResponse } from 'src/app/core/models/chat-message';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { StateIdList } from 'src/app/core/models/state';
@Component({
  selector: 'app-p2p-chat-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class P2PChatEditorComponent implements OnInit,OnDestroy {
  faSmile = faSmile;
  faPaperPlane = faPaperPlane;
  faFolder = faFolder
  faFilm = faFilm;


  emojiPanelIsOpen: boolean = false;
  messageText: string;
  editorOn = EditorOn
  animationToggle: boolean = false;


  @ViewChild('editor', { static: true }) editor: EditorComponent;
  @ViewChild("emojiPanel", { static: false, read: ElementRef }) emojiPanel: ElementRef;
  @ViewChild("animation", { static: false, read: ElementRef }) animation: ElementRef; 
  @ViewChild("topeditor", { static: false, read: ElementRef }) topeditor: ElementRef; 
  @ViewChild('emojiImg', { static: false, read: ElementRef }) emojiImg: ElementRef;

  @Input() editorMessageForSet: Promise<string> = Promise.resolve("");
  @Input() chatPanelProps: ChatPanelProps;
  @Output() editorTextChanged = new EventEmitter<string>();

  p2pFileMessagesReceivedSubscription: Subscription;
  p2pAnimasyonMessagesSelectedSubscription: Subscription;

  constructor(
    private messengerService: MessengerService,
    private utilService: UtilService,
    private p2PService: P2PService,
    public deviceService: DeviceService,
    private messagePanelService: MessagePanelService,
    private chatService: ChatService,
    public panelManagerService:PanelManagerService,
    public indexedDBService:IndexedDBService
  ) {
    this.p2pFileMessagesReceivedSubscription = this.chatService.p2pFileMessageReceived.subscribe((fileObj:FileMessageData) => {
      if(this.panelManagerService.activeselectedFriendFileUploadPanel == this.chatPanelProps)
          this.sendFileMessage(fileObj)
    })
    
    this.p2pAnimasyonMessagesSelectedSubscription = this.chatService.p2pAnimationSend.subscribe((animationId:number)=>{
      if (this.chatService.selectedUserPanelForSendingAnimation == this.chatPanelProps.UserId) {
        this.sendAnimationMessage(animationId)
      }
    })
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    if (this.p2pAnimasyonMessagesSelectedSubscription){
      this.p2pAnimasyonMessagesSelectedSubscription.unsubscribe();
    }
  }

  onRightClick($event) {
    $event.preventDefault();
  }
  

  addEmoji($event) {

    this.setEditorOperations($event.emoji);
    this.editor.pasteEmojiToEditor(this.messengerService.createEmojiHtml($event.emoji));
  }

  toggleAnimationsPanel() {
    this.animationToggle = !this.animationToggle;
    this.chatService.selectedUserPanelForSendingAnimation = this.chatPanelProps.UserId;
  }

  sendAnimationMessage(animationId:number) {
    this.p2PService.sendMessageToFriend(this.chatPanelProps, animationId.toString(), MessageBubbleType.PtoPMe,true)
      .then(() => {
      })
      .catch(() => {
      })
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {

    if (!this.deviceService.isMobile) {
      if (this.emojiPanel.nativeElement.contains(event.target)) {
        this.emojiPanelIsOpen = true;
      }
      else if (this.emojiImg.nativeElement.contains(event.target)) {
        this.emojiPanelIsOpen = !this.emojiPanelIsOpen;
      }
      else {
        this.emojiPanelIsOpen = false;
      }
    }

    if (!this.animation.nativeElement.contains(event.target)) {
      this.animationToggle = false;
    }

    event.preventDefault();
    event.stopPropagation();
  }

  editorTextChange(editorText) {
    this.setEditorOperations(editorText);
  }

  editorEnterPress() {
    this.sendMessage();
    this.emojiPanelIsOpen = false;
  }

  setEditorOperations(text) {
    if (this.utilService.isNullOrEmtpyString(text)) {
    }
    else {
      this.messageText = text;
    }

    this.editorTextChanged.emit(text);
  }

  
  sendMessage() {
    const message = this.editor.getEditorTextForSend();
    const emojiRegex = /<img[^>]*class=["']emoji["'][^>]*>/g; 
  
    const hasEmojis = emojiRegex.test(message);
    const isTextValid = message.length > 0 && message.length < 250;
  
    if (hasEmojis || isTextValid) {
      this.editorMessageForSet = Promise.resolve("");
      this.p2PService.sendMessageToFriend(this.chatPanelProps, message, MessageBubbleType.PtoPMe)
        .then(() => {
        })
        .catch(() => {
        });
    } else if (message.length >= 250) {
      this.messagePanelService.ShowPopupMessageWithLocalization("message-lenght-250-character", 2000);
    }
  }
  

  docsPopupDisplay(){
    if (this.indexedDBService.userFullInfo.RutbeDerece == -1) {
      this.panelManagerService.guestWarningPopupDisplay = true;
      return
    }
    this.panelManagerService.activeselectedFriendFileUploadPanel = this.chatPanelProps;
    this.panelManagerService.sendFolderToFriendPopupDisplay = true;
  }

  sendFileMessage(fileObj:FileMessageData) {
      let message = this.editor.getEditorTextForSend();
      this.editorMessageForSet = Promise.resolve("");
      this.p2PService.sendFileMessageToFriend(this.chatPanelProps, message, MessageBubbleType.PtoPMe,fileObj)
        .then(() => {
        })
        .catch(() => {
        })
    
  }
}