<p-dialog [(visible)]="panelManagerService.roomPasswordPanelIsOpened" [closeOnEscape]="false" [closable]="false"
    [modal]="true" [baseZIndex]="10000" [responsive]="true" [focusOnShow]="false" [focusTrap]="true">
    <p-header>
        {{'RoomPass' | translate}}
    </p-header>

    <div style="text-align:center;">
        <input type="password" #roomPass autofocus class="textbox roomPassWordTextBox dialog-input" id="roomPassTextbox"
            [(ngModel)]="roomPassword" autocomplete="new-password" (keyup.enter)="passwordControl()"
            placeholder="{{'EnterRoomPass' | translate}}" name="roomPassTextbox" />
    </div>

    <p-footer>
        <button pButton (click)="passwordControl()" label="{{'OkButtonText' | translate}}"
            class="p-button-raised p-button-info popup"></button>
        <button pButton (click)="close()" label="{{'CancelButtonText' | translate}}"
            class="p-button-raised p-button-danger popup"></button>
    </p-footer>
</p-dialog>