<p-dialog [(visible)]="panelManagerService.generalMessageConfirmPopupDisplay" 
    (onShow)="onShow()" 
    [closeOnEscape]="false" 
    [focusOnShow]="false"
    header="{{ 'general-message-confirm' | translate }}" 
    [modal]="true" 
    [baseZIndex]="10000" 
    [responsive]="true" 
    [closable]="true">
    
    <div class="message-content">
        <p>
            <strong>{{ 'sender-name' | translate }}:</strong> {{ roomService.generalMessageToConfirm.SenderName }}
        </p>
        <p>
            <strong>{{ 'message-text' | translate }}: </strong>
            <span [innerHTML]="isGifOrEmoji ? gifAddress : roomService.generalMessageToConfirm.RtfText"></span>
        </p>
    </div>
    
    <div class="timer-text">{{ timeLeft }}</div>

    <p-footer>
        <button (click)="acceptOrRejectConfirm(true)" 
            pButton type="button" 
            label="{{ 'confirm' | translate }}" 
            class="p-button-raised p-button-success"></button>
        <!-- <button (click)="acceptOrRejectConfirm(false)" 
            pButton type="button" 
            label="{{ 'reject' | translate }}" 
            class="p-button-raised p-button-danger"></button> -->
    </p-footer>
</p-dialog>
