<p-dialog 
    [(visible)]="panelManagerService.p2pMessageProcessPopupDisplay" 
    [modal]="true" 
    [baseZIndex]="99999"
    [responsive]="true" 
    [showHeader]="true" 
    (onHide)="closeRoomMessageProcessPanel()" 
    [dismissableMask]="true" 
    closable="true"
    styleClass="custom-dialog-style"
>
    <p-scrollPanel [style]="{width: '100%', height: '100%'}">
        <div class="user-card-container">
            <div class="user-card-items" (click)="copyMessageContent($event)">
                <fa-icon [icon]="faCopy" ></fa-icon>
                <span>{{ ('copyMessage' | translate)}}</span>
            </div>
        </div>
    </p-scrollPanel>
</p-dialog>
