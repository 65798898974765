import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange, AfterViewInit } from '@angular/core';
import { Constants } from '../../models/constants';
import { IndexedDBService } from '../../services/indexed-db.service';
import { Nicktype } from '../../models/nick-type';
import { Subscription } from 'rxjs';
import { ChatService } from '../../services/chat.service';
import { UtilService } from '../../services/util.service';
import { DeviceService } from '../../services/device.service';
import { RoomService } from '../../services/room.service';
import { MessageSendingType, EditorOn } from '../../models/enums';
import { PanelManagerService } from '../../services/panel-manager.service';
import { Plugins } from '@capacitor/core';

const { Keyboard } = Plugins;

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})

export class EditorComponent implements OnInit, OnChanges, AfterViewInit {

  editorMessage: string = "";
  @Input() editorMessageForSet: Promise<string> = Promise.resolve("");
  @Input() editorOn;
  editoron = EditorOn;
  selectedText: string;
  startIndex: number = 0;
  endIndex: number = 0;
  startNode;
  endNode;
  startOffset;
  endOffset;
  isReverse: boolean;
  currentNode;
  editorStyle;
  editorFontChangedSubscription: Subscription;
  messageSendingType = MessageSendingType;

  @ViewChild("editormsg", { static: false }) editorMsg: ElementRef;
  @ViewChild("ghostInput", { static: false }) ghostInput: ElementRef;

  @Output() editorTextChanged = new EventEmitter<string>();
  @Output() editorEnterPressed = new EventEmitter();

  constructor(
    private indexedDBService: IndexedDBService,
    private chatService: ChatService,
    private utilService: UtilService,
    private deviceService: DeviceService,
    public roomService: RoomService,
    public panelManagerService: PanelManagerService
  ) {
    this.getEditorTextFontFromIndexedDB();
  }

  ngOnInit() {
    this.editorFontChangedSubscription = this.chatService.editorFontChanged.subscribe((fontSettings: Nicktype) => {
      if (!this.utilService.isNullOrEmtpyObject(fontSettings))
        this.setEditorTextFont(fontSettings);
    })
  }

  ngOnDestroy(): void {
    if (this.editorFontChangedSubscription)
      this.editorFontChangedSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    //setTimeout(() => {
    this.setEditorText("");
    this.chatService.activeEditor = this.editorMsg.nativeElement;

    //TODO Klavye açılma kapanma durumu burdan kaynaklı olabilir...
    //this.editorMsg.nativeElement.focus();
    // setTimeout(() => {
    this.editorMsg.nativeElement.blur();
    //   }, 100);
    // }, 100);

  }

  hideKeyboard() {
    Keyboard.hide();
  }

  // @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   if (this.editorMsg && !this.editorMsg.nativeElement.contains(event.target))
  //     this.unfocusEditor();
  // }

  unfocusEditor() {
    if (this.deviceService.isMobile) {
      // setTimeout(() => {
      if (this.chatService.activeEditor === this.editorMsg.nativeElement) {
        this.chatService.activeEditor.blur();
        //setTimeout(() => {
        //  this.chatService.activeEditor.focus();
        this.getSelection();
        //});
      }
      // });
    }

  }


  setEditorTextFont(fontSettings: Nicktype) {
    let defaultNickType: Nicktype = new Nicktype();
    this.editorStyle = {
      'font-weight': defaultNickType.B === true ? 'bold' : 'normal',
      'font-style': defaultNickType.I === true ? 'italic' : 'normal',
      'color': 'white',
      'font-family': defaultNickType.F,
      'font-size': fontSettings.FS + "px",
      'line-height': fontSettings.FS + 5 + "px"
    };
  }

  getEditorTextFontFromIndexedDB() {
    this.indexedDBService.getPrivateSetting(this.indexedDBService.settingsNickTypePrivateKey)
      .then((fontSettings: Nicktype) => {
        if (!this.utilService.isNullOrEmtpyObject(fontSettings))
          this.setEditorTextFont(fontSettings);
      })
      .catch(error => {
        console.log(error);
      });

  }

  ngOnChanges(changes: SimpleChanges) {
    let editorMessageForSet = changes['editorMessageForSet'] as SimpleChange;
    if (editorMessageForSet.previousValue) {
      if (!this.utilService.isNullOrUndefined(this.editorMsg))
        this.editorMsg.nativeElement.focus();

      this.editorTextChanged.emit(editorMessageForSet.currentValue);
    }
  }

  //#region Paste Operations

  public pasteTextToEditor(pastingText: string): void {
    try {
      if (!this.editorMsg || !this.editorMsg.nativeElement) {
        console.error("Editor element is not initialized.");
        return;
      }
  
      const editorElement = this.editorMsg.nativeElement;
  
      if (editorElement.childNodes.length > 0) {
        editorElement.appendChild(document.createTextNode(" "));
      }
  
      const textNode = document.createTextNode(pastingText);
      editorElement.appendChild(textNode);
      this.setCaretAtEnd();
  
    } catch (error) {
      console.error("Error pasting text to editor: ", error);
    }
  }
  
  private setCaretAtEnd(): void {
    const editorElement = this.editorMsg.nativeElement;
    const range = document.createRange();
    const selection = window.getSelection();
  

    range.selectNodeContents(editorElement);
    range.collapse(false);
  
    selection.removeAllRanges();
    selection.addRange(range);
  }
  

  public pasteEmojiToEditor(pastingText: string): void {
    try {
      if (!this.editorMsg || !this.editorMsg.nativeElement) {
        console.error("Editor element is not initialized.");
        return;
      }

      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = pastingText.trim();
  
      const emojiNodes = Array.from(tempDiv.childNodes);
      if (!emojiNodes || emojiNodes.length === 0) {
        console.error("No valid emoji nodes found in the input text.");
        return;
      }
  
      emojiNodes.forEach((emojiNode) => {
        if (emojiNode instanceof Element && emojiNode.nodeName === "IMG" && emojiNode.classList.contains("emoji")) {
          const newEmojiNode = emojiNode.cloneNode(true);
          this.editorMsg.nativeElement.appendChild(newEmojiNode);
        }
      });
  
    } catch (error) {
      console.error("Error pasting emoji to editor: ", error);
    }
  }
  
  private setEditorTextForOneNode(currentNode, index, editorData, pastingText) {
    console.log("////////// pastingText: ", pastingText)
    if (
      (this.isEditorNode(this.startNode) && this.isEditorNode(this.endNode)) ||
      this.startNode !== this.endNode
    )
      this.setEditorTextIfNotEqualsStartEndNodes(
        currentNode,
        index,
        editorData,
        pastingText
      );
    else
      this.setEditorTextIfEqualsStartEndNodes(
        currentNode,
        index,
        editorData,
        pastingText
      );
  }

  private setEditorTextIfEqualsStartEndNodes(
    currentNode,
    index,
    editorData,
    pastingText
  ) {
    if (currentNode === this.startNode || currentNode === this.endNode) {
      if (this.isEmojiNode(currentNode)) {
        editorData.text += pastingText;
        editorData.pasteIndex = index;
      } else this.appendTextToOneNodeText(editorData, index, pastingText);
    } else editorData.text += this.getHtmlObjectText(currentNode);

    if (this.isEditorNode(this.startNode) && index === this.startOffset - 1) {
      editorData.text += pastingText;
      editorData.pasteIndex = index;
    }
  }

  private setEditorTextIfNotEqualsStartEndNodes(
    currentNode,
    index,
    editorData,
    pastingText
  ) {
    if (currentNode === this.startNode) {
      if (this.isTextNode(currentNode))
        editorData.text += this.startNode.data.substring(0, this.startOffset);

      editorData.text += pastingText;
      editorData.pasteIndex = index;
      editorData.isSelectedAreaOperationStarted = true;
      return;
    } else if (
      this.isEditorNode(this.startNode) &&
      index === this.startOffset - 1
    ) {
      editorData.isSelectedAreaOperationStarted = true;
      editorData.text += this.getHtmlObjectText(currentNode);
      editorData.text += pastingText;
      editorData.pasteIndex = index;

      if (
        this.isEditorNode(this.endNode) &&
        this.startOffset === this.endOffset
      )
        editorData.isSelectedAreaOperationStarted = false;
      return;
    } else if (currentNode === this.endNode) {
      if (this.isTextNode(currentNode))
        editorData.text += this.endNode.data.substring(
          this.endOffset,
          this.endNode.data.length
        );

      editorData.isSelectedAreaOperationStarted = false;
      return;
    } else if (
      this.isEditorNode(this.endNode) &&
      index === this.endOffset - 1
    ) {
      editorData.isSelectedAreaOperationStarted = false;
      return;
    }

    if (editorData.isSelectedAreaOperationStarted === false)
      editorData.text += this.getHtmlObjectText(currentNode);
  }

  private appendTextToOneNodeText(editorData, index, pastingText) {
    var temptext = this.startNode.data;
    this.startIndex = this.startOffset;
    this.endIndex = this.endOffset;

    var start = temptext.slice(0, this.startIndex);
    var end = temptext.slice(this.endIndex, temptext.length);

    editorData.text += start + pastingText + end;
    editorData.pasteIndex = index;
  }

  private setCaretPositionAfterPaste(childIndex, childTextIndex) {
    setTimeout(
      function () {
        var editor = this.editorMsg.nativeElement;
        var range = document.createRange();
        var sel = window.getSelection();
        if (childTextIndex)
          range.setStart(editor.childNodes.item(Math.max(0, childIndex)), childTextIndex);
        else
          range.setStartAfter(editor.childNodes.item(childIndex));
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        editor.focus();
        this.getSelection();
      }.bind(this)
    );
  }

  //#endregion

  //#region Helper Methods

  public getEditorText() {
    var text = "";

    this.getEditorChildNodes().forEach(node => {
      if (this.isSupportedNode(node) === true)
        text += this.getHtmlObjectText(node);
    });

    return text.trim();
  }

  getEditorTextForSend(): string {
    let textContent = '';
  
    try {
      this.editorMsg.nativeElement.childNodes.forEach(node => {
        if (node.nodeName === "#text") {
          textContent += node.textContent;
        } else if (node.nodeName === "SPAN") {
          textContent += node.textContent;
        } else if (node.nodeName === "IMG" && this.isSupportedNode(node)) {
          textContent += node.outerHTML;
        }
      });
    } catch (error) {
      console.log("Error extracting text from editor:", error);
    }
  
    return textContent.trim(); // Ensure no extra spaces
  }
  

  private isEmojiNode(currentNode) {
    return currentNode.nodeName === "IMG";
  }

  private isTextNode(currentNode) {
    return currentNode.nodeName === "#text";
  }

  private isEditorNode(currentNode) {
    if (currentNode.id)
      return currentNode.id === "editor";
    else
      return false;
  }

  private getHtmlObjectText(currentNode) {
    if (this.isEmojiNode(currentNode)) return currentNode.outerHTML;
    else return currentNode.data;
  }

  private setEditorText(text) {
    try {
      this.editorMessageForSet = Promise.resolve("");
      this.editorMessageForSet = Promise.resolve(text);
    }
    catch (error) {
      console.log(error);
    }

  }

  public clearEditor() {
    this.setEditorText("");
  }

  clearUnsupportedTags() {
    this.editorMsg.nativeElement.childNodes.forEach(currentNode => {
      if (this.isSupportedNode(currentNode) === false)
        currentNode.remove();
    });
  }

  clearPasteTextFakeSpan() {
    let text = "";
    this.editorMsg.nativeElement.childNodes.forEach(currentNode => {

      if (currentNode.nodeName === "#text")
        text += currentNode.textContent;
      else if (currentNode.nodeName === "SPAN")
        text += currentNode.textContent;
      else if (currentNode.nodeName === "IMG" && this.isSupportedNode(currentNode))
        text += currentNode.outerHTML;
    });
    this.setEditorText(text);

  }

  isSupportedNode(currentNode) {
    if (
      this.isEmojiNode(currentNode) === true &&
      currentNode.attributes &&
      currentNode.attributes["class"] &&
      currentNode.attributes["class"].value === "emoji"
    )
      return true;

    if (this.isTextNode(currentNode) === true) return true;

    return false;
  }

  getEditorChildNodes() {
    return this.editorMsg.nativeElement.childNodes;
  }

  //#endregion

  //#region Selection Operations

  public getSelection() {
    const currentSelection: Selection = window.getSelection();
    const currentSelectedText: string = currentSelection.toString();
    this.selectedText = currentSelectedText;
    this.startNode = currentSelection.anchorNode; //startNode
    this.endNode = currentSelection.focusNode; //endNode
    this.startOffset = currentSelection.anchorOffset;
    this.endOffset = currentSelection.focusOffset;
  }

  private reverseStartEndNodeIfNecessary() {
    var startNodeIndex = -1;
    var endNodeIndex = -1;

    this.clearUnsupportedTags();

    if (
      this.editorMsg.nativeElement.childNodes.length === 0 &&
      this.editorMsg.nativeElement.innerHTML === ""
    )
      return;

    this.getEditorChildNodes().forEach((node, index) => {
      if (node === this.endNode) endNodeIndex = index;
      if (node === this.startNode) startNodeIndex = index;
    });

    if (this.isEditorNode(this.startNode))
      startNodeIndex = this.startOffset - 1;

    if (this.isEditorNode(this.endNode))
      endNodeIndex = this.endOffset - 1;

    if (endNodeIndex < startNodeIndex)
      this.reverseStartEndNode();
  }

  private reverseStartEndNode() {
    var tempNode = this.startNode;
    this.startNode = this.endNode;
    this.endNode = tempNode;

    var tempOffset = this.startOffset;
    this.startOffset = this.endOffset;
    this.endOffset = tempOffset;
  }

  //#endregion

  //#region Events 

  onKeydown(event) {
    if (event.code === "Enter" || event.code === "NumpadEnter"
     || event.keyCode===13 || event.key === "Enter" || event.which === 13) {
      this.editorEnterPressed.emit();
      event.preventDefault();
      return false;
    }

    if (this.deviceService.isMobile) {
      setTimeout(() => {
        var textcontent = event.srcElement.textContent;
        this.editorTextChanged.emit(textcontent);
      });
    }

  }

  mousedown(event) {
    event.target.focus();
    this.getSelection();
    this.chatService.activeEditor = event.target;
    setTimeout(() => {
      event.target.click();
    });
  }

  public pasteToEditor(event) {
    event.preventDefault();
    var pastingText = event.clipboardData.getData("text/plain").toString();
    // this.pasteTextToEditor(`<span>${pastingText}</span>`, false);
    this.pasteTextToEditor(pastingText);
  }
  //#endregion
}