<p-dialog [(visible)]="panelManagerService.roomUserCardPopupDisplay" [modal]="true" [baseZIndex]="99999"
    [responsive]="true" [showHeader]="false" (onHide)="closeUserCard()" [dismissableMask]="true">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}" #userCard>

        <div class="user-card-container" *ngIf="loaded">

            <div class="user-card mt-1">
                <app-user-photo [userPhotoId]="roomService.selectedRoomUser.ProfilResmiId" [width]="40" [height]="40"
                    [showStateIcon]="false" class="user-photo" (click)="showProfilePicture()"></app-user-photo>
                <div>
                    {{ roomService.selectedRoomUser.KullaniciAdi }}
                    <app-user-id [userId]="roomService.selectedRoomUser.KullaniciId"></app-user-id>
                </div>
                <app-rank [degree]="roomService.selectedRoomUser.RutbeDerece" [color]="'orange'" [size]="10">
                </app-rank>
            </div>
            
            <div class="user-card-items" (click)="addRemoveFriend()"
                *ngIf="(isSendPrivateMessage && indexedDBService.userFullInfo.RutbeDerece != -1) && roomService.selectedRoomUser.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeAdi != 'SipUser'">
                <fa-icon [icon]="isMyFriend ? faUserCheck : faUserPlus" style="color: aquamarine;"></fa-icon>
                <span>{{ isMyFriend ? ('removeFriend' | translate) : ('addFriend' | translate) }}</span>
            </div>

            <div class="user-card-items" *ngIf="roomUserContextMenuOperation.writePrivateMessage && indexedDBService.userFullInfo.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeAdi != 'SipUser' "
                (click)="writePrivateMessage()">
                <fa-icon [icon]="faComments" style="color: deepskyblue;"></fa-icon>
                <span>{{ 'writePrivateMessage' | translate }}</span>
            </div>

            <div class="user-card-items extra-item" *ngIf="roomUserContextMenuOperation.inviteToMic  && indexedDBService.userFullInfo.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeAdi != 'SipUser'"
                (click)="inviteToMic()">
                <fa-icon [icon]="faMicrophoneAlt"></fa-icon>
                <span>{{ 'inviteToMic' | translate }}</span>
            </div>

            <div class="user-card-items" *ngIf="roomUserContextMenuOperation.banOda  && indexedDBService.userFullInfo.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeAdi != 'SipUser'"
                (click)="openBanMutePanel('roomBan', 6, banOrMute.Ban)">
                <fa-icon [icon]="faBan" style="color: pink;"></fa-icon>
                <span>{{ 'roomBan' | translate }}</span>
            </div>

            <div class="user-card-items extra-item" *ngIf="roomUserContextMenuOperation.removeBanOda  && indexedDBService.userFullInfo.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeAdi != 'SipUser'"
                (click)="removeBanMute(false, banOrMute.Ban)">
                <fa-icon [icon]="faBan"></fa-icon>
                <span>{{ 'removeRoomBan' | translate }}</span>
            </div>

            <div class="user-card-items" *ngIf="roomUserContextMenuOperation.banSite  && indexedDBService.userFullInfo.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeAdi != 'SipUser'"
                (click)="openBanMutePanel('siteBan', 6, banOrMute.Ban)">
                <fa-icon [icon]="faBan" style="color: red;"></fa-icon>
                <span>{{ 'siteBan' | translate }}</span>
            </div>

            <div class="user-card-items extra-item" *ngIf="roomUserContextMenuOperation.removeBanSite  && indexedDBService.userFullInfo.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeAdi != 'SipUser'"
                (click)="removeBanMute(true, banOrMute.Ban)">
                <fa-icon [icon]="faBan"></fa-icon>
                <span>{{ 'removeSiteBan' | translate }}</span>
            </div>

            <div class="user-card-items" *ngIf="roomUserContextMenuOperation.banOda  && indexedDBService.userFullInfo.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeAdi != 'SipUser'"
                (click)="openBanMutePanel('roomBanEmailOnly', 8, banOrMute.Ban)">
                <fa-icon [icon]="faBan" style="color: pink;"></fa-icon>
                <span>{{ 'Oda Ban (Sadece Email)' | translate }}</span>
            </div>

            <div class="user-card-items" *ngIf="roomUserContextMenuOperation.banSite  && indexedDBService.userFullInfo.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeAdi != 'SipUser'"
                (click)="openBanMutePanel('siteBanEmailOnly', 8, banOrMute.Ban)">
                <fa-icon [icon]="faBan" style="color: red;"></fa-icon>
                <span>{{ 'Site Ban (Sadece Email)' | translate }}</span>
             </div>

            <div class="user-card-items" *ngIf="roomUserContextMenuOperation.muteOda  && indexedDBService.userFullInfo.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeAdi != 'SipUser'"
                (click)="openBanMutePanel('roomMute', null, banOrMute.Mute)">
                <fa-icon [icon]="faVolumeMute" style="color: green;"></fa-icon>
                <span>{{ 'roomMute' | translate }}</span>
            </div>

            <div class="user-card-items extra-item" *ngIf="roomUserContextMenuOperation.removeMuteOda  && indexedDBService.userFullInfo.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeAdi != 'SipUser'"
                (click)="removeBanMute(false, banOrMute.Mute)">
                <fa-icon [icon]="faVolumeMute"></fa-icon>
                <span>{{ 'removeRoomMute' | translate }}</span>
            </div>

            <div class="user-card-items" *ngIf="roomUserContextMenuOperation.muteSite  && indexedDBService.userFullInfo.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeAdi != 'SipUser'"
                (click)="openBanMutePanel('siteMute', null, banOrMute.Mute)">
                <fa-icon [icon]="faVolumeMute" style="color: greenyellow;"></fa-icon>
                <span>{{ 'siteMute' | translate }}</span>
            </div>

            <div class="user-card-items extra-item" *ngIf="roomUserContextMenuOperation.removeMuteSite  && indexedDBService.userFullInfo.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeDerece != -1 && roomService.selectedRoomUser.RutbeAdi != 'SipUser'"
                (click)="removeBanMute(true, banOrMute.Mute)">
                <fa-icon [icon]="faVolumeMute"></fa-icon>
                <span>{{ 'removeSiteMute' | translate }}</span>
            </div>




            <div class="user-card-items extra-item" *ngIf="roomUserContextMenuOperation.kameraSonlandir"
                (click)="finishCam()">
                <fa-icon [icon]="faCamera"></fa-icon>
                <span>{{ 'dropCam' | translate }}</span>
            </div>

            <div class="user-card-items extra-item" *ngIf="roomUserContextMenuOperation.mikrofonDusurme"
                (click)="stopUserMic()">
                <fa-icon [icon]="faMicrophoneAltSlash"></fa-icon>
                <span>{{ 'dropMic' | translate }}</span>
            </div>

            <div class="user-card-items" *ngIf="roomUserContextMenuOperation.sesiniKapatma"
                (click)="muteUserMic()">
                <fa-icon [icon]="faVolumeMute" style="color: green;"></fa-icon>
                <span>{{ 'mute-user-mic' | translate }}</span>
             </div>

             <div class="user-card-items" *ngIf="roomUserContextMenuOperation.sesAcmaTalebi"
                (click)="unMuteMicRequest()">
                <fa-icon [icon]="faVolumeUp" style="color: green;"></fa-icon>
                <span>{{ 'unmute-user-mic-request' | translate }}</span>
             </div>

            <div class="user-card-items" *ngIf="roomUserContextMenuOperation.atmaOda" (click)="kickFromRoom()">
                <fa-icon [icon]="faArrowAltCircleRight" style="color: khaki;"></fa-icon>
                <span>{{ 'kickRoom' | translate }}</span>
            </div>


            <div class="user-card-items extra-item" *ngIf="roomUserContextMenuOperation.elIndir"
                (click)="dropUserHand()">
                <fa-icon [icon]="faHandPaper"></fa-icon>
                <span>{{ 'dropHand' | translate }}</span>
            </div>

            <div class="user-card-items" *ngIf="goToTheRoomVisible" (click)="goToTheRoom()">
                <fa-icon [icon]="faHandPointer" style="color: yellow;"></fa-icon>
                <span>{{ 'GoToTheRoom' | translate }}</span>
            </div>

            <div class="user-card-items" *ngIf="roomUserContextMenuOperation.odayaDavet" (click)="inviteToTheRoom()">
                <fa-icon [icon]="faEnvelope" style="color: turquoise;"></fa-icon>
                <span>{{ 'inviteroom' | translate }}</span>
            </div>




            <div class="user-card-items" *ngIf="roomService.roomUserContextMenuMode === RoomUserContextMenuMode.RoomChatBubble && 
            roomService.selectedRoomUser.RutbeAdi !== 'Big Boss' && 
            roomService.selectedRoomUser.KullaniciId !== indexedDBService.userFullInfo.KullaniciId &&
            indexedDBService.userFullInfo.RutbeDerece != -1 &&
            roomService.selectedRoomUser.RutbeAdi != 'SipUser'"
                (click)="complainMessage()">
                <fa-icon [icon]="faFlagMessage" style="color: coral;"></fa-icon>
                <span>{{ 'report-message' | translate }}</span>
            </div>

            <div class="user-card-items"
                *ngIf="(roomService.selectedRoomUser.RutbeAdi !== 'Big Boss' && roomService.selectedRoomUser.KullaniciId !== indexedDBService.userFullInfo.KullaniciId) && this.indexedDBService.userFullInfo.RutbeDerece != -1"
                (click)="complainUser()">
                <fa-icon [icon]="faFlag" style="color: orange;"></fa-icon>
                <span>{{ 'report-user' | translate }}</span>
            </div>

        </div>
    </p-scrollPanel>
</p-dialog>