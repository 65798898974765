
import { ContactType, MessageSendingType, ContactState, ChatType, ChatState, ConferenceType, BulkMessageType, MessageBubbleType, BubbleTypes, MessageType } from 'src/app/core/models/enums';
import { Constants } from 'src/app/core/models/constants';
import { Nicktype } from 'src/app/core/models/nick-type';

export class ChatMessage {
    // LocalDBId: string = null; //string
    MessageId: string = null; //string
    IndexName: string = null; //string  //index sqlite geçmiş mesaj için
    Channelname: string = null; //string   
    SenderRutbeDerece: number = 0; //int
    SenderId: number = 0; //int
    // ReceiverSecretKey: string = null; //string
    SenderName: string = null; //string
    SenderNameSecond: string = null; //string
    ReceiverId: number = 0; //int
    ReceiverName: string = null; //string
    RtfText: string = null; //string
    // RtfTextTranslated: string = null; //string
    Text: string = null //string
    // TextImageCount: number = 0; //int
    Time: Date = new Date(); //new Date(2019, 1, 1, 0, 0, 0, 0); //DateTime
    // ChannelSessionId: string = Constants.guidEmpty; //Guid
    UserPhotoId: string = Constants.guidEmpty; //Guid
    ContactType: ContactType = ContactType.Chat; //int _enums.ContactType
    Contactstate: ContactState = ContactState.Hicbiri; //int _enums.ContactState
    MessageSendingType: MessageSendingType = MessageSendingType.Normal; //int _enums.MessageSendingType
    // IsFile: boolean = false; //bool
    ChatType: ChatType = ChatType.SingleChat; //int _enums.ChatType
    Chatstate: ChatState = ChatState.GonderilenMesaj; //int _enums.ChatState
    // ConferenceType: ConferenceType = ConferenceType.Normal; //int _enums.ConferenceType
    // CommunicationMessage: null; //CommunicationMessage
    NickType: Nicktype = null; //string   
    SenderStateId: number = 0; //int
    IsTopluMesaj: boolean = false; //bool
    // IsSended: boolean = false; //bool
    BulkMessageType: BulkMessageType = BulkMessageType.OneChannel; //int _enums.BulkMessageType
    // BulkMessageIdListKey: string = null; //string 
    // BulkMessageIdForGenerateChannell: number = 0; //int 
    // BulkMessageSenderId: string = null; //string ,
    // IsFirstMessage: boolean = false;
    MessageBubbleType: MessageBubbleType;
    MessageType?: MessageType = MessageType.Text;
    RoomNameWhereTheMessageSent: string;
    RoomIdWhereTheMessageSent: number;
    IsDocs:boolean=false;
    IsImage:boolean=false;
    URL:string="";
}
export class ChatMessageForBubble {
    senderId: number;
    // htmlEditorMessageId: string;
    userImageUrl: string;
    // bubbleType: BubbleTypes; // _enums.BubbleTypes
    MessageBubbleType: MessageBubbleType; // _enums.MessageBubbleTypes
    // messageTime: string;
    messageHtml: string[];
    userName: string;
    // isFirstMessage: boolean;
    timeString: string;
    isSended: boolean;
    nickType?:Nicktype
    messageType?: MessageType = MessageType.Text;
    roomNameWhereTheMessageSent: string;
    roomIdWhereTheMessageSent: number;
    isFile:boolean=false;
    isImage:boolean=false;
    fileUrl:string="";
    messageContent:string="";
}
export class ChatMessageSearchParams {
    friendUserId: number;
    limit: number;
    topMessageId: string;
}
export class FileMessageData {
    isFile:boolean
    isImage:boolean
    url:string
    path:string
}

export class FileMessageUploadResponse {
    ErrMsg: string 
	Result :boolean  
	Path   :string 
	URL    :string
}
export class ConferenceRequestResponse {
    userId: number;
    conferenceId: string;
    contactType: ContactType;
    answer: P2PConferenceAnswerType;
    show:boolean;
    receiving:boolean;
    candidates: Array<any>;
    sdp: any;
}



export enum P2PConferenceAnswerType {
    Accept,
    Reject,   
    UnReachable,
    CancelCalling,
}

export class MessageRecords {
    data: any[];
    next: string = null;
}