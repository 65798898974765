import { Router } from '@angular/router';
import { LoginService } from './../../services/login.service';
import { Component, OnInit, ViewChild, ComponentFactoryResolver, OnDestroy, ElementRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { MainPanels, PopupPanels, RoomPagePanels, ChatType, ContactType } from 'src/app/core/models/enums';
import { P2PChatDirective } from '../p2p-chat/p2p-chat.directive';
import { P2PChatComponent } from '../p2p-chat/p2p-chat.component';
import { Subscription } from 'rxjs';
import { ActiveChatPanel } from 'src/app/core/models/chat-panel-props';
import { DeviceService } from 'src/app/core/services/device.service';
import { RoomService } from 'src/app/core/services/room.service';
import { Constants, JsonFile } from 'src/app/core/models/constants';
import { ChatService } from 'src/app/core/services/chat.service';
import { RoomCameraDirective } from '../room/camera/camera.directive';
import { RoomCamera, RoomInfo, UserListType } from 'src/app/core/models/room';
import { RoomCameraComponent } from '../room/camera/camera.component';
import { DynamicComponentManagerService } from 'src/app/core/services/dynamic-component-manager.service';
import { AdminModeratorService } from 'src/app/admin/tabs/moderators/services/moderator.service';
import { IndexedDBService } from '../../services/indexed-db.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { RoomScreenShareDirective } from '../room/screen-share/screen-share.directive';
import { ScreenShareComponent } from '../room/screen-share/screen-share.component';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { MessagePanelService } from '../../services/messagePanel.service';
import { faChevronDown, faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss', './home-page.web.component.scss', './home-page.mobile.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class HomePageComponent implements OnInit, OnDestroy, AfterViewInit {

  roomPagePanels = RoomPagePanels;
  mainPanels = MainPanels;
  popupPanels = PopupPanels;
  swiperIndex = 1;
  constants = Constants;
  contactType = ContactType.VideoAndAudio;
  display: boolean = true;

  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  
  roomCameraIdIndex: { [key: number]: number } = {}; //key: roomCameraPanelId , value: componentView Index
  p2pChatIdIndex: { [key: number]: number } = {}; //key: p2pChatPanelId , value: componentView Index

  @ViewChild(P2PChatDirective, { read: P2PChatDirective }) p2pChatHost: P2PChatDirective;
  @ViewChild("contentDiv") contentDiv: ElementRef<HTMLElement>;
  @ViewChild("mainSwiper") mainSwiper: ElementRef;
  youtubeWidth: string;

  swiperConfig: {
    updateOnWindowResize: true
  }

  @ViewChild(RoomCameraDirective, { read: RoomCameraDirective }) roomVideoHost: RoomCameraDirective;
  @ViewChild(RoomScreenShareDirective, { read: RoomScreenShareDirective }) roomScreenShareHost: RoomScreenShareDirective;


  p2pChatPanelsAddedSubscription: Subscription;
  roomVideoPanelsAddedSubscription: Subscription;
  roomVideoPanelsRemovedSubscription: Subscription;
  mainSwiperUpdatedSubscription: Subscription;
  p2pChatPanelsRemovedSubscription: Subscription;
  youtubePanelDisplayedSubscription: Subscription;
  roomVideoElementsClearSubscription: Subscription;


  joinRoomSubscription: Subscription;


  roomId:number

  templateId:number
  guid:string
  pathParam:string
  newRoomId:number

  aiImageBounds:HTMLElement

  constructor(
    public panelManagerService: PanelManagerService,
    private componentFactoryResolver: ComponentFactoryResolver,
    public deviceService: DeviceService,
    public roomService: RoomService,
    private chatService: ChatService,
    private dynamicComponentManagerService: DynamicComponentManagerService,
    public adminModeratorService: AdminModeratorService,
    public indexedDBService: IndexedDBService,
    public loginService: LoginService,
    public localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private router:Router,
    private confirmationService:ConfirmationService,
    private translateService: TranslateService,
    private messagePanelService:MessagePanelService
    ) {
    this.youtubePanelDisplayedSubscription = this.panelManagerService.youtubePanelDisplayed.subscribe((youtubeWidth: number) => {
      this.youtubeWidth = 'calc((100% - ' + youtubeWidth + 'px) / 2)';
    })

    this.p2pChatPanelsAddedSubscription = this.panelManagerService.p2pChatPanelsAdded.subscribe((activeChatPanel: ActiveChatPanel) => {
      this.openP2PChatPanel(activeChatPanel);
    });

    this.p2pChatPanelsRemovedSubscription = this.panelManagerService.p2pChatPanelsRemoved.subscribe((userId: number) => {
      //if(!this.utilService.isNullOrUndefined(this.panelManagerService.p2pChatPanels.find(f => f.user.KullaniciId === userId && f.display === true))) 
      this.removeP2PChatPanel(userId);
    });

    this.roomVideoPanelsAddedSubscription = this.panelManagerService.roomVideoPanelsAdded.subscribe((roomCamera: RoomCamera) => {
      this.openRoomVideoPanel(roomCamera);
    });

    this.roomVideoPanelsRemovedSubscription = this.panelManagerService.roomVideoPanelsRemoved.subscribe((userId: number) => {
      this.removeRoomVideoPanel(userId);
    });

    this.mainSwiperUpdatedSubscription = this.panelManagerService.mainSwiperUpdated.subscribe(() => {
      if (this.mainSwiper && this.mainSwiper.nativeElement.directiveRef)
        this.mainSwiper.nativeElement.directiveRef.update();
    });

   this.joinRoomSubscription = this.roomService.joinRoom.subscribe((roomCameraList: Array<RoomCamera>) => {
      roomCameraList.forEach(roomCamera => {
        this.appendToRoomVideoPanels(roomCamera);
        this.appendToRoomScreenShareVideoPanels(roomCamera);
      });
    });
    this.roomVideoElementsClearSubscription = this.panelManagerService.roomVideoElementsClear.subscribe(() => {
      this.clearRoomVideoElements();
    })
  }



  calculateIndex(element: Node) {
    if (element) {
      var i = 1;
      while ((element = element.previousSibling) != null)
        i++;

      return i;
    } else {
      return 0;
    }
  }

  ngOnInit() {
   this.checkParametres()
   this.roomService.environmentVariableLoadedSource.next(null) //for room list loading
  }

  clearRoomVideoElements() {
    if ((this.roomVideoHost && this.roomVideoHost.viewContainerRef) || this.roomScreenShareHost && this.roomScreenShareHost.viewContainerRef){
      this.roomVideoHost.viewContainerRef.clear();
	    this.roomScreenShareHost.viewContainerRef.clear();
    }
  }
  checkParametres() {
    this.route.params.subscribe(params => {
      this.roomId = parseInt(params['id']);
      this.templateId = parseInt(params['templateId']);
      this.guid = params['guid'];
      this.pathParam = params['roomPathParam']

      if (this.roomId || this.pathParam) {
        this.controlJoinRoomWithRoomId();
      } else if (this.templateId && this.guid) {
        this.createDynamicRoomAndJoinRoom();
      } else {
        this.loginService.login();
      }
    });
  }
  
  controlJoinRoomWithRoomId(){
      if (this.roomId || this.pathParam) {
          this.roomService.controlRoomPathParam(this.roomId, this.pathParam).then((response:boolean)=>{
            if (response === true) {
              this.confirmForRedirectToRoom(this.roomId)
            }
          })
        this.removeParamsFromURL()
      }
  }

  confirmForRedirectToRoom(roomId: number,password?:string) {
    this.roomService.getRoomInfo(roomId)
      .then((response: RoomInfo) => {
        let roomName = response.NAME;
        this.confirmationService.confirm({
          message: this.translateService.instant('confirm-ro-redirect-room') + ' (' + roomName + ') ' ,
          header: this.translateService.instant('redirect-room'),
          icon: null,
          accept: () => {
            this.roomService.enterRoomNew(roomId,password);
          },
          reject: () => {
  
          }
        });
      });
  }
  
  removeParamsFromURL() {
    let currentUrlWithoutParams = '/home';
    window.history.replaceState({}, document.title, currentUrlWithoutParams);
  }
  
  createDynamicRoomAndJoinRoom() {
      if (this.templateId && this.guid) {
        return this.callCreateDynamicRoomByTemplateId()
        .then((response) => {
          this.newRoomId = response.RoomId;
          return this.roomService.enterDynamicRoom(this.newRoomId);
        })
        .catch((error) => {
          this.messagePanelService.ShowPopupMessageWithLocalization(error, 2000);
          this.localStorageService.storageRemoveItem('token');
          this.router.navigate(["login"]);
        })
        .finally(() => {
          this.panelManagerService.aiUserNameEntryLoading = false;
          this.removeParamsFromURL()
        });
      }
  }
  
  callCreateDynamicRoomByTemplateId() {
    let body = {
      "TemplateID": this.templateId,
      "GUID": this.guid,
      "HesapID": 0
    };
    return this.roomService.callCreateDynamicRoomFromAITemplate(body);
  }
  

  listVIPUsers(){
    if (this.roomService.selectedUserListType != UserListType.VIP) {
      this.roomService.pageIndex = 1
      this.roomService.callGetVipUserList()
    }
  }

  listOnlineUsers(){
    if (this.roomService.selectedUserListType != UserListType.ONLINE) {
      this.roomService.pageIndex = 1
      this.roomService.callGetOnlineUserList()
    }
  }

  listHandRequest(){
    if (this.roomService.selectedUserListType != UserListType.REQUEST) {
      this.roomService.pageIndex = 1
      this.roomService.callGetHandRequestUserList()
      this.roomService.isThereHandRequest = false
    }
  }
  firstPage(){
    //ilk sayfadayken ilk sayfaya geçmeye çalışıyoruz
    if (this.roomService.pageIndex == 1) {
      return
    }
    this.roomService.expandUserList(1)
  }
  
  previousPageUserList(){
    //ilk sayfadayken previous olmaz
    if (this.roomService.pageIndex == 1) {
      return
    }
    let index = this.roomService.pageIndex - 1
    this.roomService.expandUserList(index)
  }

  nextPageUserList(){
    //son sayfadayken son sayfaya geçmeye çalışıyoruz
    if (this.roomService.selectedUserListType == UserListType.VIP) {
      if ((this.roomService.pageIndex == Math.floor(this.roomService.vipUserCount / this.roomService.pageSize) +1) || this.roomService.vipUserCount <= this.roomService.pageSize ) {
        return
      }
    }
    if (this.roomService.selectedUserListType == UserListType.ONLINE) {
      if ((this.roomService.pageIndex == Math.floor(this.roomService.roomUserCount / this.roomService.pageSize) +1) || this.roomService.roomUserCount <= this.roomService.pageSize ) {
        return
      }
    }
    if (this.roomService.selectedUserListType == UserListType.REQUEST) {
      if ((this.roomService.pageIndex == Math.floor(this.roomService.requestUserCount / this.roomService.pageSize) +1) || this.roomService.requestUserCount <= this.roomService.pageSize ) {
        return
      }
    }
    let index = this.roomService.pageIndex + 1
    this.roomService.expandUserList(index)
  }

  lastPage(){
    if (this.roomService.selectedUserListType == UserListType.VIP) {
      if ((this.roomService.pageIndex == Math.floor(this.roomService.vipUserCount / this.roomService.pageSize) +1 ) || this.roomService.vipUserCount <= this.roomService.pageSize ) {
        return
      }
      else{
        let index = Math.floor(this.roomService.vipUserCount / this.roomService.pageSize) + 1;
        this.roomService.expandUserList(index)
      }
    }
    else if (this.roomService.selectedUserListType == UserListType.ONLINE) {
      if ((this.roomService.pageIndex == Math.floor(this.roomService.roomUserCount / this.roomService.pageSize) +1 ) || this.roomService.roomUserCount <= this.roomService.pageSize ) {
        return
      }
      else{
        let index = Math.floor(this.roomService.roomUserCount / this.roomService.pageSize) + 1;
        this.roomService.expandUserList(index)
      }
    }
    else{
      if ((this.roomService.pageIndex == Math.floor(this.roomService.requestUserCount / this.roomService.pageSize) +1 ) || this.roomService.requestUserCount <= this.roomService.pageSize ) {
        return
      }
      else{
        let index = Math.floor(this.roomService.requestUserCount / this.roomService.pageSize) + 1;
        this.roomService.expandUserList(index)
      }
    }
  }
  
  ngOnDestroy() {
    if (this.p2pChatPanelsAddedSubscription)
      this.p2pChatPanelsAddedSubscription.unsubscribe();

    if (this.roomVideoPanelsAddedSubscription)
      this.roomVideoPanelsAddedSubscription.unsubscribe();

    if (this.roomVideoPanelsRemovedSubscription)
      this.roomVideoPanelsRemovedSubscription.unsubscribe();

    if (this.mainSwiperUpdatedSubscription)
      this.mainSwiperUpdatedSubscription.unsubscribe();

    if (this.p2pChatPanelsRemovedSubscription)
      this.p2pChatPanelsRemovedSubscription.unsubscribe();

    if (this.youtubePanelDisplayedSubscription)
      this.youtubePanelDisplayedSubscription.unsubscribe();
    
    if (this.joinRoomSubscription)
      this.joinRoomSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.panelManagerService.mainLoading = false;
    this.aiImageBounds = this.contentDiv.nativeElement 

  }

  //#region p2p panel operations 

  openP2PChatPanel(activeChatPanel: ActiveChatPanel) {
    let viewIndex = this.getP2PChatPanelIndexByUserId(activeChatPanel.user.KullaniciId);
    if (viewIndex === -1)
      this.appendToP2PChats(activeChatPanel);
    // else
    //   this.showP2PChatPanel(activeChatPanel);
  }

  removeP2PChatPanel(userId: number) {
    let viewIndex = this.getP2PChatPanelIndexByUserId(userId);
    if (viewIndex !== -1) {
      this.p2pChatHost.viewContainerRef.remove(viewIndex);
    }
  }
  getP2PChatPanelIndexByUserId(userId: number): number {
    try {
      let embeddedViews = this.dynamicComponentManagerService.getEmbeddedViews(this.p2pChatHost.viewContainerRef);

      for (var i = 0; i < embeddedViews.length; i++)
        if (this.dynamicComponentManagerService.getDataByEmbeddedView<ActiveChatPanel>(embeddedViews[i], "activeChatPanel").user.KullaniciId === userId)
          return i;

      return -1;
    } catch (error) {
      return -1;
    }
  }

  showP2PChatPanel(activeChatPanel: ActiveChatPanel) {
    activeChatPanel.display = true;
  }

  appendToP2PChats(activeChatPanel: ActiveChatPanel, index?: number) {
    try {
      let componentFactory = this.componentFactoryResolver.resolveComponentFactory(P2PChatComponent);
      let viewContainerRef = this.p2pChatHost.viewContainerRef;
      let componentRef = viewContainerRef.createComponent(componentFactory);
      activeChatPanel.chatPanelProps = this.chatService.getChatFormPropertiesForFriendChatPanel(activeChatPanel.user, ChatType.SingleChat);
      (<P2PChatComponent>(componentRef.instance)).set(activeChatPanel, this.contentDiv.nativeElement);
    } catch (error) {
      console.log(error);
    }
  }

  clearP2PChats() {
    try {
      if (this.p2pChatHost && this.p2pChatHost.viewContainerRef) {
        this.p2pChatHost.viewContainerRef.clear();
      }
    }
    catch (error) {
      console.log(error)
    }
  }
  hideAIAnimationImage() {
    this.panelManagerService.roomAIImagePopupDisplay = false;
  }

  //#endregion

  //#region room video panel operations 

  removeRoomVideoPanel(userId: number) {
    let viewIndex = this.getRoomVideoPanelIndexByUserId(userId);
    this.roomVideoHost.viewContainerRef.remove(viewIndex);
  }

  removScreenShareVideoPanel(userId: number) {
    let viewIndex = this.getRoomScreenSharePanelIndexByUserId(userId);
    this.roomScreenShareHost.viewContainerRef.remove(viewIndex);
  }

  openRoomVideoPanel(roomCamera: RoomCamera) {
    let viewIndex = this.getRoomVideoPanelIndexByUserId(roomCamera.userId);
    if (viewIndex === -1)
     this.appendToRoomVideoPanels(roomCamera);
    else
      this.showRoomVideoPanel(roomCamera);
  }

  getRoomVideoPanelIndexByUserId(userId: number): number {
    try {
      let embeddedViews = this.dynamicComponentManagerService.getEmbeddedViews(this.roomVideoHost.viewContainerRef);

      for (var i = 0; i < embeddedViews.length; i++) {
        if (this.dynamicComponentManagerService.getDataByEmbeddedView<RoomCamera>(embeddedViews[i], "roomCamera").userId === userId)
          return i;
      }

      return -1;
    } catch (error) {
      return -1;
    }
  }
  
  getRoomScreenSharePanelIndexByUserId(userId: number): number {
    try {
      let embeddedViews = this.dynamicComponentManagerService.getEmbeddedViews(this.roomScreenShareHost.viewContainerRef);

      for (var i = 0; i < embeddedViews.length; i++) {
        if (this.dynamicComponentManagerService.getDataByEmbeddedView<RoomCamera>(embeddedViews[i], "roomCamera").userId === userId)
          return i;
      }

      return -1;
    } catch (error) {
      return -1;
    }
  }
  getRoomVideoPanelDataByEmbeddedView(embeddedView: any): RoomCamera {

    return embeddedView.nodes[1].instance.roomCamera as RoomCamera;
  }

  showRoomVideoPanel(roomCamera: RoomCamera) {
    //TODO

  }

  appendToRoomVideoPanels(roomCamera: RoomCamera, index?: number) {
    try {
      let id =  Constants.mainSwiperTag + '' + MainPanels.RoomVideo + '_' + roomCamera.userId
      let elm = <HTMLElement>document.getElementById(id);
      if (!elm){
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(RoomCameraComponent);
        let viewContainerRef = this.roomVideoHost.viewContainerRef;
        let componentRef = viewContainerRef.createComponent(componentFactory);
        componentRef.location.nativeElement.classList.add('hidden-component');
        (<RoomCameraComponent>(componentRef.instance)).set(roomCamera, this.contentDiv.nativeElement);
      }
    } catch (error) {
      console.log(error);
    }
  }

  appendToRoomScreenShareVideoPanels(roomCamera: RoomCamera, index?: number) {
    try {
      let id =  Constants.mainSwiperTag + '' + MainPanels.ScreenShare + '_' + roomCamera.userId
      let elm = <HTMLElement>document.getElementById(id);
      if (!elm){
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(ScreenShareComponent);
        let viewContainerRef = this.roomScreenShareHost.viewContainerRef;
        let componentRef = viewContainerRef.createComponent(componentFactory);
        componentRef.location.nativeElement.classList.add('hidden-component');
        (<ScreenShareComponent>(componentRef.instance)).set(roomCamera, this.contentDiv.nativeElement);
      }
    } catch (error) {
      console.log(error);
    }
  }

  clearRoomVideoPanels() {
    try {
      if (this.roomVideoHost && this.roomVideoHost.viewContainerRef) {
        this.roomVideoHost.viewContainerRef.clear();
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  //#endregion

  roomPageEvent($event) { }

  isSuccess($event) { }

  refreshUserList() {
    this.roomService.refreshUserList();
  }
}