<p-dialog class="updateStateMessage" [(visible)]="panelManagerService.phoneCallingPopupDisplay" [modal]="true"
[baseZIndex]="10000" [responsive]="true" header="{{'join-the-call' | translate}}">

    <div style="text-align:center;">
        <input [(ngModel)]="phoneNumber" type="text" autofocus="autofocus" placeholder="{{'enter-phone-number' | translate}}" 
            pInputText class="inputText dialog-input" />
    </div>

    <p-footer>
        <button pButton type="button" (click)="startCalling()" label="{{'call' | translate}}"
            class="p-button-raised p-button-info popup" style="width: 100%;"></button>
    </p-footer>

</p-dialog>
