<p-dialog class="updateStateMessage" [(visible)]="panelManagerService.accountDeletionPopupDisplay" style="width: 100%;" [modal]="true"
[baseZIndex]="10000" [responsive]="true">
    <p-header>
        {{'create-deletion-request' | translate }}
    </p-header>
    <div class="banPanelContainer">
        <span class="title" for="onlytext"> {{'deletion-reason' | translate }}</span>
        <br>
        <textarea class="description" [(ngModel)]="reason" pInputTextarea></textarea>
    </div>
    <p-footer>
        <button pButton type="button" (click)="createDeleteRequest()" label="{{'create-request' | translate}}"
            [ngStyle]="{'width': '100%'}" class="p-button-raised p-button-info popup"></button>
    </p-footer>

</p-dialog>