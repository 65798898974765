import { Injectable, Injector } from '@angular/core';
import { OperationMessageService } from './operation-message.service';
import { ChatService } from './chat.service';
import { ChatPanelService } from './chatPanel.service';
import { UtilService } from 'src/app/core/services/util.service';
import { DynamicMethod } from '../models/dynamic-method';
import { ChatType, BulkMessageType, ContactState, ContactType, BubbleTypes, MessageBubbleType, MessageSendingType } from 'src/app/core/models/enums';
import { Constants } from 'src/app/core/models/constants';
import { ChatMessage, ChatMessageForBubble } from '../models/chat-message';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { LocalizationService } from 'src/app/core/services/localization.service';
import { MessageArrayObject } from '../models/message';
import { Nicktype } from 'src/app/core/models/nick-type';
import { UserFullInfo } from '../models/user';
import { OperationMessage } from '../models/operation-message';
import { environment } from 'src/environments/environment';
import { RoomService } from './room.service';

@Injectable({
    providedIn: 'root'
})
export class ChatMessageService {


    constructor(
        private operationMessageService: OperationMessageService,
        private chatService: ChatService,
        private chatPanelService: ChatPanelService,
        private utilService: UtilService,
        protected indexedDBService: IndexedDBService,
        private localizationService: LocalizationService,
        private injector: Injector
    ) {

    }

    getRoomSettingMessage(roomId: number, dynamicMethodArray: Array<DynamicMethod>): OperationMessage {
        return this.operationMessageService.getSettingMessage(
            this.indexedDBService.userFullInfo,
            this.utilService.getRoomChannelName(roomId),
            ChatType.AdminChangesMethod,
            dynamicMethodArray
        );
    }

    sendAdminBulkSettingMessageToPublicChannel(dynamicMethodArray: Array<DynamicMethod>) {
        var operationMessage = this.operationMessageService.getSettingMessage(
            this.indexedDBService.userFullInfo,
            Constants.SETTINGSCHANNGELTAG,
            ChatType.AdminChangesMethod,
            dynamicMethodArray
        );
        return this.chatService.natsService.publishPublicSettingMessage(operationMessage);
    }



    sendAdminBulkMessageToPublicChannel(message: string, bulkMessageIdForGenerateChannell: number, bulkMessageType: BulkMessageType, chatType: ChatType) {

        var chatPanelProperties = this.chatPanelService.getChatFormPropertiesForFriendChatPanel(this.indexedDBService.userFullInfo, ChatType.GroupChat);

        var chatMessage = this.chatService.getChatMessage(
            chatPanelProperties,
            message,
            chatPanelProperties.ContactType,
            ContactState.Hicbiri,
            this.indexedDBService.userFullInfo
        );

        chatMessage.MessageId = this.utilService.guid();
        chatMessage.Channelname = Constants.SYSTEMCHANNEL;
        chatMessage.ContactType = ContactType.Chat;
        chatMessage.ChatType = chatType;
        chatMessage.SenderRutbeDerece = this.indexedDBService.userFullInfo.RutbeDerece;
        // chatMessage.BulkMessageIdForGenerateChannell = bulkMessageIdForGenerateChannell;
        chatMessage.BulkMessageType = bulkMessageType;
        chatMessage.MessageBubbleType = this.chatService.setChatMessageBubbleType(chatMessage);

        return this.chatService.natsService.publishPublicSettingMessage(chatMessage);
    }

    sendAdminMessageToOneRoom(roomId: number, message, chatType) {
          let roomService = this.injector.get(RoomService)

        var chatPanelProperties = this.chatPanelService.getChatFormPropertiesForFriendChatPanel(this.indexedDBService.userFullInfo, ChatType.GroupChat);

        var chatMessage = this.chatService.getChatMessage(
            chatPanelProperties,
            message,
            chatPanelProperties.ContactType,
            ContactState.Hicbiri,
            this.indexedDBService.userFullInfo
        );

        chatMessage.MessageId = this.utilService.guid();
        chatMessage.Time = new Date();
        chatMessage.Channelname = this.utilService.getRoomChannelName(roomId);
        chatMessage.ContactType = ContactType.Chat;
        chatMessage.ChatType = chatType;
        chatMessage.SenderRutbeDerece = this.indexedDBService.userFullInfo.RutbeDerece;
        chatMessage.MessageBubbleType = this.chatService.setChatMessageBubbleType(chatMessage);
        chatMessage.RoomNameWhereTheMessageSent = roomService.currentRoom.Info?.NAME;
        chatMessage.RoomIdWhereTheMessageSent = roomService.currentRoom.Info?.ID;
        chatMessage.IndexName = roomService.currentRoom.Info?.ID.toString();
        //  chatMessage.Time = this.utilService.addServerTimeDistanceToDate(chatMessage.Time, this.indexedDBService.serverTimeDistance);

        return this.chatService.natsService.publishRoomChatMessage(roomId, chatMessage);
    }


    // #region Get Message

    getMessageArray(
        chatMessage: ChatMessage,
        htmlEditorMessageId: string
    ): Promise<MessageArrayObject> {
        var isTranslatedTextVisible = false;
    
        try {
            // Eğer doküman ya da resim mesajıysa metni temizle
            if (chatMessage.IsDocs || chatMessage.IsImage) {
                chatMessage.RtfText = '';
            } else {
                chatMessage.RtfText = this.utilService.ClearWhiteSpace(chatMessage.RtfText);
            }
    
            var messageArray = [];
    
            // Eğer RtfText sadece emoji içeriyorsa
            const emojiRegex = /<img[^>]*class=["']emoji["'][^>]*>/g; 
            if (emojiRegex.test(chatMessage.RtfText)) {
                messageArray[0] = this.getMessageHtmlTextForMainText(
                    htmlEditorMessageId,
                    this.getNormalText(chatMessage.RtfText),
                    chatMessage?.NickType?.F,
                    chatMessage.NickType.I === true ? 'italic' : 'normal',
                    chatMessage.NickType.B === true ? 'bold' : 'normal',
                    this.indexedDBService.userFullInfo.NicktypeForRoom.FS,
                    chatMessage.NickType.C
                );
            }
             else {
                // Normal mesaj işleme
                if (chatMessage.SenderId === this.indexedDBService.userFullInfo.KullaniciId) {
                    // Gönderen kullanıcı ise
                    messageArray[0] = this.getMessageHtmlTextForMainText(
                        htmlEditorMessageId,
                        this.getNormalText(chatMessage.RtfText),
                        chatMessage?.NickType?.F,
                        chatMessage.NickType.I === true ? 'italic' : 'normal',
                        chatMessage.NickType.B === true ? 'bold' : 'normal',
                        this.indexedDBService.userFullInfo.NicktypeForRoom.FS,
                        chatMessage.NickType.C
                    );
    
                    messageArray[1] = this.getMessageHtmlText(
                        this.getNormalText(chatMessage.RtfText),
                        chatMessage?.NickType?.F,
                        chatMessage.NickType.I === true ? 'italic' : 'normal',
                        chatMessage.NickType.B === true ? 'bold' : 'normal',
                        this.indexedDBService.userFullInfo.NicktypeForRoom.FS,
                        chatMessage.NickType.C
                    );
                } else {
                    // Diğer kullanıcıdan gelen mesaj
                    if (chatMessage.NickType.C === 'black') {
                        chatMessage.NickType.C = 'white';
                    }
                    messageArray[0] = this.getMessageHtmlTextForMainText(
                        htmlEditorMessageId,
                        this.getNormalText(chatMessage.RtfText),
                        chatMessage?.NickType?.F,
                        chatMessage.NickType.I === true ? 'italic' : 'normal',
                        chatMessage.NickType.B === true ? 'bold' : 'normal',
                        this.indexedDBService.userFullInfo.NicktypeForRoom.FS,
                        chatMessage.NickType.C
                    );
    
                    messageArray[1] = this.getMessageHtmlText(
                        this.getNormalText(chatMessage.RtfText),
                        chatMessage?.NickType?.F,
                        chatMessage.NickType.I === true ? 'italic' : 'normal',
                        chatMessage.NickType.B === true ? 'bold' : 'normal',
                        this.indexedDBService.userFullInfo.NicktypeForRoom.FS,
                        chatMessage.NickType.C
                    );
                    messageArray[2] = '';
                }
            }
    
            return Promise.resolve(<MessageArrayObject>{
                messageArray: messageArray,
                isTranslatedTextVisible: isTranslatedTextVisible,
            });
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    


    getMessageHtmlTextForUserName(
        text: string, //string
        fontFamily: string, //string fontFamily
        fontSize: number, //float
        color: string //Color
    ) {
        return "<span class=\"username\" style=\"font-family:" + fontFamily + "; font-style:normal; font-weight:bold; font-size:" + fontSize + "px)" + "; color:" + color + "; \" >" + text + "</span>";
    };

    getMessageHtmlTextForMainText(
        htmlEditorMessageId: string,//string
        text: string, //string
        fontFamily: string, //string fontFamily
        fontStyle: string,//string 
        fontWeight: string,//string 
        fontSize: number,//float
        color: string//Color
    ) {
        return "<div class=\"messageSpan\" id=\"messageSpan_" + htmlEditorMessageId + "\" style=\"font-family:'" + fontFamily + "'; font-style:" + fontStyle + "; font-weight:" + fontWeight + "; font-size:" + fontSize + "px; color:" + color + "; \" >" + text + "</div>";
    }

    getMessageHtmlText(
        text: string,// string
        fontFamily: string, //string fontFamily
        fontStyle: string,//string 
        fontWeight: string,//string 
        fontSize: number,//float
        color: string// string
    ) {
        var newText = text;

        var matchesArray = newText.match(new RegExp(this.utilService.UrlRegex));
        if (matchesArray === null || matchesArray === undefined)
            return newText;

        matchesArray.forEach(val => {
            newText = this.getMessageHyperLinkHtmlText(val, fontFamily, fontStyle, fontWeight, fontSize, color);
        });

        return "<span style=\"font-family:" + fontFamily + "; font-style:" + fontStyle + "; font-weight:" + fontWeight + "; font-size:" + fontSize + "px; color:" + color + "; \" >" + newText + "</span>";
    };

    getTranslatedText(translatedText: string) {
        if (this.utilService.IsNullOrWhitespace(translatedText))
            return "";

        return translatedText;
    }

    getMessageHyperLinkHtmlText(
        text: string, //string
        fontFamily: string,//string
        fontStyle: string,//string
        fontWeight: string,//string
        fontSize: number,//float
        color: string//string
    ) {
        return "<a href=\"#\"><span onclick=\"_xamarin.GoHyperLink('" + text + "');\"  style=\"cursor:hand; text-decoration:underline; font-family:" + fontFamily + "; font-style:" + fontStyle + "; font-weight:" + fontWeight + "; font-size:" + fontSize + "px; color:" + color + "; \" >" + text + "</span></a>";
    }

    getNormalText(text: string) {
        return text;
    }

    // #endregion


    getChatMessageForRoomBubble(chatMessage: ChatMessage): Promise<ChatMessageForBubble> {
        return this.chatService.prepareChatMessageBeforeAddToBubble(this.getUserImage(chatMessage), chatMessage)
            .then(_chatMessage => {
                chatMessage = _chatMessage;
            })
            .then(() => {
                return this.getMessageArray(chatMessage, this.utilService.guid());// this.chatService.getMessageTimeString(new Date(chatMessage.Time)));
            })
            .then((messageArrayObject: MessageArrayObject) => {
                return <ChatMessageForBubble>{
                    senderId: chatMessage.SenderId,
                    userImageUrl: this.getUserImage(chatMessage),
                    bubbleType: BubbleTypes.Text,
                    MessageBubbleType: chatMessage.MessageBubbleType,
                    // messageTime: this.utilService.formatMessageTime(chatMessage.Time),
                    messageHtml: messageArrayObject.messageArray,
                    userName: this.getUserName(chatMessage),
                    timeString: this.utilService.getDateStringHourMinute(chatMessage.Time) + " " + this.utilService.formatMessageTime(chatMessage.Time),
                    nickType: chatMessage.NickType,
                    messageType: chatMessage.MessageType,
                    roomNameWhereTheMessageSent: chatMessage.RoomNameWhereTheMessageSent,
                    roomIdWhereTheMessageSent: chatMessage.RoomIdWhereTheMessageSent,
                    isSended: chatMessage.SenderId === this.indexedDBService.userFullInfo.KullaniciId ? true : false,
                    isFile: chatMessage.IsDocs,
                    isImage: chatMessage.IsImage,
                    fileUrl: chatMessage.URL,
                    messageContent: chatMessage.RtfText
                };
            })
            .catch((error) => {
                console.log(error)
                return null;
            });
    }

    public getUserName(chatMessage: ChatMessage) {
        try {
            var rankHtml = this.utilService.getRutbeFontStarts(chatMessage.SenderRutbeDerece);
            // if (this.utilService.IsNullOrWhitespace(rankHtml) === false)
            //     rankHtml = " (" + rankHtml + ") ";

            if (chatMessage.ChatType === ChatType.SystemMessages)
                return "";

            if (chatMessage.IsTopluMesaj) //messengerToplu Mesaj
                return chatMessage.SenderName + " [" + this.localizationService.getKeyWithTag("BulkMessage") + "] " + rankHtml;

            if (chatMessage.ChatType === ChatType.RootAdminTopluMesaj)
                return this.localizationService.getKeyWithTag("SystemMessage");

            switch (chatMessage.MessageSendingType) {
                case MessageSendingType.GenelAdmin:
                    return chatMessage.SenderName + " [" + this.localizationService.getKeyWithTag("BulkAdmin") + "] " + rankHtml;
                case MessageSendingType.GenelHerkes:
                    return chatMessage.SenderName + " [" + this.localizationService.getKeyWithTag("Bulk") + "] " + rankHtml;
            }

            // p2p mesajı ise ve benim arkadasımsa gerçek adını ve rütbesini göster
            if (chatMessage.MessageBubbleType === MessageBubbleType.PtoPOther && this.indexedDBService.isMyFriend(chatMessage.SenderId)) {
                return chatMessage.SenderName + " " + rankHtml;
            }
            else {//odaya atılan normal mesaj ise
                //ikinci rumuz yok ise
                if (this.utilService.IsNullOrWhitespace(chatMessage.SenderNameSecond) === true) {
                    return chatMessage.SenderName + " " + rankHtml;
                }

                // mesajı atan ben ise
                if (chatMessage.SenderId === this.indexedDBService.userFullInfo.KullaniciId)
                    return chatMessage.SenderNameSecond + " (" + chatMessage.SenderName + ") " + rankHtml;

                //ikinci rumuz var  ve mesajı atanın rütbesi benim rütbemden büyük ise       
                if (this.indexedDBService.userFullInfo.RutbeDerece > chatMessage.SenderRutbeDerece)
                    return chatMessage.SenderNameSecond + " (" + chatMessage.SenderName + ") " + rankHtml;

                // hiçbiri değil ise ikinci rumuz olduğu için ikni rumuzu göster 
                return chatMessage.SenderNameSecond;
            }

        }
        catch (error) {
            console.log(error);
        }

        return "";
    }

    getUserImage(chatmessage: ChatMessage) {
        try {
            if (chatmessage.ChatType === ChatType.SystemMessages ||
                chatmessage.ChatType === ChatType.RootAdminTopluMesaj ||
                chatmessage.ChatType === ChatType.AdminChangesMethod)
                return this.utilService.GetImageUrl(Constants.Veri24MessengerLogo70);
            else {
                if (chatmessage.UserPhotoId === Constants.guidEmpty)
                    return Constants.defaultSmallUserPhotoUrl;
                else
                    return Constants.veri24MessengerUserPhotosUrl + chatmessage.UserPhotoId + ".png";
            }

        }
        catch (error) {
            console.log(error)
            return this.utilService.GetImageUrl(Constants.Veri24MessengerDefaultUserPhoto);
        }
    }
}   