<p-dialog [(visible)]="panelManagerService.addFriendPopupDisplay"  [modal]="true"  [baseZIndex]="10000"   [responsive]="true" >
    <p-header>
        {{'menu_addfrined' | translate}}
    </p-header>

    <div style="text-align:center;">
        <input type="text" pInputText id="userName" class="inputText dialog-input" placeholder="{{'searchbyusername' | translate}}"
            [(ngModel)]="username" (ngModelChange)="valuechange($event)"/>
            <p-progressSpinner class="snipper" *ngIf="panelManagerService.findFriendLoading" fill="#EEEEEE"
            animationDuration=".5s">
        </p-progressSpinner>
        <br>
        <!-- (keyup)="onKeyup($event)" -->
        <div class="findedUserArea" *ngIf="responseLoaded">
            <header class="p-dialog-titlebar" style="text-align: left;">
                {{ 'UserName' | translate }}
            </header>
            <div style="border: 1px solid #bbb; border-top: none; padding: 10px;">
                <div *ngIf="findedUser !== ''" style="height: 30px;">
                    <p style="float: left;">{{ findedUser }}</p>
                    <button style="float: right;" (click)="addFriend()" pButton type="button" label="{{ 'Add' | translate }}"></button>
                </div>
                <div *ngIf="findedUser === ''">
                    {{ 'nodatafound' | translate }}
                </div>

            </div>
        </div>
    </div>
</p-dialog>