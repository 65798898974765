import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RoomService } from 'src/app/core/services/room.service';
import { UtilService } from 'src/app/core/services/util.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { RoomNickSelectionType } from 'src/app/core/models/enums';

@Component({
  selector: 'app-room-second-nick-entry',
  templateUrl: './second-nick-entry.component.html',
  styleUrls: ['./second-nick-entry.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class RoomSecondNickEntryComponent implements OnInit {
  _roomNickSelectionType = RoomNickSelectionType;
  secondnickname: string = "";
  constructor(
    private roomService: RoomService,
    private utilService: UtilService,
    private messagePanelService: MessagePanelService,
    public panelManagerService: PanelManagerService,
    private indexedDBService: IndexedDBService
  ) { }

  ngOnInit() { }

  enterSecondNick() {
    if (!this.utilService.isNullOrEmtpyString(this.secondnickname)) {
      if (this.isSecondNickValid() === true)
        this.callsaveSecondNick();
      else
        this.messagePanelService.ShowPopupMessageWithLocalization('UserNameValidationWarning', 4000)
    }
    else{
      this.panelManagerService.roomSecondNickPanelIsOpened = false;
      this.panelManagerService.roomSecondNickPanelResponseSource.next('');
      // this.callsaveSecondNick();
    }
    this.panelManagerService.roomSecondNickPanelIsOpened = false;
  }

  callsaveSecondNick() {
    this.roomService.saveSecondNick(this.indexedDBService.userFullInfo.KullaniciId, this.secondnickname)
      .then((response: any) => {
        if (response.Result === true)
          this.successSaveSecondNick();
        else
          this.errorSaveSecondNick(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  successSaveSecondNick() {
    this.indexedDBService.userFullInfo.IkinciKullaniciAdi = this.secondnickname;
    this.indexedDBService.updateUser(this.indexedDBService.userFullInfo)
    this.panelManagerService.roomSecondNickPanelResponseSource.next(this.secondnickname);
  }

  errorSaveSecondNick(response) {
    this.utilService.isNullOrEmtpyString(response.ErrMsg) === true ? this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 4000) : this.messagePanelService.ShowPopupMessageWithLocalization(response.ErrMsg, 2000);
    this.panelManagerService.roomSecondNickPanelResponseSource.next(null);
  }

  isSecondNickValid() {
    if (this.utilService.isNullOrEmtpyString(this.secondnickname) || this.utilService.isValidUserName(this.secondnickname) === false) {
      return false;
    }
    return true;
  }
}