
export class RankFullInfo {
	ACIL_CAGRI: boolean;
	ADI: string;
	ADMIN_HAREKETLERI: boolean;
	ANIMASYONLAR: boolean;
	ATMA_ODA: boolean;
	ATMA_SITE: boolean;
	BAN_1: boolean;
	BAN_3: boolean;
	BAN_7: boolean;
	BAN_30: boolean;
	BAN_GIRISLERI_AC_KAPA: boolean;
	BAN_ODA: boolean;
	BAN_SAAT: boolean;
	BAN_SITE: boolean;
	BAN_SURESIZ: boolean;
	BAS_KONUS: boolean;
	DERECE: number;
	EL_INDIR: boolean;
	FLASHNICK_DEGISTIRME: boolean;
	GECICI_OP_ODA: boolean;
	GECICI_OP_SITE: boolean;
	GENEL_MESAJ: boolean;
	GENEL_MESAJ_ADET: number;
	GERI_BILDURUM: boolean;
	GIRIS_KAYITLARI_SOHBET: boolean;
	GIRIS_KAYITLARI_messengerMain: boolean;
	GOREV_DESTEK: boolean;
	GORUNMEZ_MOD: boolean;
	GRUP_ACMA: boolean;
	ID: number;
	IKINCI_RUMUZ: boolean;
	KAMERA_LIMIT: number;
	KAMERA_SONLANDIR: boolean;
	KARA_LISTE: boolean;
	KELIME_YASAKLAMA: boolean;
	KENDINICKINIYONETEBILIR: boolean;
	KENDI_FLASHI: boolean;
	KISI_BIREYSEL_BILGI_DUZENLEME: boolean;
	KISI_BIREYSEL_BILGI_GORME: boolean;
	KISI_DEGISTIRME: boolean;
	KISI_KILIT_BITIS_TARIHI: boolean;
	KISI_PC_LISTESI: boolean;
	KISI_RUTBE: boolean;
	KISI_SIFRE: boolean;
	KISI_SILME: boolean;
	KISI_YETKI: boolean;
	MIKROFON_ALMA: boolean;
	MIKROFON_DUSURME: boolean;
	MIK_ENGEL_ODA: boolean;
	MIK_ENGEL_SITE: boolean;
	MIS_SURE: number;
	MUTE_ODA: boolean;
	MUTE_SITE: boolean;
	ODADAN_ARKADAS_EKLEME_BUTONU: boolean;
	ODADA_KULLANICI_LISTELE: boolean;
	ODAYA_CEKME: boolean;
	ODAYA_DAVET: boolean;
	ODA_DEGISTIRME: boolean;
	ODA_EKLEME: boolean;
	ODA_SESSIZ: boolean;
	ODA_SIFRE: boolean;
	ODA_SILME: boolean;
	OZEL_MESAJ: boolean;
	RENKLI_RUMUZ: boolean;
	RUTBE_DEGISTIRME: boolean;
	RUTBE_EKLEME: boolean;
	RUTBE_SILME: boolean;
	SESSIZ_SESLI: boolean;
	SIKAYET_GORUNTULE: boolean;
	SIKAYET_SIL: boolean;
	SISTEM_AYARLARI: boolean;
	TRANSLATE_MESSENGER: boolean;
	TRANSLATE_ODA: boolean;
	YAZILARI_SIL: boolean;
	YAZMA_KONUSMA_IZNI: boolean;
	YETKILI_SLIDER_YONETIMI: boolean;
	GENEL_MESAJ_ONAYLAMA:boolean;
}

export enum Rank {
	ID = 0,
	ADI = 1,
	MIS_SURE = 2,
	KAMERA_LIMIT = 3,
	KISI_DEGISTIRME = 4,
	KISI_SILME = 5,
	KISI_SIFRE = 6,
	KISI_YETKI = 7,
	KISI_RUTBE = 8,
	KISI_KILIT_BITIS_TARIHI = 9,
	KISI_PC_LISTESI = 10,
	KISI_BIREYSEL_BILGI_GORME = 11,
	KISI_BIREYSEL_BILGI_DUZENLEME = 12,
	RUTBE_DEGISTIRME = 13,
	RUTBE_EKLEME = 14,
	RUTBE_SILME = 15,
	ODA_DEGISTIRME = 16,
	ODA_EKLEME = 17,
	ODA_SILME = 18,
	ODA_SIFRE = 19,
	ODA_SESSIZ = 20,
	BAN_ODA = 21,
	BAN_SITE = 22,
	BAN_SAAT = 23,
	BAN_1 = 24,
	BAN_3 = 25,
	BAN_7 = 26,
	BAN_30 = 27,
	BAN_SURESIZ = 28,
	MUTE_ODA = 29,
	MUTE_SITE = 30,
	ATMA_ODA = 31,
	ATMA_SITE = 32,
	GECICI_OP_ODA = 33,
	GECICI_OP_SITE = 34,
	ADMIN_HAREKETLERI = 35,
	GIRIS_KAYITLARI_messengerMain = 36,
	GIRIS_KAYITLARI_SOHBET = 37,
	KELIME_YASAKLAMA = 38,
	SISTEM_AYARLARI = 39,
	BAN_GIRISLERI_AC_KAPA = 40,
	ANIMASYONLAR = 41,
	GERI_BILDURUM = 42,
	KENDI_FLASHI = 43,
	YAZILARI_SIL = 44,
	IKINCI_RUMUZ = 45,
	MIKROFON_DUSURME = 46,
	MIKROFON_ALMA = 47,
	GENEL_MESAJ = 48,
	EL_INDIR = 49,
	OZEL_MESAJ = 50,
	ODAYA_CEKME = 51,
	GORUNMEZ_MOD = 52,
	YAZMA_KONUSMA_IZNI = 53,
	KARA_LISTE = 54,
	RENKLI_RUMUZ = 55,
	SESSIZ_SESLI = 56,
	DERECE = 57,
	MIK_ENGEL_ODA = 58,
	MIK_ENGEL_SITE = 59,
	ODADA_KULLANICI_LISTELE = 60,
	KAMERA_SONLANDIR = 61,
	FLASHNICK_DEGISTIRME = 62,
	GRUP_ACMA = 63,
	GOREV_DESTEK = 64,
	ACIL_CAGRI = 65,
	BAS_KONUS = 66,
	SIKAYET_GORUNTULE = 67,
	SIKAYET_SIL = 68,
	ODADAN_ARKADAS_EKLEME_BUTONU = 69,
	ODAYA_DAVET = 70,
	TRANSLATE_ODA = 71,
	TRANSLATE_MESSENGER = 72,
	KENDINICKINIYONETEBILIR = 73,
	GENEL_MESAJ_ADET = 74,
	YETKILI_SLIDER_YONETIMI = 75
}

